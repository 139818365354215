import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { clientService } from './../../../_services';
import { Message, PageHeader, Loader } from './../../../common';
import { FileList, Input, TextArea } from './../../../common';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import TimeLeft from '../../../public/icons/TimeLeft';
import * as moment from 'moment';
import ProjectDetailPanel from './ProjectDetailPanel';
import '../project.css';
import ProjectTaskPanel from './ProjectTaskPanel';
import ProjectFilesPanel from './ProjectFilesPanel';
import ProjectTeamPanel from './ProjectTeamPanel';
import ProjectMessagesPanel from './ProjectMessagesPanel';
import NumberFormat from 'react-number-format';
import GrayTimeLeft from '../../../public/icons/GrayTimeLeft';
import RedTimeLeft from '../../../public/icons/RedTimeLeft';
import BackToOption from '../../../common/BackToOption';
import { withTranslation } from 'react-i18next';
import 'moment/locale/ja';
import 'moment/locale/en-ca';

let TimeLeftPanel = styled.div`
  position: relative;
  width: fit-content;
  height: 20px;
  div.background-panel {
    position: absolute;
    width: 100%;
    height: 20px;
    padding: 2px;
    background-color: ${(props) =>
      props.value > 7 ? '#4B4D4E' : props.value > 0 ? '#ff974a' : '#FC5A5A'};
    opacity: 0.1;
    border-radius: 2.5px;
  }
  p.icon-timeleft {
    position: absolute;
    margin: 0;
    padding: 2px;
    display: flex;
  }
  p.text-timeleft {
    margin-left: 20px;
    padding: 4px 4px 4px 0;
    font-family: Montserrat;
    font-size: 11px;
    line-height: 1.29;
    letter-spacing: 0.05px;
    text-align: left;
    color: ${(props) => (props.value > 7 ? '#4B4D4E' : props.value > 0 ? '#ff974a' : '#FC5A5A')};
    @media (min-width: 1600px) {
      font-size: 12px;
    }
  }
`;
let LabelPanel = styled.div`
  position: relative;
  height: 20px;
  margin: 0 10px;
  div.background-text {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #f8f8f8;
    border-radius: 5px;
  }
  p.box-text {
    margin: 0;
    position: inherit;
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: 0.04px;
    text-align: center;
    color: #4b4d4e;
    padding: 4px 10px;
    @media (min-width: 1600px) {
      font-size: 12px;
    }
  }
`;
let ProgressBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  div.show-progress-bar {
    height: 4px;
    margin: 0;
    overflow: hidden;
    background-color: #e2e6ea;
    border-radius: 2px;
    width: 100%;
    align-self: center;
    div.show-progress {
      float: left;
      width: ${(props) => (props.width ? props.width + '%' : '0')};
      height: 100%;
      font-size: 12px;
      line-height: 20px;
      color: #fff;
      text-align: center;
      -webkit-transition: width 0.6s ease;
      -o-transition: width 0.6s ease;
      transition: width 0.6s ease;
      border-radius: 1.3px;
      background-color: #21a8df;
    }
  }
  p.percent-text {
    font-family: Montserrat;
    font-size: 11px;
    line-height: 1.71;
    letter-spacing: 0.05px;
    color: #696974;
    margin: 0;
    text-align: end;
    @media (min-width: 1600px) {
      font-size: 12px;
    }
  }
  div.date-bar {
    display: flex;
    justify-content: space-between;
    p {
      margin-top: 10px;
      font-family: Montserrat;
      font-size: 11px;
      line-height: 1.25;
      letter-spacing: 0.04px;
      color: #696974;
      @media (min-width: 1600px) {
        font-size: 12px;
      }
    }
  }
`;
const ProjectDetail = ({ t, i18n, ...props }) => {
  moment.locale('ja');
  const [project, setProject] = useState({
    name: '',
    startDate: '',
    endDate: '',
    category: '',
    subCategory: '',
    budget: '',
    currency: '',
    background: '',
    details: '',
    status: ''
  });
  const [key, setKey] = useState('details');

  let taskStatusData = ['not_started', 'completed'];

  const [tasks, setTasks] = useState([]);

  const [page, setPage] = useState(0);

  const [taskModalFlag, setTaskModalFlag] = useState(false);

  const handleTaskModalClose = () => setTaskModalFlag(false);
  const handleTaskModalShow = () => setTaskModalFlag(true);

  const [task, setTask] = useState({
    name: '',
    details: '',
    status: '',
    startDate: '',
    endDate: '',
    assignee: ''
  });

  useEffect(() => {
    let data = {};
    let params = new URLSearchParams(window.location.search);
    data.project_id = params.get('project_id');
    if (data.project_id) {
      clientService.getProject(data).then(
        (response) => {
          if (!response.data.result) {
            props.history.push('/home/projects');
            return;
          }
          let temp = {};
          temp = response.data.result;
          setProject(temp);
          setcurreny(temp.currency);
          let tempTask = task;
          tempTask.project = response.data.result._id;
          setTask(tempTask);
        },
        (err) => {
          console.error(err);
          props.history.push('/home/projects');
        }
      );

      let taskParams = { project_id: data.project_id, page: page };
      clientService.getProjectTasks(taskParams).then((response) => {
        let temp = [];
        temp = temp.concat(response.data.result);
        setTasks(temp);
      });
    } else {
      // props.history.push('/home')
    }

    // clientService
  }, []);

  const taskFieldChange = (event) => {
    let temp = {
      name: task.name,
      details: task.details,
      status: task.status,
      startDate: task.startDate,
      endDate: task.endDate,
      assignee: task.assignee,
      project: task.project
    };
    temp[event.target.name] = event.target.value;
    setTask(temp);
  };

  const createTask = () => {
    if (validateTaskData()) {
      clientService.createProjectTask(task).then(
        (response) => {
          let tempTask = {
            name: '',
            details: '',
            status: '',
            startDate: '',
            endDate: '',
            assignee: '',
            project: task.project
          };
          setTask(tempTask);
          handleTaskModalClose();
          let tasksTemp = [];
          tasksTemp = tasksTemp.concat(tasks);
          tasksTemp.push(response.data.result);
          setTasks(tasksTemp);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  const validateTaskData = () => {
    if (
      task.project &&
      task.name &&
      task.details &&
      task.status &&
      task.startDate &&
      task.endDate &&
      task.assignee
    ) {
      return true;
    }
    return false;
  };

  const deleteTask = (task, index) => {
    let params = {
      task_id: task._id
    };
    clientService.deleteProjectTask(params).then(
      (response) => {
        let tempTasks = [];
        tempTasks = tempTasks.concat(tasks);
        tempTasks.splice(index, 1);
        setTasks(tempTasks);
      },
      (error) => {
        console.error(error);
      }
    );
  };
  const [currenySign, setcurrenySign] = useState('');

  const setcurreny = (currency) => {
    if (currency === 'USD') {
      setcurrenySign('$');
    } else if (currency === 'EUR') {
      setcurrenySign('€');
    } else {
      setcurrenySign('¥');
    }
  };

  return (
    <div className='row m-0 h-100 ' style={{ width: '84vw' }}>
      {project && project.name ? (
        <div style={{ padding: '20px', width: '100%' }}>
          <BackToOption link='/home/projects' text='Projects' />
          <div className='row m-0'>
            <PageHeader text={project.name} />
            <LabelPanel>
              <div className='background-text'></div>
              <p className='box-text'>
                {project.status === 'not_started'
                  ? t('projectStatus.not_started')
                  : project.status === 'in_progress'
                  ? t('projectStatus.in_progress')
                  : t('projectStatus.completed')}
              </p>
            </LabelPanel>
            <LabelPanel>
              <div className='background-text'></div>
              <p className='box-text'>{project.category}</p>
            </LabelPanel>
            <LabelPanel>
              <div className='background-text'></div>
              <p className='box-text'>
                <NumberFormat
                  className='currency-box'
                  thousandSeparator={true}
                  displayType={'text'}
                  allowNegative={false}
                  readOnly={true}
                  prefix={currenySign}
                  value={project.budget}
                />
              </p>
            </LabelPanel>
          </div>
          <div className='row m-0'>
            <ProgressBar width={project.projectCompleted}>
              <p className='percent-text'>{project.projectCompleted}%</p>
              <div className='show-progress-bar'>
                <div className='show-progress'></div>
              </div>
              <div className='date-bar'>
                <p>{moment(project.startDate).format('MMM,D YYYY')}</p>
                <p>{moment(project.endDate).format('MMM,D YYYY')}</p>
              </div>
            </ProgressBar>
            {project.status === 'completed' ? (
              ''
            ) : (
              <div
                style={{
                  position: 'absolute',
                  right: ' 42%',
                  marginTop: '10px'
                }}
              >
                <TimeLeftPanel value={moment(project.endDate).diff(moment(), 'days') + 1}>
                  <div className='background-panel'></div>
                  <p className='icon-timeleft'>
                    {moment(project.endDate).diff(moment(), 'days') + 1 > 7 ? (
                      <GrayTimeLeft />
                    ) : moment(project.endDate).diff(moment(), 'days') + 1 > 0 ? (
                      <TimeLeft />
                    ) : (
                      <RedTimeLeft />
                    )}
                  </p>
                  <p className='text-timeleft'>
                    {moment(project.endDate).diff(moment(), 'days') + 1 > 0
                      ? moment(project.endDate).diff(moment(), 'days') + 1 == '1'
                        ? '1 ' + t('alerts.day_left')
                        : moment(project.endDate).diff(moment(), 'days') +
                          1 +
                          ' ' +
                          t('alerts.days_left')
                      : t('alerts.overdue')}
                  </p>
                </TimeLeftPanel>
              </div>
            )}
          </div>
          <div
            style={{
              width: '100%',
              height: '69vh',
              display: 'flex',
              flexDirection: 'column',
              position: 'relative'
            }}
          >
            {project._id && (
              <Tabs id='project-tab' activeKey={key} onSelect={(k) => setKey(k)}>
                <Tab eventKey='details' title={t('project.details.detail')}>
                  <ProjectDetailPanel projectDetails={project} />
                </Tab>
                <Tab eventKey='tasks' title={t('project.details.tasks')}>
                  <ProjectTaskPanel projectDetails={project} />
                </Tab>
                <Tab eventKey='files' title={t('project.details.files')}>
                  <ProjectFilesPanel projectDetails={project} />
                </Tab>
                <Tab eventKey='teams' title={t('project.details.teams')}>
                  <ProjectTeamPanel projectDetails={project} />
                </Tab>
                <Tab eventKey='messages' title={t('project.details.messages')}>
                  {key === 'messages' && <ProjectMessagesPanel projectDetails={project} />}
                </Tab>
              </Tabs>
            )}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default withTranslation('common')(ProjectDetail);
