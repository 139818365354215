import Cookies from 'universal-cookie';
import { BehaviorSubject } from 'rxjs';

const cookies = new Cookies();
const axios = require('axios').default;
const globals = require('./../_helpers/globals');
const URL = globals.domain;

// var loggedUser = new BehaviorSubject({})
var loggedUser = null;

function setCookie(name, value) {
  localStorage.setItem(name, value.token);
  localStorage.setItem('U_ID', value.user._id);
  loggedUser = value.user;
  // loggedUser.next(value.user)
}

function getCookie() {
  return localStorage.getItem('UID');
}

function login(data) {
  return axios.post(`${URL}users/signin`, data);
}

function register(data) {
  return axios.post(`${URL}users/signup`, data);
}

function registerProfessional(data) {
  return axios.patch(`${URL}users/professional`, data);
}

function updateProfessional(data, role) {
  var API = `${URL}users/profile`;
  if (role === 'professional') {
    API = `${URL}users/professional`;
  }
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .patch(`${API}`, data);
}

function logout() {
  localStorage.removeItem('UID');
  localStorage.removeItem('U_ID');
}

function checkEmailExist(params) {
  return axios.get(`${URL}users/signup/emailValidate`, { params: params });
}

function getUserIDCookie() {
  return localStorage.getItem('U_ID');
}

function updateUser() {
  return new Promise((resolve, reject) => {
    isLoggedIn().then(
      (response) => {
        loggedUser = response.data.user;
        resolve(response.data.user);
      },
      (err) => {
        reject('somethign went wrong');
      }
    );
  });
}

function sendResetLink(params) {
  return axios.get(`${URL}users/forgotPassword`, {
    params: params
  });
}

function resetPassword(data) {
  return axios.post(`${URL}users/resetPassword`, data);
}

function updateProfileImage(data) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .post(`${URL}users/profile/image`, data);
}

function clearProfileImage() {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .delete(`${URL}users/profile/image`);
}

function isLoggedIn() {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .get(`${URL}users/guard`);
}

function getNotification() {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .get(`${URL}users/notifications`);
}

function readNotification(params) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .get(`${URL}users/notification-read`, {
      params: params
    });
}

function linkExpire(params) {
  return axios.get(`${URL}users/link-expiry-check`, {
    params: params
  });
}

function getDropDown() {
  return axios.get(`${URL}drop-down-list`);
}

function mainMessagesMembers() {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .get(`${URL}users/all`);
}

function verifyAccount(data) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .post(`${URL}users/verifyEmail`, data);
}

function getVerificationLink(data) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .post(`${URL}users/resendEmailVerificationEmail`, data);
}

export const authenticationService = {
  login,
  register,
  logout,
  setCookie,
  getCookie,
  isLoggedIn,
  checkEmailExist,
  getUserIDCookie,
  loggedUser,
  updateUser,
  registerProfessional,
  updateProfileImage,
  clearProfileImage,
  updateProfessional,
  sendResetLink,
  linkExpire,
  resetPassword,
  getDropDown,
  getNotification,
  readNotification,
  mainMessagesMembers,
  verifyAccount,
  getVerificationLink
};
