import React from 'react';

const ThreeDots = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='ic_More'
    width='24'
    height='24'
    viewBox='0 0 24 24'
  >
    <defs></defs>
    <path
      id='Shape'
      d='M0 16a2 2 0 1 1 2 2 2 2 0 0 1-2-2zm0-7a2 2 0 1 1 2 2 2 2 0 0 1-2-2zm0-7a2 2 0 1 1 2 2 2 2 0 0 1-2-2z'
      style={{ fill: '#4b4d4e' }}
      transform='rotate(-90 8.5 5.5)'
    />
  </svg>
);

export default ThreeDots;
