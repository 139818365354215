import React, { useState, useEffect, useContext } from 'react';
import { authenticationService, sendBirdService, clientService } from './../../_services';
import './message.css';
import styled from 'styled-components';
import * as moment from 'moment';
import ChatAttachments from '../../public/icons/ChatAttachments';
import { Link } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import Avatar from 'react-avatar';
import { withTranslation } from 'react-i18next';
import 'moment/locale/ja';
import 'moment/locale/en-ca';

let InputWrapper = styled.div`
  display: flex;
  width: 89%;
  height: 8vh;
  position: absolute;
  bottom: 20px;
  border-radius: 15px;
  border: 0;
  background-color: #fafafb;
  padding: 15px 20px;
  margin-left: -1.2%;
  div.profile-logo {
    width: 30px;
    align-self: center;
    margin-left: 10px;
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }
  div.input-message {
    width: 100%;
    padding-left: 15px;
    input {
      border-radius: 20px;
      border: 0;
      border: solid 0.5px #d5d5dc;
      background-color: #ffffff;
      height: 30px;
      align-self: center;
      width: 98%;
      padding: 0 40px 0 20px;
      font-family: Montserrat;
      font-size: 12px;
      line-height: 1.25;
      letter-spacing: 0.04px;
      text-align: left;
      color: #92929d;
      @media (min-width: 1600px) {
        font-size: 13px;
      }
    }
  }
`;
let Striker = styled.div`
  display: flex;
  justify-content: space-between;
  div {
    width: 35%;
    height: 2px;
    background: #f1f1f5;
  }
  p {
    margin: 0;
    top: -5px;
    position: relative;
    line-height: 1.19;
    color: #4b4d4e;
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 600;
    @media (min-width: 1600px) {
      font-size: 12px;
    }
  }
`;
function Message({ t, i18n, ...props }) {
  moment.locale('ja');
  const [{ user }, dispatch] = useContext(UserContext);
  const [messages, setMessages] = useState([]);
  const [messageValue, setMessageValue] = useState('');
  const user_id = authenticationService.getUserIDCookie();
  const [sendBirdChannel, setChannel] = useState(null);

  var handler = new sendBirdService.sb.ChannelHandler();
  var localConnectionStatus = 'not_connected';

  let sendBirdChannelURL = props.sendBirdChannelURL;
  let parentType = props.parentType;
  let parentId = props.parentId;

  sendBirdService.connectionStatus.subscribe((connectionStatus) => {
    localConnectionStatus = connectionStatus;
  });

  const autoScroll = () => {
    setTimeout(() => {
      var elem = document.getElementById('message_panel');
      elem.scrollTop = elem.scrollHeight;
    }, 1);
  };

  handler.onMessageReceived = async function (channel, message) {
    if (sendBirdChannel) {
      autoScroll();
      let dateStrike;
      let dummySetback = false;
      let lastTextedDate;
      const reversedItems = messages.map((item) => item).reverse();

      for (let i = 0; i < reversedItems.length; i++) {
        if (reversedItems[i].props.children[0]) {
          lastTextedDate = reversedItems[i].props.children[0].props.children[1].props.children;
          if (lastTextedDate === t('messages.today')) {
            lastTextedDate = moment().format('DD-MMM-YYYY');
          }
          break;
        }
      }
      if (lastTextedDate != moment(message.createdAt).format('DD-MMM-YYYY')) {
        dateStrike = moment(message.createdAt).format('DD-MMM-YYYY');

        dummySetback = true;
      } else {
        dummySetback = false;
      }
      if (message.channelUrl == sendBirdChannel.url) {
        let temp = [];
        temp = temp.concat(messages);
        if (user_id != message._sender.userId) {
          let lastMessage;
          if (messages.length > 0) {
            lastMessage =
              messages[messages.length - 1].props.children[1].props.className === 'incoming_msg';
          }
          if (lastMessage) {
            document.getElementById(
              messages[messages.length - 1].props.children[1].props.children[0].props.id
            ).style.display = 'none';
          }
          temp.push(
            <>
              {dummySetback && (
                <Striker>
                  <div></div>
                  <p>
                    {dateStrike === moment().format('DD-MMM-YYYY')
                      ? t('messages.today')
                      : dateStrike}
                  </p>
                  <div></div>
                </Striker>
              )}
              <div key={messages.length} className='incoming_msg'>
                <span className='profile-pic' id={'incoming%' + message.messageId}>
                  <div className='dummy-circle'>
                    {message._sender.metaData.profileImage ? (
                      <img
                        src={message._sender.metaData.profileImage}
                        alt='incoming_profile_image'
                      />
                    ) : (
                      <Avatar
                        size='30px'
                        round='50%'
                        name={
                          message._sender.metaData.firstName +
                          ' ' +
                          message._sender.metaData.lastName
                        }
                      />
                    )}
                  </div>
                </span>
                <div className='received_withd_msg'>
                  {message.messageType === 'user' && (
                    <p className='inner-text'>{message.message}</p>
                  )}
                  {message.messageType === 'file' && (
                    <a href={message.url} target='_blank'>
                      <p className='file-message-text'>{t('messages.file')}</p>
                      <p className='inner-text'>{message.name}</p>
                    </a>
                  )}
                </div>
                <span className='time_date'>{moment(message.createdAt).format('LT')}</span>
              </div>
            </>
          );
        } else {
          let lastMessage;
          if (messages.length > 0) {
            lastMessage =
              messages[messages.length - 1].props.children[1].props.className === 'outgoing_msg';
          }
          if (lastMessage) {
            document.getElementById(
              messages[messages.length - 1].props.children[1].props.children[2].props.id
            ).style.display = 'none';
          }

          temp.push(
            <>
              {dummySetback && (
                <Striker>
                  <div></div>
                  <p>
                    {dateStrike === moment().format('DD-MMM-YYYY')
                      ? t('messages.today')
                      : dateStrike}
                  </p>
                  <div></div>
                </Striker>
              )}
              <div key={messages.length} className='outgoing_msg'>
                <span className='time_date'>{moment(message.createdAt).format('LT')}</span>
                <div className='sent_msg'>
                  {message.messageType === 'user' && (
                    <p className='inner-text'>{message.message}</p>
                  )}
                  {message.messageType === 'file' && (
                    <a href={message.url} target='_blank'>
                      <p className='file-message-text'>{t('messages.file')}</p>
                      <p className='inner-text'>{message.name}</p>
                    </a>
                  )}
                </div>
                (
                <span className='profile-pic' id={'outgoing%' + message.messageId}>
                  <div className='dummy-circle'>
                    {message._sender.metaData.profileImage ? (
                      <img src={message._sender.profileUrl} alt='incoming_profile_image' />
                    ) : (
                      <Avatar
                        size='30px'
                        round='50%'
                        name={
                          message._sender.metaData.firstName +
                          ' ' +
                          message._sender.metaData.lastName
                        }
                      />
                    )}
                  </div>
                </span>
              </div>
            </>
          );
        }
        setMessages(temp);
      }
    }
  };
  sendBirdService.sb.addChannelHandler('msgHandler', handler);

  const messageValueChange = (event) => {
    if (event.target.value != ' ') {
      setMessageValue(event.target.value);
    }
  };

  const newMessage = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (messageValue && localConnectionStatus == 'connected' && sendBirdChannel) {
      sendBirdService
        .sendMessage({
          channel: sendBirdChannel,
          message: messageValue
        })
        .then((messageResponse) => {
          sendMessageInPanel(messageResponse);
        });
    } else {
      // console.log('null value', messageValue, '0', localConnectionStatus, sendBirdChannel)
    }
  };
  const sendMessageInPanel = (messageResponse) => {
    autoScroll();
    let temp = [];
    temp = temp.concat(messages);
    let lastMessage;
    if (messages.length > 0) {
      lastMessage =
        messages[messages.length - 1].props.children[1].props.className === 'outgoing_msg';
    }
    if (lastMessage) {
      document.getElementById(
        messages[messages.length - 1].props.children[1].props.children[2].props.id
      ).style.display = 'none';
    }
    let dateStrike;
    let dummySetback = false;
    let lastTextedDate;
    const reversedItems = messages.map((item) => item).reverse();

    for (let i = 0; i < reversedItems.length; i++) {
      if (reversedItems[i].props.children[0]) {
        lastTextedDate = reversedItems[i].props.children[0].props.children[1].props.children;
        if (lastTextedDate === t('messages.today')) {
          lastTextedDate = moment().format('DD-MMM-YYYY');
        }
        break;
      }
    }

    if (lastTextedDate != moment(messageResponse.createdAt).format('DD-MMM-YYYY')) {
      dateStrike = moment(messageResponse.createdAt).format('DD-MMM-YYYY');

      dummySetback = true;
    } else {
      dummySetback = false;
    }
    temp.push(
      <>
        {dummySetback && (
          <Striker>
            <div></div>
            <p>
              {dateStrike === moment().format('DD-MMM-YYYY') ? t('messages.today') : dateStrike}
            </p>
            <div></div>
          </Striker>
        )}
        <div key={temp.length} className='outgoing_msg'>
          <span className='time_date'> {moment().format('LT')} </span>
          <div className='sent_msg'>
            {/* <p className="inner-text">{messageValue}</p> */}
            {messageResponse.messageType === 'user' && (
              <p className='inner-text'>{messageResponse.message}</p>
            )}
            {messageResponse.messageType === 'file' && (
              <a href={messageResponse.url} target='_blank'>
                <p className='file-message-text'>{t('messages.file')}</p>
                <p className='inner-text'>{messageResponse.name}</p>
              </a>
            )}
          </div>
          {/* {lastMessage && ( */}
          <span className='profile-pic' id={'outgoing%' + messageResponse.messageId}>
            <div className='dummy-circle'>
              {user.user.profileImage ? (
                <img src={user.user.profileImage} alt='incoming_profile_image' />
              ) : (
                <Avatar
                  size='30px'
                  round='50%'
                  name={user.user.firstName + ' ' + user.user.lastName}
                />
              )}
            </div>
          </span>
          {/* )} */}
        </div>
      </>
    );
    setMessages(temp);
    setMessageValue('');
  };

  useEffect(() => {
    if (props.parentId) {
      parentId = props.parentId;
      sendBirdChannelURL = props.sendBirdChannelURL;
      setMessages([]);
      if (!sendBirdChannelURL) {
        let channelParams = {};
        channelParams.parent = parentType;
        channelParams.parentId = parentId;
        if (props.parentType == 'message_id') {
          channelParams.users = props.users;
        }
        sendBirdService.createOpenChannel(channelParams).then(
          (createdChannel) => {
            setChannel(createdChannel);
            let newChannelData = {
              sendBirdChannelURL: createdChannel.url
            };
            newChannelData[parentType] = parentId;
            newChannelData['parent'] = parentType;
            clientService.newChannel(newChannelData);
            sendBirdChannelURL = createdChannel.url;
            if (parentType != 'message_id') {
              sendBirdService.enterChannel(createdChannel);
            }
          },
          (error) => {
            console.error(error);
          }
        );
      } else {
        let params = { url: sendBirdChannelURL, parent: parentType, type: props.group };
        sendBirdService.getChannel(params).then((connectedChannel) => {
          setChannel(connectedChannel);
          if (parentType != 'project_id') {
            sendBirdService.enterChannel(connectedChannel);
          }
          // console.log("connectedChannel", connectedChannel);
          if (
            props.checkStatus &&
            connectedChannel.members[1] &&
            connectedChannel.customType === 'Single'
          ) {
            props.checkStatus(connectedChannel.members[1].connectionStatus, connectedChannel);
          }
          sendBirdService.retrieveChannelPreviosMessages(connectedChannel).then((prevMessages) => {
            // console.log(prevMessages)
            let temp = [];
            let dateStrike;
            let dummySetback = false;
            temp = prevMessages.map((messageItem, index) => {
              // console.log(
              //   "prevMessages",
              //   moment(messageItem.createdAt).format("DD-MMM-YYYY")
              // );
              if (dateStrike != moment(messageItem.createdAt).format('DD-MMM-YYYY')) {
                dateStrike = moment(messageItem.createdAt).format('DD-MMM-YYYY');
                dummySetback = true;
              } else {
                dummySetback = false;
              }
              if (user_id != messageItem._sender.userId) {
                let displayImage = prevMessages[index + 1]
                  ? prevMessages[index]._sender.userId != prevMessages[index + 1]._sender.userId
                    ? true
                    : false
                  : true;
                // console.log("displayImage", displayImage);
                return (
                  <>
                    {dummySetback && (
                      <Striker>
                        <div></div>
                        <p>
                          {dateStrike === moment().format('DD-MMM-YYYY')
                            ? t('messages.today')
                            : dateStrike}
                        </p>
                        <div></div>
                      </Striker>
                    )}
                    <div key={index} className='incoming_msg'>
                      {displayImage && (
                        <span
                          className='profile-pic'
                          id={
                            prevMessages.length - 1 === index
                              ? 'incoming%' + messageItem.messageId
                              : null
                          }
                        >
                          <div className='dummy-circle'>
                            {messageItem._sender.metaData.profileImage ? (
                              <img
                                src={messageItem._sender.metaData.profileImage}
                                alt='incoming_profile_image'
                              />
                            ) : (
                              <Avatar
                                size='30px'
                                round='50%'
                                name={
                                  messageItem._sender.metaData.firstName +
                                  ' ' +
                                  messageItem._sender.metaData.lastName
                                }
                              />
                            )}
                          </div>
                        </span>
                      )}
                      <div className='received_withd_msg'>
                        {messageItem.messageType === 'user' && (
                          <p className='inner-text'>{messageItem.message}</p>
                        )}
                        {messageItem.messageType === 'file' && (
                          <a href={messageItem.url} target='_blank'>
                            <p className='file-message-text'>{t('messages.file')}</p>
                            <p className='inner-text'>{messageItem.name}</p>
                          </a>
                        )}
                      </div>
                      <span className='time_date'>
                        {moment(messageItem.createdAt).format('LT')}
                      </span>
                    </div>
                  </>
                );
              } else {
                let displayImage = prevMessages[index + 1]
                  ? prevMessages[index]._sender.userId != prevMessages[index + 1]._sender.userId
                    ? true
                    : false
                  : true;
                // console.log("displayImage", displayImage);
                return (
                  <>
                    {dummySetback && (
                      <Striker>
                        <div></div>
                        <p>
                          {dateStrike === moment().format('DD-MMM-YYYY')
                            ? t('messages.today')
                            : dateStrike}
                        </p>
                        <div></div>
                      </Striker>
                    )}
                    <div key={index} className='outgoing_msg'>
                      <span className='time_date'>
                        {moment(messageItem.createdAt).format('LT')}
                      </span>
                      <div className='sent_msg'>
                        {messageItem.messageType === 'user' && (
                          <p className='inner-text'>{messageItem.message}</p>
                        )}
                        {messageItem.messageType === 'file' && (
                          <a href={messageItem.url} target='_blank'>
                            <p className='file-message-text'>{t('messages.file')}</p>
                            <p className='inner-text'>{messageItem.name}</p>
                          </a>
                        )}
                      </div>
                      {displayImage && (
                        <span
                          className='profile-pic'
                          id={
                            prevMessages.length - 1 === index
                              ? 'outgoing%' + messageItem.messageId
                              : null
                          }
                        >
                          <div className='dummy-circle'>
                            {messageItem._sender.metaData.profileImage ? (
                              <img
                                src={messageItem._sender.metaData.profileImage}
                                alt='incoming_profile_image'
                              />
                            ) : (
                              <Avatar
                                size='30px'
                                round='50%'
                                name={
                                  messageItem._sender.metaData.firstName +
                                  ' ' +
                                  messageItem._sender.metaData.lastName
                                }
                              />
                            )}
                          </div>
                        </span>
                      )}
                    </div>
                  </>
                );
              }
            });
            // console.log(temp)
            setMessages(temp);
          });
        });
      }
    }
  }, [sendBirdChannelURL]);

  const fileSelect = () => {
    document.getElementById('sendbirdfile').click();
  };

  const sendbirdFileChange = (event) => {
    let file = event.target.files[0];
    if (file && sendBirdChannel) {
      sendBirdService.sendFileAsAMessage(sendBirdChannel, file).then(
        (response) => {
          // console.log("resposnssss", messages, "response", response);
          sendMessageInPanel(response);
          // push the response.url as a message in the messages array
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error('no file selected or channel not set');
    }
  };

  return (
    <div
      className='row'
      style={{
        height: '100%',
        margin: '0',
        padding: '20px'
      }}
    >
      <div
        style={{
          width: '100%',
          height: '82%',
          overflow: 'scroll',
          padding: '20px'
        }}
        id='message_panel'
      >
        {messages}
        <InputWrapper>
          <div className='profile-logo'>
            {user.user.profileImage ? (
              <img src={user.user.profileImage} alt='Avatar' />
            ) : (
              <Avatar
                size='30px'
                round='50%'
                name={user.user.firstName + ' ' + user.user.lastName}
              />
            )}
          </div>
          <div className='input-message d-flex'>
            <form onSubmit={newMessage} id='formmessage' className='w-100 d-flex'>
              <input
                type='text'
                placeholder={t('messages.input_text')}
                name='message'
                value={messageValue}
                onChange={messageValueChange}
                autoFocus
              />
              <button type='submit' hidden />
              <div className='attachment-message-icon'>
                <ChatAttachments onClick={fileSelect} />
              </div>
            </form>
            <input
              type='file'
              onChange={sendbirdFileChange}
              name='sendbirdfile'
              hidden
              id='sendbirdfile'
            />
          </div>
        </InputWrapper>
      </div>
    </div>
  );
}

export default withTranslation('common')(Message);
