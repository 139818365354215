import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ProjectDetail from '../Project/ProjectDetail/ProjectDetail';
import ProjectDashboard from '../Project/ProjectDashboard/ProjectDashboard';
import InquiryDashboard from '../Inquiry/InquiryDashboard/InquiryDashboard';
import NewInquiry from '../Inquiry/NewInquiry/NewInquiry';
import InquiryDetail from '../Inquiry/InquiryDetail/InquiryDetail';
import OverViewPage from '../HomePage/OverView/OverViewPage';
import ProfessionalTaskPanel from '../Task/ProfessionalTaskPanel';
import AlertsDashboard from '../Alerts/AlertsDashboard';
import { Messages } from '../Messages/Messages';
import NewsPage from '../News/NewsPage';
import BusimessCases from '../BusimessCases/BusimessCases';
import CoffeeBreak from '../CoffeeBreak/CoffeeBreak'
import BusinessLibrary from '../BusinessLibrary/BusinessLibrary';
import BusinessTools from '../BusinessTools/BusinessTools';

const RoutesFileClient = (
  <Switch>
    <Route path={['/home']} exact component={OverViewPage} />
    <Route path='/home/inquiries' component={InquiryDashboard} />
    <Route path='/home/inquiry_new' component={NewInquiry} />
    <Route path='/home/inquiry_details' component={InquiryDetail} />
    <Route path='/home/projects' component={ProjectDashboard} />
    <Route path='/home/project_details' component={ProjectDetail} />
    <Route path='/home/notifications' component={AlertsDashboard} />
    <Route path='/home/messages' component={Messages} />
    <Route path='/home/tools' exact component={BusinessTools} />
    <Route path='/home/insight' component={NewsPage} />
    <Route path='/home/businessCases' exact component={BusimessCases} />
    <Route path='/home/coffeeBreak' exact component={CoffeeBreak} />
    <Route path='/home/businessLibrary' exact component={BusinessLibrary} />
    <Route path='*' component={OverViewPage} />
  </Switch>
);

const RoutesFileFaciliator = (
  <Switch>
    <Route exact path={['/', '/home']} render={() => <Redirect to='/home/inquiries' />} />
    <Route path='/home/inquiries' exact component={InquiryDashboard} />
    <Route path='/home/inquiry_new' component={NewInquiry} />
    <Route path='/home/inquiry_details' component={InquiryDetail} />
    <Route path='/home/projects' component={ProjectDashboard} />
    <Route path='/home/project_details' component={ProjectDetail} />
    <Route path='/home/notifications' component={AlertsDashboard} />
    <Route path='/home/messages' component={Messages} />
    {/* <Route path='/home/tools/businessLibrary' exact component={BusinessLibrary} /> */}
    <Route path='*' component={InquiryDashboard} />
  </Switch>
);

const RoutesFileProfessional = (
  <Switch>
    <Route exact path={['/', '/home']} render={() => <Redirect to='/home/projects' />} />
    <Route path='/home/projects' exact component={ProjectDashboard} />
    <Route path='/home/project_details' component={ProjectDetail} />
    <Route path='/home/task' component={ProfessionalTaskPanel} />
    {/* <Route path='/home/notifications' component={AlertsDashboard} /> */}
    <Route path='/home/messages' component={Messages} />
    <Route path='*' component={ProjectDashboard} />
  </Switch>
);

export const routesSection = {
  RoutesFileClient,
  RoutesFileFaciliator,
  RoutesFileProfessional
};
