import React, { useState } from 'react';
import styled from 'styled-components';
import * as moment from 'moment';
import ScheduleCalendar from '../../../public/icons/ScheduleCalendar';
import NumberFormat from 'react-number-format';
import { withTranslation } from 'react-i18next';
import 'moment/locale/ja';
import 'moment/locale/en-ca';

let DetailsRow = styled.div`
  div {
    font-family: Montserrat;
    font-size: 12px;
    line-height: 1.29;
    color: #4b4d4e;
    margin: 30px 0 0 0;
    text-align: left;
    @media (min-width: 1600px) {
      font-size: 13px;
    }
  }
`;
const ProjectDetailPanel = ({ t, i18n, projectDetails }) => {
  moment.locale('ja');

  const [currenySign, setcurrenySign] = useState(() => {
    if (projectDetails.currency === 'USD') {
      return '$';
    } else if (projectDetails.currency === 'EUR') {
      return '€';
    } else {
      return '¥';
    }
  });

  return (
    <div className='row'>
      <div className='col-md-6'>
        <DetailsRow className='row m-0'>
          <div className='col-md-3 left-section'>{t('project.details.details.title')}</div>
          <div className='col-md-3 right-section p-0'>{projectDetails.name}</div>
        </DetailsRow>
        <DetailsRow className='row m-0'>
          <div className='col-md-3 left-section'>{t('project.details.details.category')}</div>
          <div className='col-md-3 right-section p-0'>{projectDetails.category}</div>
        </DetailsRow>
        <DetailsRow className='row m-0'>
          <div className='col-md-3 left-section'>{t('project.details.details.sub-category')}</div>
          <div className='col-md-3 right-section p-0'>{projectDetails.subCategory}</div>
        </DetailsRow>
        <DetailsRow className='row m-0'>
          <div className='col-md-3 left-section'>{t('project.details.details.status')}</div>
          <div className='col-md-4 right-section p-0'>
            {projectDetails.status === 'not_started'
              ? t('projectStatus.not_started')
              : projectDetails.status === 'in_progress'
              ? t('projectStatus.in_progress')
              : t('projectStatus.completed')}
          </div>
          <div className='col-md-2 right-section p-0'>{t('project.details.details.budget')}</div>
          <div className='col-md-3 right-section p-0'>
            <NumberFormat
              className='currency-box'
              thousandSeparator={true}
              allowNegative={false}
              readOnly={true}
              displayType={'text'}
              prefix={currenySign}
              value={projectDetails.budget}
            />{' '}
            &nbsp;&nbsp;&nbsp;{projectDetails.currency}
          </div>
        </DetailsRow>
        <DetailsRow className='row m-0'>
          <div className='col-md-3 left-section'>{t('project.details.details.start_date')}</div>
          <div className='col-md-4 right-section p-0'>
            {moment(projectDetails.startDate).format('MMM D, YYYY')}&nbsp;&nbsp;&nbsp;
            <ScheduleCalendar />
          </div>
          <div className='col-md-2 right-section p-0'>{t('project.details.details.end_date')}</div>
          <div className='col-md-3 right-section p-0'>
            {moment(projectDetails.endDate).format('MMM D, YYYY')}&nbsp;&nbsp;&nbsp;
            <ScheduleCalendar />
          </div>
        </DetailsRow>
        <DetailsRow className='row m-0'>
          <div className='col-md-3 left-section'>{t('project.details.details.owner')}</div>
          <div className='col-md-4 right-section p-0'>{projectDetails.name}</div>
          <div className='col-md-2 right-section p-0'>
            {t('project.details.details.facilitator')}
          </div>
          <div className='col-md-3 right-section p-0'>{projectDetails.name}</div>
        </DetailsRow>
      </div>
      <div className='col-md-6'>
        <DetailsRow className='row m-0'>
          <div className='col-md-3 left-section p-0'>{t('project.details.details.background')}</div>
          <div className='col-md-7 right-section p-0'>{projectDetails.background}</div>
        </DetailsRow>
        <DetailsRow className='row m-0'>
          <div className='col-md-3 left-section p-0'>{t('project.details.details.details')}</div>
          <div className='col-md-7 right-section p-0'>{projectDetails.details}</div>
        </DetailsRow>
      </div>
    </div>
  );
};

export default withTranslation('common')(ProjectDetailPanel);
