import React, { useContext } from 'react';
import Iframe from 'react-iframe';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { UserContext } from '../../common/context/UserContext';
let IframeWrapper = styled.div`
  height: 100%;
  padding: 30px;
  div.feature-block {
    height: 100%;
    margin: 0;
    margin-top: 30px;
  }
`;
const NewsPage = ({ t, i18n, ...props }) => {
  const [{ user, webflowLinks }, dispatch] = useContext(UserContext);
  return (
    <>
      <IframeWrapper>
        <Iframe id='content_iframe' url={webflowLinks.jp.newsPage} width='100%' height='100%' />
      </IframeWrapper>
    </>
  );
};
export default withTranslation('common')(NewsPage);
