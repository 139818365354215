import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Modal, Loader } from '../../../common';
import styled from 'styled-components';
import { ProjectFilesConfig } from '../../../public/configuration';
import { ProjectFilesConfig as ProjectFilesConfigJP } from '../../../public/configurationJP';
import { projectService } from '../../../_services/project.service';
import { wrapComponent } from 'react-snackbar-alert';
import { UserContext } from '../../../common/context/UserContext';
import DeletePopUp from '../../../common/DeletePopUp';
import UploadButton from '../../../common/UploadButton';
import { withTranslation } from 'react-i18next';
import Pagination from '../../../common/Pagination';
import Table from '../../../common/Table';

let BottomPanel = styled.div`
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    div.button-footer{
        position: absolute;
        right: 110px;
        bottom: 30px;
    }

}
`;
let dataToSend = [];
const ProjectFilesPanel = wrapComponent(({ t, i18n, createSnackbar, projectDetails }) => {
  const [{ user }, dispatch] = useContext(UserContext);
  const [LoaderStatus, setLoader] = useState(false);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [totalPageNo, setTotalPageNo] = useState();
  const [filterField, setFilterField] = useState({ name: '', type: '' });
  const [deleteFile, setDeleteFile] = useState('');
  const [deleteModalState, setDeleteModalState] = useState(false);
  const closeDeleteModalHandler = useCallback(() => {
    setDeleteModalState(false);
  });
  const type = 'File';
  useEffect(() => {
    getFilesData(1, 0);
  }, [projectDetails._id]);

  const columns = [
    'fileName',
    'owner',
    'uploadedOn',
    'lastModified',
    'download',
    'share',
    'action'
  ];

  const showSnackbar = (msg) => {
    createSnackbar({
      theme: 'info',
      message: msg,
      timeout: 2000,
      dismissable: false,
      pauseOnHover: true,
      progressBar: false,
      sticky: false
    });
  };
  const displayFileName = (fileName) => {
    let temp = fileName.split('-');
    temp = temp[temp.length - 1];
    return temp;
  };
  const getFilesData = (page, limit, orderBy, order) => {
    setLoader(false);
    let data = {};
    if (limit === 0) {
      data = { project_id: projectDetails._id, page: page };
    } else {
      data = { project_id: projectDetails._id, page: page, limit: limit };
    }
    if (orderBy) {
      data['orderBy'] = orderBy;
      data['order'] = order;
    }
    if (projectDetails._id) {
      projectService.getFiles(data).then(
        (response) => {
          setTotalPageNo(Math.ceil(response.data.totalCount / 6));
          let temp = [];
          temp = response.data.result;
          for (let [key, value] of Object.entries(temp)) {
            let field = value;
            dataToSend.push({
              fileName: {
                type: 'string',
                value: displayFileName(field.name),
                fieldValue: field
              },
              owner: {
                type: 'string&image',
                value: field.addedBy,
                fieldValue: field
              },
              uploadedOn: {
                type: 'date',
                value: field.createdAt,
                fieldValue: field
              },
              download: {
                type: 'download',
                value: field.url,
                fieldValue: field
              },
              share: {
                type: 'share',
                value: field.url,
                fieldValue: field
              },
              action: {
                type: user.user.role === 'facilitator' ? 'close' : '',
                value: '',
                fieldValue: field
              }
            });
          }
          setDataToDisplay(dataToSend);
          setLoader(true);
          dataToSend = [];
        },
        (err) => {
          console.error(err);
        }
      );
    }
  };
  const fileUpload = useCallback(async (event) => {
    event.preventDefault();
    var data = new FormData();
    await Array.from(event.target.files).map((file) => data.append('document', file));
    data.append('project_id', projectDetails._id);
    await projectService.uploadFile(data).then(
      (response) => {
        showSnackbar(t('project.actions.files_add'));
        getFilesData(totalPageNo, 0);
      },
      (error) => {
        console.error(error);
      }
    );
  });

  const [projectConfig, setProjectConfig] = useState(ProjectFilesConfigJP);
  const handleSort = useCallback((index) => {
    let newConfigSortChange = [...projectConfig];
    newConfigSortChange[index].config.sortType = !newConfigSortChange[index].config.sortType;
    setProjectConfig(newConfigSortChange);
    setFilterField({
      ...filterField,
      ...{ name: columns[index], type: newConfigSortChange[index].config.sortType ? 'asc' : 'desc' }
    });
    getFilesData(1, 6, columns[index], newConfigSortChange[index].config.sortType ? 'asc' : 'desc');
    // setDataToDisplay(
    //     getSortedData(dataToDisplay, columns[index], newConfigSortChange[index].config.sortType)
    // );
  });

  const pageNoClicked = (pageNo) => {
    if (filterField.name) {
      getFilesData(pageNo, pageNo === -1 ? -1 : 6, filterField.name, filterField.type);
    } else {
      getFilesData(pageNo, pageNo === -1 ? -1 : 0);
    }
  };
  const deleteDate = async () => {
    let data = {};
    data.file_id = deleteFile._id;
    data.project_id = deleteFile.project;
    await projectService.deleteFile(data).then(
      (response) => {
        setDeleteModalState(false);
        showSnackbar(t('project.actions.files_delete'));
        getFilesData(1, 0);
      },
      (err) => {
        console.error('error', err);
      }
    );
  };

  const deleteAction = async (field) => {
    setDeleteFile(field);
    setDeleteModalState(true);
  };
  return (
    <>
      <div className='project-details-tabs'>
        {dataToDisplay ? (
          <>
            {LoaderStatus ? (
              <Table
                config={projectConfig}
                data={dataToDisplay}
                sort={handleSort}
                deleteDate={deleteAction}
              ></Table>
            ) : (
              <Loader />
            )}{' '}
            <BottomPanel>
              <div className='pagination-footer'>
                <Pagination
                  pageNoClicked={pageNoClicked}
                  totalPageNo={totalPageNo}
                  type={'Files'}
                />
              </div>
              <div className='button-footer'>
                <UploadButton
                  onChange={fileUpload}
                  multiple
                  accept='.doc,.docx,.png,.jpg,.jpeg,.pdf,.xls,.csv,.xlsx,.zip,.rar'
                />
              </div>
            </BottomPanel>
          </>
        ) : LoaderStatus ? (
          <></>
        ) : (
          <Loader />
        )}
        {deleteModalState && (
          <Modal show={deleteModalState} close={closeDeleteModalHandler}>
            <DeletePopUp delete={deleteDate} type={type} />
          </Modal>
        )}
      </div>
    </>
  );
});

export default withTranslation('common')(ProjectFilesPanel);
