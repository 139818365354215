import React, { useEffect, useState, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { clientService } from '../../../_services';
import { UserContext } from '../../../common/context/UserContext';
import Iframe from 'react-iframe';
import { withTranslation } from 'react-i18next';

let IframeWrapper = styled.div`
  height: 100%;
  padding: 10vh min(30px, 4vw);
  div.feature-block {
    height: 100%;
    margin: 0;
    margin-top: 30px;
  }
`;

function OverViewPage({ t, i18n, ...props }) {
  const [{ user, webflowLinks }, dispatch] = useContext(UserContext);
  const [homeData, setHomeData] = useState();
  useEffect(() => {
    getHomeData();
  }, []);
  const getHomeData = () => {
    clientService.dashboardAlerts().then((response) => {
      setHomeData(response.data);
    });
  };

  return (
    <IframeWrapper>
      <Iframe id='content_iframe' url={webflowLinks.jp.eisDashboard} width='100%' height='100%' />
    </IframeWrapper>
  );
}

export default withTranslation('common')(OverViewPage);
