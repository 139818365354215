import React from 'react';

const HeaderLogo = (props) => (
  <svg
    id='prefix__Calque_1'
    x={0}
    y={0}
    viewBox='0 0 388.11 46.85'
    xmlSpace='preserve'
    {...props}
    style={{ width: ' 77%', height: '100%', position: 'relative', left: '-40px' }}
  >
    <style>
      {'.prefix__st0{fill:#36a9e1}.prefix__st1{fill:#504d4a}.prefix__st5{fill:#8a8789}'}
    </style>
    <g>
      <path
        className='prefix__st0'
        d='M79.3,22.41c-1.41-1.53-2.74-3.13-4.21-4.61C69.75,12.41,63.79,7.67,56.69,4.2c-4.59-2.25-9.45-3.78-14.71-4.13C36-0.32,30.38,0.9,25.06,3.25C14.96,7.7,7.13,14.51,0.4,22.44c-0.6,0.71-0.49,1.41,0.09,2.09c5.85,6.87,12.62,12.85,20.94,17.31c5.62,3.02,11.64,4.96,16.79,5.01c7.2,0.01,12.5-1.41,17.49-3.77c9.53-4.51,17.05-11.07,23.59-18.64C79.92,23.73,79.94,23.1,79.3,22.41z M55.62,17.85c-1.07,3.74-2.41,7.42-3.85,11.04c-1.25,3.15-2.68,4.41-6.15,5.18c0.99-2.13,4.73-13.72,5.76-17c0.88-2.83-0.55-5.59-3.26-6.68c-2.63-1.06-5.49-0.03-6.82,2.37c1.25,1.12,2.64,2.09,3.66,3.36c2.11,2.62,2.67,5.69,1.65,8.91c-1.07,3.36-2.19,6.71-3.5,9.97c-2.92,7.27-12.38,8.67-17.29,2.58c-2.02-2.5-2.72-5.46-1.8-8.54c1.11-3.69,2.38-7.35,3.82-10.92c1.25-3.12,3.2-4.51,6.7-5.6c-1.05,2.46-5.14,13.99-6.16,17.31c-1.01,2.92,0.57,5.42,3.11,6.57c2.42,1.1,5.3,0.35,6.85-1.81c0.14-0.2,0.22-0.44,0.36-0.72c-2.33-1.15-4.07-2.83-5.1-5.2c-0.9-2.08-1.15-4.25-0.45-6.4c1.17-3.63,2.36-7.27,3.79-10.8c1.9-4.67,7.41-7.04,12.15-5.44C54.01,7.71,57.03,12.93,55.62,17.85z'
      />
      <path
        className='prefix__st0'
        d='M39.41,17.2c-0.72,2.1-1.54,4.16-2.12,6.29c-0.67,2.42,0.56,4.86,3.01,6.19c0.76-2.16,1.59-4.18,2.17-6.26C43.21,20.8,41.88,18.19,39.41,17.2z'
      />
    </g>
    <path
      className='prefix__st1'
      d='M120.48,4.05v6.24H99.51v10.4h18.83v5.9H99.51v10.51h21.64v6.24H92.88V4.05H120.48z'
    />
    <path className='prefix__st0' d='M143.01,4.05h6.63l-13.56,39.3h-6.63L143.01,4.05z' />
    <path
      className='prefix__st1'
      d='M169.78,9.22c-1.93,0-3.51,0.39-4.75,1.18c-1.24,0.79-1.86,1.98-1.86,3.57c0,1.59,0.62,2.81,1.86,3.65c1.24,0.84,3.87,1.75,7.9,2.73c4.03,0.97,7.07,2.34,9.11,4.1c2.04,1.76,3.06,4.36,3.06,7.79c0,3.43-1.29,6.21-3.88,8.35c-2.59,2.14-5.98,3.2-10.18,3.2c-6.15,0-11.6-2.12-16.36-6.35l4.16-5c3.97,3.45,8.1,5.17,12.37,5.17c2.14,0,3.83-0.46,5.09-1.38c1.25-0.92,1.88-2.14,1.88-3.65c0-1.52-0.59-2.7-1.77-3.54c-1.18-0.84-3.21-1.61-6.1-2.31c-2.89-0.69-5.08-1.33-6.58-1.91c-1.5-0.58-2.83-1.34-3.99-2.28c-2.32-1.76-3.49-4.46-3.49-8.1c0-3.64,1.32-6.44,3.96-8.4c2.64-1.97,5.91-2.95,9.81-2.95c2.51,0,5,0.41,7.48,1.24c2.47,0.82,4.61,1.99,6.41,3.49l-3.54,5c-1.16-1.05-2.74-1.91-4.72-2.59C173.67,9.56,171.71,9.22,169.78,9.22z'
    />
    <text
      transform='translate(192.392 12.467)'
      className='prefix__st0'
      fontSize={7.999}
      fontFamily='Montserrat-Bold'
    >
      {'R'}
    </text>
    <circle cx={195.13} cy={9.76} r={5.17} fill='none' stroke='#36a9e1' strokeMiterlimit={10} />
    <g>
      <path
        className='prefix__st5'
        d='M229.99,10.83h3.05v31.32h-3.27l-19.4-26.53v26.53h-3.09V10.83h3.27l19.45,26.53V10.83z'
      />
      <path
        className='prefix__st5'
        d='M259.87,22.06c1.91,2.32,2.79,5.43,2.64,9.34h-19.58c0.21,2.51,1.13,4.53,2.78,6.07c1.64,1.54,3.67,2.31,6.09,2.31c1.49,0,2.88-0.27,4.17-0.81c1.28-0.54,2.39-1.3,3.32-2.28l1.7,1.7c-1.14,1.23-2.5,2.17-4.1,2.85c-1.6,0.67-3.35,1.01-5.27,1.01c-2.27,0-4.29-0.5-6.05-1.5c-1.76-1-3.14-2.4-4.15-4.19c-1-1.79-1.5-3.84-1.5-6.14c0-2.27,0.5-4.31,1.5-6.12c1-1.81,2.38-3.21,4.15-4.21c1.76-1,3.75-1.5,5.96-1.5C255.18,18.58,257.96,19.74,259.87,22.06zM259.83,29.07c-0.15-2.45-0.96-4.4-2.44-5.85c-1.48-1.45-3.41-2.17-5.8-2.17c-2.36,0-4.33,0.73-5.91,2.2c-1.58,1.46-2.49,3.41-2.73,5.83H259.83z'
      />
      <path
        className='prefix__st5'
        d='M280.62,40.54c-1.61,1.11-3.35,1.69-5.2,1.75c-1.79,0-3.21-0.55-4.26-1.66c-1.05-1.11-1.57-2.76-1.57-4.97V21.67h-3.36l-0.04-2.33h3.41V13.3h3v6.05l7.62-0.04v2.37h-7.62v13.49c0,2.9,1.08,4.35,3.23,4.35c1.19,0,2.49-0.42,3.9-1.25L280.62,40.54z'
      />
      <path
        className='prefix__st5'
        d='M281.87,18.67h3.23l7.21,20.48l7.12-20.48h3.18l7.17,20.48l7.17-20.48h3.14l-8.78,23.48h-3.14l-7.17-19.85l-7.17,19.85h-3.14L281.87,18.67z'
      />
      <path
        className='prefix__st5'
        d='M339.14,20.08c1.79,1,3.2,2.4,4.21,4.19c1.02,1.79,1.52,3.82,1.52,6.09c0,2.3-0.51,4.35-1.52,6.16c-1.02,1.81-2.42,3.21-4.21,4.21s-3.82,1.5-6.09,1.5c-2.27,0-4.3-0.5-6.09-1.5c-1.79-1-3.2-2.4-4.21-4.21c-1.02-1.81-1.52-3.86-1.52-6.16c0-2.27,0.51-4.3,1.52-6.09c1.02-1.79,2.42-3.19,4.21-4.19c1.79-1,3.82-1.5,6.09-1.5C335.31,18.58,337.35,19.08,339.14,20.08z M328.47,22.35c-1.34,0.78-2.39,1.87-3.14,3.27c-0.75,1.4-1.12,3-1.12,4.79c0,1.82,0.37,3.44,1.12,4.84c0.75,1.4,1.79,2.49,3.14,3.27c1.34,0.78,2.87,1.17,4.57,1.17s3.23-0.39,4.57-1.17c1.34-0.78,2.39-1.87,3.14-3.27c0.75-1.4,1.12-3.02,1.12-4.84c0-1.79-0.37-3.39-1.12-4.79c-0.75-1.4-1.79-2.49-3.14-3.27c-1.34-0.78-2.87-1.17-4.57-1.17S329.82,21.57,328.47,22.35z'
      />
      <path
        className='prefix__st5'
        d='M357.58,20.04c1.45-0.94,3.19-1.43,5.22-1.46v2.82c-2.48-0.03-4.47,0.69-5.98,2.17c-1.51,1.48-2.37,3.49-2.58,6.03v12.55h-3V18.67h3v5.47C355.01,22.35,356.13,20.98,357.58,20.04z'
      />
      <path
        className='prefix__st5'
        d='M384.48,42.15l-8.33-12.23l-5.29,5.42v6.81h-3V8.9h3v22.58l12.64-12.82h3.59l-8.83,9.1l9.86,14.38H384.48z'
      />
    </g>
  </svg>
);

export { HeaderLogo };
