import React, { useState, useEffect, useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authenticationService } from './../_services';
import { Loader } from '../common/Loader';
import { UserContext } from '../common/context/UserContext';

const PrivateRoute = ({ path, component, location }) => {
  const [{ user }, dispatch] = useContext(UserContext);
  const [loginStatus, setLoginStatus] = useState({ isLogged: false, gotUnswerFromServer: false });
  const webflow_urls = {
    en: {
      eisProfile: 'https://www.eisnetwork.co/en/eis-platform/about-us',
      eisDashboard: 'https://www.eisnetwork.co/en/eis-platform/featured-blog',
      newsPage: 'https://www.eisnetwork.co/en/eis-platform/news',
      featuredBusinessCases:
        'https://www.eisnetwork.co/en/eis-platform/business-tools/menu-tools/featured-business-cases',
      startUps: 'https://www.eisnetwork.co/en/eis-platform/business-tools/startups',
      businessCases: 'https://www.eisnetwork.co/en/eis-platform/business-tools/business-cases',
      coffeeBreak: 'https://www.eisnetwork.co/jp/eis-platform/eis-coffee-break',
      businessLibrary:
        'https://www.eisnetwork.co/en/eis-platform/business-tools/business-templates',
      professionals: 'https://www.eisnetwork.co/en/eis-platform/business-tools/professionals',
      innerStartUps: 'https://www.eisnetwork.co/en/eis-platform/business-tools/menu-tools/startups',
      innerBusinessCases:
        'https://www.eisnetwork.co/en/eis-platform/business-tools/menu-tools/business-cases',
      innerBusinessTemplate:
        'https://www.eisnetwork.co/en/eis-platform/business-tools/menu-tools/business-templates',
      innerProfessional:
        'https://www.eisnetwork.co/en/eis-platform/business-tools/menu-tools/professionals'
    },
    jp: {
      eisProfile: 'https://www.eisnetwork.co/jp/eis-platform/about-us',
      eisDashboard: 'https://www.eisnetwork.co/jp/eis-platform/featured-blog',
      newsPage: 'https://www.eisnetwork.co/jp/eis-platform/news',
      featuredBusinessCases:
        'https://www.eisnetwork.co/jp/eis-platform/business-tools/menu-tools/featured-business-cases',
      startUps: 'https://www.eisnetwork.co/jp/eis-platform/business-tools/startups',
      businessCases: 'https://www.eisnetwork.co/jp/eis-platform/business-tools/business-cases',
      coffeeBreak: 'https://www.eisnetwork.co/jp/eis-platform/eis-coffee-break',
      businessLibrary:
        'https://www.eisnetwork.co/jp/eis-platform/business-tools/business-templates',
      professionals: 'https://www.eisnetwork.co/jp/eis-platform/business-tools/professionals',
      innerStartUps: 'https://www.eisnetwork.co/jp/eis-platform/business-tools/menu-tools/startups',
      innerBusinessCases:
        'https://www.eisnetwork.co/jp/eis-platform/business-tools/menu-tools/business-cases',
      innerBusinessTemplate:
        'https://www.eisnetwork.co/jp/eis-platform/business-tools/menu-tools/business-templates',
      innerProfessional:
        'https://www.eisnetwork.co/jp/eis-platform/business-tools/menu-tools/professionals'
    }
  };

  useEffect(() => {
    authenticationService.isLoggedIn().then(
      (res) => {
        authenticationService.getDropDown().then(
          (dropdownResponse) => {
            if (res.data.status) {
              dispatch({
                type: 'SET_USER_DETAILS',
                payload: res.data,
                dropdownAll: dropdownResponse.data
              });
              dispatch({
                type: 'SET_DROPDOWN',
                dropdown: dropdownResponse.data.japaneseData
              });
              if (res.data.user.role === 'client') {
                dispatch({
                  type: 'SET_WEBFLOW',
                  webflowLinks: webflow_urls
                });
              }
              setLoginStatus({ gotUnswerFromServer: true, isLogged: true });
            } else {
              setLoginStatus({ gotUnswerFromServer: true, isLogged: false });
            }
          },
          (err) => {
            setLoginStatus({ gotUnswerFromServer: true, isLogged: false });
          }
        );
      },
      (err) => {
        setLoginStatus({ gotUnswerFromServer: true, isLogged: false });
      }
    );
  }, []);
  // console.log("loginStatus",loginStatus)
  return loginStatus.gotUnswerFromServer ? (
    loginStatus.isLogged ? (
      <Route path={path} component={component} />
    ) : (
      <Redirect to={{ pathname: '/login', state: { from: location } }} />
    )
  ) : (
    <Loader />
  ); //this null can be repaced by the loader later
};

export default PrivateRoute;
