import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { ProjectTeamConfig } from '../../../public/configuration';
import { ProjectTeamConfig as ProjectTeamConfigJP } from '../../../public/configurationJP';
import { Table, Pagination, Button, Modal } from '../../../common';
import AddIcon from '../../../public/icons/AddIcon';
import Close from '../../../public/icons/Close';
import { wrapComponent } from 'react-snackbar-alert';
import validator from 'validator';
import { authenticationService } from '../../../_services';
import { projectService } from '../../../_services/project.service';
import { withTranslation } from 'react-i18next';

let InviteFormPanel = styled.form`
  width: 30vw;
  padding: 30px 0 0 0;
`;
let HeaderTitleModal = styled.p`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.29;
  text-align: center;
  color: #4b4d4e;
  @media (min-width: 1600px) {
    font-size: 13px;
  }
`;
let LabelInvite = styled.p`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 11px;
  margin-bottom: 10px;
  @media (min-width: 1600px) {
    font-size: 12px;
  }
`;
let InputRow = styled.input`
  width: 100%;
  border: 0;
  border-bottom: 1px solid #565353;
  text-align: center;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 12px;
  @media (min-width: 1600px) {
    font-size: 13px;
  }
`;
let ErrorPTag = styled.p`
  color: red;
  margin-top: 5px;
  font-size: 9px;
  @media (min-width: 1600px) {
    font-size: 12px;
  }
`;
const InviteCollaborator = wrapComponent(({ createSnackbar, setInviteProfessional, t }) => {
  const [inviteForm, setInviteForm] = useState([
    {
      firstName: '',
      lastName: '',
      email: ''
    }
  ]);
  const removeInvite = (index) => {
    let temp = inviteForm;
    let erroTemp = errorFlag;
    temp.splice(index, 1);
    erroTemp.splice(index, 1);
    setInviteForm([...temp]);
    setErrorFlag([...erroTemp]);
  };

  const [errorFlag, setErrorFlag] = useState([
    {
      firstName: '',
      lastName: '',
      email: ''
    }
  ]);

  const inviteFieldHandler = (event, index) => {
    let temp = inviteForm;
    temp[index][event.target.name] = event.target.value;
    setInviteForm([...temp]);
    // console.log("inviteForminviteForminviteForminviteForm", inviteForm);
  };

  const increaseFields = useCallback(() => {
    if (inviteForm.length > 4) {
      return;
    }
    let inviteFormOld = inviteForm;
    inviteFormOld.push({
      firstName: '',
      lastName: '',
      email: ''
    });
    let errorFlagForm = errorFlag;
    errorFlagForm.push({
      firstName: '',
      lastName: '',
      email: ''
    });
    setInviteForm([...inviteFormOld]);
    setErrorFlag([...errorFlagForm]);
  });

  const validateField = async (event, index) => {
    let temp = errorFlag;
    let inveteTemp = inviteForm;
    temp[index][event.target.name] = false;
    if (
      event.target.name === 'firstName' &&
      (!event.target.value || event.target.value.length < 2)
    ) {
      temp[index][event.target.name] = t('collaborator.actions.invalid');
    } else if (
      event.target.name === 'lastName' &&
      event.target.value &&
      event.target.value.length < 2
    ) {
      temp[index][event.target.name] = t('collaborator.actions.invalid');
    } else if (event.target.name == 'email' && !validator.isEmail(event.target.value)) {
      temp[index][event.target.name] = t('collaborator.actions.invalid');
    } else if (event.target.name == 'email' && validator.isEmail(event.target.value)) {
      let result = await authenticationService.checkEmailExist({ email: event.target.value });
      if (result.data.result !== 0) {
        temp[index]['email'] = t('collaborator.actions.email_exists');
      }
    }
    setErrorFlag([...temp]);
  };

  const showSnackbar = (msg, theme) => {
    createSnackbar({
      theme: theme,
      message: msg,
      timeout: 2000,
      dismissable: false,
      pauseOnHover: true,
      progressBar: false,
      sticky: false
    });
  };

  const validateFormStep = async () => {
    var errMsg = false;
    try {
      await inviteForm.map((form, index) => {
        if (inviteForm[index].email.length < 2 || !validator.isEmail(inviteForm[index].email)) {
          errMsg = t('collaborator.actions.valid_email');
        } else if (inviteForm[index].firstName.length < 2) {
          errMsg = t('collaborator.actions.first_name_valid');
        } else if (inviteForm[index].lastName.length && inviteForm[index].lastName.length < 2) {
          errMsg = t('collaborator.actions.last_name_valid');
        }
      });
    } finally {
      if (errMsg) {
        return errMsg;
      } else {
        return false;
      }
    }
  };

  const submit = async (event) => {
    event.preventDefault();
    const verify = await validateFormStep();
    if (!verify) {
      await inviteForm.map((form, index) => {
        let data = {};
        data.firstName = inviteForm[index].firstName;
        data.lastName = inviteForm[index].lastName;
        data.email = inviteForm[index].email;
        projectService.inviteProfessionals(data).then(
          (response) => {
            if (response) {
              showSnackbar(
                t('collaborator.actions.invite_sent', { email: inviteForm[index].email }),
                'info'
              );
              setInviteProfessional(false);
            }
          },
          (error) => {
            if (error && error.response && error.response.data) {
              error = error.response.data.error;
              showSnackbar(error, 'error');
              setInviteProfessional(false);
            }
            // showSnackbar("Failed to send invite", "error");
          }
        );
      });
    } else {
      showSnackbar(verify, 'error');
    }
  };

  return (
    <InviteFormPanel name='form' onSubmit={(event) => submit(event)}>
      <HeaderTitleModal>{t('collaborator.header')}</HeaderTitleModal>
      {inviteForm &&
        inviteForm.map((field, index) => (
          <div key={'invite-add' + index}>
            <div className='row' style={{ margin: '20px 0' }}>
              <div className='col-md-3'>
                <div className='row m-0'>
                  <LabelInvite>{t('collaborator.first_name')}</LabelInvite>
                </div>
                <div className='row m-0'>
                  <InputRow
                    type='text'
                    placeholder={t('collaborator.first_name')}
                    name='firstName'
                    error={errorFlag[index].firstName}
                    value={inviteForm[index].firstName}
                    onChange={(event) => inviteFieldHandler(event, index)}
                    onBlur={(event) => validateField(event, index)}
                  />
                  {errorFlag[index].firstName && (
                    <ErrorPTag>{errorFlag[index].firstName}</ErrorPTag>
                  )}
                </div>
              </div>
              <div className='col-md-3'>
                <div className='row m-0'>
                  <LabelInvite>{t('collaborator.last_name')}</LabelInvite>
                </div>
                <div className='row m-0'>
                  <InputRow
                    type='text'
                    error={errorFlag[index].lastName}
                    placeholder={t('collaborator.last_name')}
                    name='lastName'
                    value={inviteForm[index].lastName}
                    onChange={(event) => inviteFieldHandler(event, index)}
                    onBlur={(event) => validateField(event, index)}
                  />
                  {errorFlag[index].lastName && <ErrorPTag>{errorFlag[index].lastName}</ErrorPTag>}
                </div>
              </div>
              <div className='col-md-4'>
                <div className='row m-0'>
                  <LabelInvite>{t('collaborator.email')}</LabelInvite>
                </div>
                <div className='row m-0'>
                  <InputRow
                    type='text'
                    placeholder={t('collaborator.email')}
                    error={errorFlag[index].email}
                    name='email'
                    value={inviteForm[index].email}
                    onChange={(event) => inviteFieldHandler(event, index)}
                    onBlur={(event) => validateField(event, index)}
                  />
                  {errorFlag[index].email && <ErrorPTag>{errorFlag[index].email}</ErrorPTag>}
                </div>
              </div>
              <div className='col-md-2'>
                {inviteForm.length > 1 && (
                  <p className='hover m-0' onClick={(e) => removeInvite(index)}>
                    <Close />
                  </p>
                )}
              </div>
            </div>
          </div>
        ))}
      {inviteForm.length < 5 && (
        <p onClick={increaseFields} className='hover'>
          <AddIcon />
        </p>
      )}
      <Button text={t('collaborator.send_invite')} type='submit' />
    </InviteFormPanel>
  );
});

export default withTranslation('common')(InviteCollaborator);
