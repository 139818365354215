import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { InquiryDetailFilesConfig } from '../../../public/configuration';
import { InquiryDetailFilesConfig as InquiryDetailFilesConfigJP } from '../../../public/configurationJP';
import '../inquiry.css';
import { inquiryService } from '../../../_services/inquiry.service';
import { withTranslation } from 'react-i18next';
import UploadButton from '../../../common/UploadButton';
import Message from '../../../common/Message';
import Table from '../../../common/Table';

const InquiryFiles = ({ t, i18n, inquiry }) => {
  const tabs = ['files', 'messages'];
  const [key, setKey] = useState('files');
  const [newFiles, setNewFiles] = useState([]);
  const [inquiryFilesConfig, setInquiryFilesConfig] = useState(InquiryDetailFilesConfigJP);
  const columns = ['filename', 'uploadedon'];
  useEffect(() => {
    drawUnderline(key);
  }, []);

  useEffect(() => {
    if (inquiry._id) {
      inquiryService.getFiles({ inquiry_id: inquiry._id }).then(
        (response) => {
          let temp = [];
          temp = response.data.result;
          for (let [key, value] of Object.entries(temp)) {
            let field = value;
            dataToSend.push({
              filename: {
                type: 'string',
                value: displayFileName(field.name),
                fieldValue: field
              },
              uploadedon: {
                type: 'date',
                value: field.updatedAt,
                fieldValue: field
              },
              download: {
                type: 'download',
                value: field.url,
                fieldValue: field
              },
              share: {
                type: 'share',
                value: field.url,
                fieldValue: field
              }
            });
          }
          setNewFiles(dataToSend);
          dataToSend = [];
        },
        (err) => {
          console.error(err);
        }
      );
    }
  }, [inquiry._id]);

  const drawUnderline = (k) => {
    tabs.map((tab) => {
      if (tab != k) {
        var child = document.getElementById('project-tab-tab-' + tab);
        for (var i = 1; i < child.childNodes.length; i++) {
          child.removeChild(child.childNodes[i]);
        }
      }
    });
    var createDiv = document.createElement('div');
    createDiv.classList.add('underline-under-selected');
    document.getElementById('project-tab-tab-' + k).appendChild(createDiv);
  };

  const handleSort = useCallback((index) => {
    // console.log("index");
    let newConfigSortChange = [...inquiryFilesConfig];
    newConfigSortChange[index].config.sortType = !newConfigSortChange[index].config.sortType;
    setInquiryFilesConfig(newConfigSortChange);
    setNewFiles(
      getSortedData(newFiles, columns[index], newConfigSortChange[index].config.sortType)
    );
  });
  const getSortedData = (data, prop, isAsc) => {
    return data.sort(
      (a, b) =>
        (a[prop].value.toLowerCase() < b[prop].value.toLowerCase() ? -1 : 1) * (isAsc ? 1 : -1)
    );
  };
  const displayFileName = (fileName) => {
    let temp = fileName.split('-');
    temp = temp[temp.length - 1];
    return temp;
  };
  let dataToSend = [];
  const fileUpload = useCallback(async (event) => {
    event.preventDefault();
    var data = new FormData();
    // console.log(event.target.files)
    await Array.from(event.target.files).map((file) => data.append('document', file));
    data.append('inquiry_id', inquiry._id);
    // console.log(data)
    await inquiryService.uploadFile(data).then(
      (response) => {
        // console.log("response", response);

        if (response.data.result) {
          for (let [key, value] of Object.entries(response.data.result)) {
            let field = value;
            dataToSend.push({
              filename: {
                type: 'string',
                value: displayFileName(field.name),
                fieldValue: field
              },
              uploadedon: {
                type: 'date',
                value: field.updatedAt,
                fieldValue: field
              },
              download: {
                type: 'download',
                value: field.url,
                fieldValue: field
              },
              share: {
                type: 'share',
                value: field.url,
                fieldValue: field
              }
            });
          }
          setNewFiles([...newFiles, ...dataToSend]);
          dataToSend = [];
          // console.log("index", newFiles);
        }
        // let temp = []
        // temp = temp.concat(files)
        // temp.push(response.data.result)
        // document.getElementById('fileUpload').value = null
        // setFiles(temp)
      },
      (error) => {
        console.error(error);
      }
    );
  });
  const selectedTab = (k) => {
    setKey(k);
    drawUnderline(k);
  };

  return (
    <div className='row' style={{ flexDirection: 'column', width: '95%', position: 'relative' }}>
      {key == 'files' && (
        <UploadButton
          onChange={fileUpload}
          multiple
          accept='.doc,.docx,.png,.jpg,.jpeg,.pdf,.xls,.csv,.xlsx,.zip,.rar'
        />
      )}
      <Tabs id='project-tab' activeKey={key} onSelect={(k) => selectedTab(k)}>
        <Tab eventKey='files' title={t('inquiry.details.files')}>
          {newFiles.length > 0 && (
            <Table config={inquiryFilesConfig} data={newFiles} sort={handleSort}></Table>
          )}
        </Tab>
        <Tab eventKey='messages' title={t('inquiry.details.messages')}>
          {key == 'messages' && (
            <Message
              parentType='inquiry_id'
              parentId={inquiry._id}
              sendBirdChannelURL={inquiry.sendBirdChannelURL}
            ></Message>
          )}
        </Tab>
      </Tabs>
    </div>
  );
};
export default withTranslation('common')(InquiryFiles);
