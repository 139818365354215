import React, { useState, useEffect, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { ProjectTeamConfig } from '../../../public/configuration';
import { ProjectTeamConfig as ProjectTeamConfigJP } from '../../../public/configurationJP';
import { Button, Modal, Loader } from '../../../common';
import { projectService } from '../../../_services/project.service';
import { wrapComponent } from 'react-snackbar-alert';
import InviteCollaborator from './InviteCollaborator';
import { UserContext } from '../../../common/context/UserContext';
import { withTranslation } from 'react-i18next';
import Pagination from '../../../common/Pagination';
import Table from '../../../common/Table';

let BottomPanel = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  div.button-footer {
    position: absolute;
    right: 50px;
    bottom: 0;
  }
`;

const ProjectTeamPanel = wrapComponent(({ t, i18n, createSnackbar, projectDetails }) => {
  const [{ user }, dispatch] = useContext(UserContext);
  const [inviteProfessional, setInviteProfessional] = useState(false);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [totalPageNo, setTotalPageNo] = useState();
  const columns = ['name', 'status', 'addedOn', 'addedBy'];
  const [LoaderStatus, setLoader] = useState(false);

  const [projectConfig, setProjectConfig] = useState(ProjectTeamConfigJP);
  const [filterField, setFilterField] = useState({ name: '', type: '' });

  const handleSort = useCallback((index) => {
    let newConfigSortChange = [...projectConfig];
    newConfigSortChange[index].config.sortType = !newConfigSortChange[index].config.sortType;
    setProjectConfig(newConfigSortChange);

    setFilterField({
      ...filterField,
      ...{ name: columns[index], type: newConfigSortChange[index].config.sortType ? 'asc' : 'desc' }
    });
    getTeam(1, 6, columns[index], newConfigSortChange[index].config.sortType ? 'asc' : 'desc');
    // setDataToDisplay(
    //   getSortedData(
    //     dataToDisplay,
    //     columns[index],
    //     newConfigSortChange[index].config.sortType
    //   )
    // );
  });

  useEffect(() => {
    getTeam(1, 0);
  }, []);

  const getTeam = (page, limit, orderBy, order) => {
    setLoader(false);
    let data = {};
    if (limit === 0) {
      data = { project_id: projectDetails._id, page: page };
    } else {
      data = { project_id: projectDetails._id, page: page, limit: limit };
    }
    if (orderBy) {
      data['orderBy'] = orderBy;
      data['order'] = order;
    }
    if (projectDetails._id) {
      projectService.getTeam(data).then(
        (response) => {
          // console.log("response team", response);
          if (response.data.result) {
            setTotalPageNo(Math.ceil(response.data.totalCount / 6));
            let dataToSend = [];
            response.data.result.map((field, index) => {
              dataToSend.push({
                name: {
                  type: 'string&image',
                  value: field.member,
                  fieldValue: field
                },
                status: {
                  type: 'string',
                  value: field.role,
                  fieldValue: field
                },
                addedOn: {
                  type: 'date',
                  value: field.addedOn ? field.addedOn : projectDetails.createdAt,
                  fieldValue: field
                },
                addedBy: {
                  type: 'string&image',
                  value: field.addedBy,
                  fieldValue: field
                }
              });
            });
            setDataToDisplay(dataToSend);
            setLoader(true);
          }
        },
        (err) => {
          console.error('error project filers', err);
        }
      );
    }
  };
  const pageNoClicked = (pageNo) => {
    if (filterField.name) {
      getTeam(pageNo, pageNo === -1 ? -1 : 6, filterField.name, filterField.type);
    } else {
      getTeam(pageNo, pageNo === -1 ? -1 : 0);
    }
  };

  return (
    <>
      <div className='project-details-tabs'>
        {dataToDisplay ? (
          <>
            {LoaderStatus ? (
              <Table config={projectConfig} data={dataToDisplay} sort={handleSort}></Table>
            ) : (
              <Loader />
            )}
            <BottomPanel>
              <div>
                <Pagination
                  pageNoClicked={pageNoClicked}
                  totalPageNo={totalPageNo}
                  type={'Members'}
                />
              </div>
              {user.user.role !== 'professional' && (
                <div className='button-footer'>
                  <Button
                    text={t('universal_actions.invite')}
                    fullWidth='140%'
                    switch={true}
                    onClick={(e) => setInviteProfessional(true)}
                  />
                </div>
              )}
            </BottomPanel>
            {inviteProfessional && (
              <Modal show={inviteProfessional} close={() => setInviteProfessional(false)}>
                <InviteCollaborator setInviteProfessional={setInviteProfessional} />
              </Modal>
            )}
          </>
        ) : LoaderStatus ? (
          <></>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
});

export default withTranslation('common')(ProjectTeamPanel);
