import React from 'react';

const Close = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    id='Group_3'
    width='18'
    height='18'
    data-name='Group 3'
    viewBox='0 0 24.5 24.5'
  >
    <defs></defs>
    <circle id='Oval' cx='12.25' cy='12.25' r='12.25' style={{ fill: '#e2e6ea' }} />
    <path
      id='Path_4'
      d='M0 0l7 7'
      style={{
        fill: 'none',
        stroke: '#828385',
        strokeLinecap: 'round',
        strokeMiterlimit: '10',
        strokeWidth: '2px'
      }}
      data-name='Path 4'
      transform='translate(9 9)'
    />
    <path
      id='Path_4_Copy'
      d='M0 0l-7 7'
      style={{
        fill: 'none',
        stroke: '#828385',
        strokeLinecap: 'round',
        strokeMiterlimit: '10',
        strokeWidth: '2px'
      }}
      data-name='Path 4 Copy'
      transform='translate(16 9)'
    />
  </svg>
);
export default Close;
