import SendBird from 'sendbird';
import { authenticationService } from './authentication.service';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
const CONSTANTS = require('./../_helpers/globals');

var sb = new SendBird({
  appId: CONSTANTS.SENDBIRD_API
});

var ConnectionHandler = new sb.ConnectionHandler();
var connectedUser = null;
// var connectionStatus = 'not_connected'
var connectionStatus = new BehaviorSubject('not_connected');

ConnectionHandler.onReconnectStarted = () => {
  connectionStatus.next('connecting');
};

ConnectionHandler.onReconnectSucceeded = () => {
  connectionStatus.next('connected');
};

ConnectionHandler.onReconnectFailed = () => {
  connectionStatus.next('not_connected');
};

function sendFileAsAMessage(channel, file) {
  return new Promise((resolve, reject) => {
    const params = new sb.FileMessageParams();
    params.file = file;
    params.fileName = file.name;
    params.fileSize = file.size;
    params.mimeType = file.type;
    params.thumbnailSizes = [
      { maxWidth: 100, maxHeight: 100 },
      { maxWidth: 200, maxHeight: 200 }
    ]; // Add the maximum sizes of thumbnail images (allowed number of thumbnail images: 3).
    channel.sendFileMessage(
      params,
      function (event) {
        // console.log(parseInt(Math.floor(event.loaded / event.total * 100)) + '%');
      },
      function (fileMessage, error) {
        if (error) {
          reject(error);
        } else {
          // sendMessage({ channel: channel, message: fileMessage.url }).then(resp => {
          //     console.log(resp)
          // }, err => {
          //     console.log(err)
          // })
          resolve(fileMessage);
        }
      }
    );
  });
}

function connectToSendBirdServer(loggeInUser) {
  if (!connectedUser) {
    sb.connect(authenticationService.getUserIDCookie(), function (user, error) {
      if (error) {
        return;
      }
      if (loggeInUser) {
        if (!(user.metaData.profileImage && user.metaData.firstName && user.metaData.lastName)) {
          user.createMetaData(
            {
              profileImage: loggeInUser?.user.profileImage ? loggeInUser?.user.profileImage : '',
              firstName: loggeInUser?.user.firstName,
              lastName: loggeInUser?.user.lastName
            },
            function (metadata, error) {
              connectedUser = user;
              connectionStatus.next('connected');
              if (error) {
                return;
              }
            }
          );
        }
        connectedUser = user;
        connectionStatus.next('connected');
        user.updateMetaData(
          {
            profileImage: loggeInUser?.user.profileImage ? loggeInUser?.user.profileImage : '',
            firstName: loggeInUser?.user.firstName,
            lastName: loggeInUser?.user.lastName
          },
          function (metadata, error) {
            connectedUser = user;
            connectionStatus.next('connected');
            if (error) {
              return;
            }
          }
        );
      }
    });
  }
}

function getMessagesChannel() {
  return new Promise((resolve, reject) => {
    var myChannelListQuery = sb.GroupChannel.createMyGroupChannelListQuery();
    myChannelListQuery.includeEmpty = true;
    myChannelListQuery.userIdsIncludeFilter = [authenticationService.getUserIDCookie()];
    myChannelListQuery.next(function (groupChannels, error) {
      if (error) {
        reject(error);
      }
      resolve(groupChannels);
    });
  });
}

function getConnectedUser() {
  return connectedUser;
}

function createOpenGroupChannel(channelParams) {
  return new Promise((resolve, reject) => {
    var params = new sb.GroupChannelParams();
    params.isPublic = channelParams.customType === 'Group' ? true : true;
    params.isEphemeral = false;
    params.isDistinct = channelParams.customType === 'Group' ? false : false;
    params.addUserIds(channelParams.users); //strings
    params.operatorIds = channelParams.creator; // creator id
    params.name = channelParams.name; //name of the group
    params.channelUrl = JSON.parse(channelParams.creator) + '_' + moment().format('x'); //custom url, the moment will save in millisecond format
    params.coverUrl = ''; // no default image of group
    params.data = ''; // sringify format of data of all group members joined
    params.customType = channelParams.customType;
    sb.GroupChannel.createChannel(params, function (groupChannel, error) {
      if (error) {
        console.log('eeri', error);
        reject(error);
      }
      resolve(groupChannel);
    });
  });
}

function createOpenChannel(params) {
  return new Promise((resolve, reject) => {
    sb.OpenChannel.createChannel(function (createdOpenChannel, error) {
      if (error) {
        reject(error);
      }
      resolve(createdOpenChannel);
    });
  });
}

function retrieveChannelPreviosMessages(Channel) {
  return new Promise((resolve, reject) => {
    var prevMessageListQuery = Channel.createPreviousMessageListQuery();
    prevMessageListQuery.limit = 30;
    // prevMessageListQuery.reverse = true;
    prevMessageListQuery.load(function (messageList, error) {
      if (error) {
        reject(error);
      }
      resolve(messageList);
    });
  });
}

function getGroupChannel(url) {
  return new Promise((resolve, reject) => {
    sb.GroupChannel.getChannel(url, function (groupChannel, error) {
      if (error) {
        reject(error);
      }
      resolve(groupChannel);
    });
  });
}

function getChannel(params) {
  if (params.type) {
    return new Promise((resolve, reject) => {
      sb.GroupChannel.getChannel(params.url, function (openChannel, error) {
        if (error) {
          console.log('sb.GroupChannel.getChannel', error);
          reject(error);
        }
        console.log('openChannel', openChannel);
        resolve(openChannel);
      });
    });
  } else {
    return new Promise((resolve, reject) => {
      sb.OpenChannel.getChannel(params.url, function (openChannel, error) {
        if (error) {
          reject(error);
        }
        resolve(openChannel);
      });
    });
  }
}

function enterChannel(Channel) {
  return new Promise((resolve, reject) => {
    Channel.enter(function (response, error) {
      if (error) {
        reject(error);
      }
      resolve(response);
    });
  });
}

function sendMessage(data) {
  return new Promise((resolve, reject) => {
    const params = new sb.UserMessageParams();
    params.message = data.message;
    try {
      data.channel.sendUserMessage(params, function (message, error) {
        if (error) {
          reject(error);
        }
        resolve(message);
      });
    } catch (e) {
      reject('error');
    }
  });
}

function retrieveListOfChannel() {
  return new Promise((resolve, reject) => {
    var channelListQuery = sb.GroupChannel.createMyGroupChannelListQuery();
    channelListQuery.includeEmpty = true;
    channelListQuery.order = 'chronological'; // 'chronological', 'latest_last_message', 'channel_name_alphabetical', and 'metadata_value_alphabetical'
    channelListQuery.limit = 100; // The value of pagination limit could be set up to 100.
    try {
      if (channelListQuery.hasNext) {
        channelListQuery.next(function (channelList, error) {
          if (error) {
            return;
          }
          resolve(channelList);
        });
      }
    } catch (e) {
      reject('error');
    }
  });
}
export const sendBirdService = {
  connectToSendBirdServer,
  getConnectedUser,
  createOpenChannel,
  retrieveChannelPreviosMessages,
  getChannel,
  enterChannel,
  sendMessage,
  sb,
  getMessagesChannel,
  connectionStatus,
  sendFileAsAMessage,
  createOpenGroupChannel,
  getGroupChannel,
  retrieveListOfChannel
};
