import Cookies from 'universal-cookie';

const cookies = new Cookies();
const axios = require('axios').default;
const DOMAIN = 'localhost';
const globals = require('./../_helpers/globals');
const URL = globals.domain;
const axiosInstance = axios.create({
  headers: {
    Authorization: 'Bearer ' + getCookie()
  }
});

function getCookie() {
  return localStorage.getItem('UID');
  // return cookies.get('UID')
}

function getFiles(params) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .get(`${URL}project/file/all`, { params: params });
}

function getTeam(params) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .get(`${URL}project/team/all`, { params: params });
}

function getProfesstional() {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .get(`${URL}users/professional`);
}

function newProject(data) {
  let headers = {
    Authorization: 'Bearer ' + getCookie()
  };
  return axios
    .create({
      headers: headers
    })
    .post(`${URL}project`, data);
}

function uploadFile(data) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .post(`${URL}project/file`, data);
}

function addTask(data) {
  return axios
    .create({
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .post(`${URL}project/task`, data);
}

function getTask(params) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .get(`${URL}project/task/all`, {
      params: params
    });
}

function getProfessionalTask(params) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .get(`${URL}project/task/professional`, {
      params: params
    });
}

function updateTasks(data) {
  return axios
    .create({
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .post(`${URL}project/task/status-update`, data);
}

function deleteProject(params) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .delete(`${URL}project`, {
      params: params
    });
}

function editProject(data) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .put(`${URL}project`, data);
}

function editTask(data) {
  return axios
    .create({
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .put(`${URL}project/task`, data);
}

function deleteTask(params) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .delete(`${URL}project/task`, { params: params });
}

function deleteFile(params) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .delete(`${URL}project/file`, { params: params });
}

function inviteProfessionals(data) {
  let headers = {
    Authorization: 'Bearer ' + getCookie()
  };
  return axios
    .create({
      headers: headers
    })
    .post(`${URL}users/professional`, data);
}

export const projectService = {
  getFiles,
  getTeam,
  newProject,
  uploadFile,
  addTask,
  getTask,
  getProfessionalTask,
  getProfesstional,
  updateTasks,
  editProject,
  deleteProject,
  editTask,
  deleteTask,
  deleteFile,
  inviteProfessionals
};
