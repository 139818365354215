import React, { useCallback, useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import * as moment from 'moment';
import { Button, Calendar } from '../../../common';
import AddIcon from '../../../public/icons/AddIcon';
import { projectService } from '../../../_services/project.service';
import { wrapComponent } from 'react-snackbar-alert';
import { UserContext } from '../../../common/context/UserContext';
import Avatar from 'react-avatar';
import TeamDropdown from '../../../common/TeamDropdown';
import { withTranslation } from 'react-i18next';
import 'moment/locale/ja';
import 'moment/locale/en-ca';

let ProjectAddTaskForm = styled.form`
  width: 70vh;
  padding: 30px 0 0 0;
`;
let HeaderModal = styled.p`
  font-family: Montserrat;
  font-size: 0.8em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.05px;
  text-align: left;
  color: #4b4d4e;
`;
let HeaderTitleModal = styled.p`
  font-family: Montserrat;
  font-size: 1.1em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.07px;
  text-align: left;
  color: #21a8df;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 20px;
`;
let RowBox = styled.div`
  padding: 10px 0;

  div.primaryContact {
    img {
      width: 30px;
      border-radius: 50%;
      height: 30px;
    }
    div {
      margin: auto 10px;
      font-family: Montserrat;
      font-size: 0.8em;
    }
  }
`;
let FormLabel = styled.div`
  font-family: Montserrat;
  font-size: 0.8em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.05px;
  text-align: left;
  color: #696974;
  margin: auto;
`;
let InputBox = styled.input`
  border: 1px solid #e5e5e5 !important;
  border-radius: 3px;
  font-family: Montserrat;
  font-size: 0.8em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #4b4d4e;
  border: 0;
  width: 100%;
  resize: none;
`;
let TextBoxBox = styled.textarea`
  border: 1px solid #e5e5e5 !important;
  border-radius: 3px;
  font-family: Montserrat;
  font-size: 0.8em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.7px;
  text-align: left;
  color: #4b4d4e;
  border: 0;
  height: 90px;
  width: 100%;
  resize: none;
  scrollbar-width: none;
`;
let SelectBox = styled.select`
  font-family: Montserrat;
  font-size: 0.8em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #4b4d4e;
  border: 0;
  width: 50%;
  float: left;
  padding: 10px;
`;
let AddTeamPanel = styled.div`
  display: flex;
  p.add-team-member {
    font-family: Montserrat;
    font-size: 12px;
    line-height: 1.43;
    letter-spacing: 0.05px;
    text-align: left;
    color: #21a8df;
    margin: 0 10px;
    :hover {
      cursor: hover;
    }
  }
`;
let Warning = styled.div`
  font-family: Montserrat;
  font-size: 0.8em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.05px;
  text-align: right;
  color: #f44336;
  align-self: center;
`;
const AddTaskModal = wrapComponent(
  ({ t, i18n, createSnackbar, projectDetails, addTaskSendForm, updateTask, updateTaskForm }) => {
    moment.locale('ja');
    const [{ user, dropdown }, dispatch] = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [addTaskForm, setAddTaskForm] = useState({
      taskTitle: updateTask ? updateTask.name : '',
      taskDetails: updateTask ? updateTask.details : '',
      taskStatus: updateTask ? updateTask.status : dropdown.TaskStatus[0].value,
      category: updateTask ? updateTask.category : dropdown.taskCategories[0].value,
      startDate: updateTask ? updateTask.createdAt : new Date().toISOString().substr(0, 10),
      endDate: updateTask ? updateTask.endDate : new Date().toISOString().substr(0, 10),
      assignee: updateTask ? updateTask.assignee : ''
    });
    const [teamMembers, setTeamMembers] = useState();
    useEffect(() => {
      if (projectDetails._id) {
        projectService.getTeam({ project_id: projectDetails._id }).then(
          (response) => {
            // console.log("response team", response)
            if (response.data.result) {
              setTeamMembers(response.data.result);
            }
          },
          (err) => {
            console.error('error project filers', err);
          }
        );
      }
    }, []);

    const showSnackbar = (msg) => {
      createSnackbar({
        theme: 'error',
        message: msg,
        timeout: 2000,
        dismissable: false,
        pauseOnHover: true,
        progressBar: false,
        sticky: false
      });
    };

    const [showTeamBox, setShowTeamBox] = useState(false);
    const submit = (e) => {
      e.preventDefault();
      if (updateTask) {
        //this is to update
        if (validateFormData()) {
          let data = {
            name: addTaskForm.taskTitle,
            task_id: updateTask._id,
            assignee: addTaskForm.assignee._id,
            category: addTaskForm.category,
            startDate: addTaskForm.startDate,
            endDate: addTaskForm.endDate,
            status: addTaskForm.taskStatus,
            details: addTaskForm.taskDetails
          };
          updateTaskForm(data);
          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
        } else {
          setIsLoading(false);
        }
      } else {
        //this is to add task
        if (validateFormData()) {
          let data = {
            name: addTaskForm.taskTitle,
            project: projectDetails._id,
            category: addTaskForm.category,
            status: addTaskForm.taskStatus,
            startDate: addTaskForm.startDate,
            endDate: addTaskForm.endDate,
            assignee: addTaskForm.assignee._id,
            details: addTaskForm.taskDetails
          };
          addTaskSendForm(data);
          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
        } else {
          setIsLoading(false);
        }
      }
    };

    const [showWarning, setWarning] = useState(0);

    const fieldChange = useCallback((event) => {
      let data = {};
      data[event.target.name] = event.target.value;
      if (event.target.name === 'endDate' && !addTaskForm.startDate) {
        setWarning(1);
        return false;
      }
      setAddTaskForm({ ...addTaskForm, ...data });
    });

    const validateFormData = () => {
      let msg;
      if (addTaskForm.taskTitle === '' || addTaskForm.taskTitle === null) {
        msg = t('project.actions.title_empty');
      } else if (addTaskForm.taskDetails === '' || addTaskForm.taskDetails === null) {
        msg = t('project.actions.detail_empty');
      } else if (addTaskForm.taskStatus === '' || addTaskForm.taskStatus === null) {
        msg = t('project.actions.status_empty');
      } else if (addTaskForm.category === '' || addTaskForm.category === null) {
        msg = t('project.actions.category_empty');
      } else if (addTaskForm.assignee === '' || addTaskForm.assignee === null) {
        msg = t('project.actions.assignee_empty');
      } else if (addTaskForm.startDate === '' || addTaskForm.startDate === null) {
        msg = t('project.actions.start_date_empty');
      } else if (addTaskForm.endDate === '' || addTaskForm.endDate === null) {
        msg = t('project.actions.end_date_empty');
      }

      if (msg) {
        showSnackbar(msg);
        return false;
      } else {
        return true;
      }
    };
    const selectedTeamMembers = (value) => {
      setShowTeamBox(false);
      setAddTaskForm({ ...addTaskForm, assignee: value.member });
    };
    const fieldChangeCalendar = (value, type) => {
      let data = {};
      data[type] = moment(value).format('YYYY-MM-DD');
      if (type === 'endDate' && moment(value).format('YYYY-MM-DD') < addTaskForm.startDate) {
        setWarning(1);
        showSnackbar('End date cannot be less than start date', 'error');
        return false;
      }
      if (
        type === 'endDate' &&
        moment(value).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
      ) {
        setWarning(1);
        showSnackbar('End date cannot be less than todays date', 'error');
        return false;
      }
      if (type === 'startDate' && moment(value).format('YYYY-MM-DD') > addTaskForm.endDate) {
        setWarning(1);
        showSnackbar('Start date cannot be more than end date', 'error');
        return false;
      }
      setWarning(0);
      setAddTaskForm({ ...addTaskForm, ...data });
    };
    const onlyProfessionals = (teamList) => {
      return teamList.filter((member) => member.role === 'professional');
    };
    return (
      <ProjectAddTaskForm name='form' onSubmit={submit}>
        {user.user.role === 'facilitator' || (user.user.role === 'client' && !updateTask) ? (
          <HeaderModal>
            {updateTask
              ? t('project.details.task.update_task')
              : t('project.details.task.add_task_header')}
          </HeaderModal>
        ) : (
          <HeaderModal>{t('project.details.task.details')}</HeaderModal>
        )}
        <HeaderTitleModal>
          {addTaskForm.taskTitle || t('project.details.task.title_placeholder')}
        </HeaderTitleModal>
        <HeaderModal>{projectDetails.name}</HeaderModal>
        <RowBox className='row'>
          <FormLabel className='col-md-3'>{t('project.details.task.title')}</FormLabel>
          <InputBox
            className='col-md-9'
            name='taskTitle'
            disabled={
              user.user.role === 'facilitator' || (user.user.role === 'client' && !updateTask)
                ? false
                : true
            }
            value={addTaskForm.taskTitle}
            onChange={(e) => fieldChange(e)}
            placeholder={t('project.details.task.title_placeholder')}
          />
        </RowBox>
        <RowBox className='row'>
          <FormLabel className='col-md-3 m-0'>{t('project.details.task.details')}</FormLabel>
          <TextBoxBox
            className='col-md-9'
            name='taskDetails'
            disabled={
              user.user.role === 'facilitator' || (user.user.role === 'client' && !updateTask)
                ? false
                : true
            }
            value={addTaskForm.taskDetails}
            onChange={(e) => fieldChange(e)}
            placeholder={t('project.details.task.details_placeholder')}
          />
        </RowBox>
        <RowBox className='row p-0'>
          <FormLabel className='col-md-3'>{t('project.details.task.status')}</FormLabel>
          <SelectBox
            className='col-md-9'
            name='taskStatus'
            disabled={
              user.user.role === 'facilitator' ||
              (user.user.role === 'client' && !updateTask) ||
              (user.user.role === 'professional' &&
                updateTask &&
                user.user._id == addTaskForm.assignee._id)
                ? false
                : true
            }
            value={addTaskForm.taskStatus}
            onChange={(e) => fieldChange(e)}
            placeholder={t('project.details.task.status_placeholder')}
          >
            {dropdown.TaskStatus.map(({ value, label }, index) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </SelectBox>
        </RowBox>
        <RowBox className='row pt-3 pb-3 pl-0 pr-0'>
          <FormLabel className='col-md-3'>{t('project.details.task.category')}</FormLabel>
          <SelectBox
            className='col-md-9'
            name='category'
            disabled={
              user.user.role === 'facilitator' || (user.user.role === 'client' && !updateTask)
                ? false
                : true
            }
            value={addTaskForm.category}
            onChange={(e) => fieldChange(e)}
            placeholder={t('project.details.task.category_placeholder')}
          >
            {dropdown.taskCategories.map(({ value, label }, index) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </SelectBox>
        </RowBox>
        <RowBox className='row'>
          <FormLabel className='col-md-3 m-0'>{t('project.details.task.start_date')}</FormLabel>
          <div
            className='col-md-3'
            style={{
              padding: '0',
              fontSize: '11px',
              fontFamily: 'Montserrat'
            }}
          >
            <Calendar
              name='startDate'
              disabled={
                user.user.role === 'facilitator' || (user.user.role === 'client' && !updateTask)
                  ? false
                  : true
              }
              value={moment(addTaskForm.startDate).format('MMM DD, YYYY')}
              onChange={(e) => fieldChangeCalendar(e, 'startDate')}
              dateFormat='MMM DD, YYYY'
              className='hover'
            />
          </div>
          <FormLabel className='col-md-3 m-0'>{t('project.details.task.end_date')}</FormLabel>
          <div
            className='col-md-3'
            style={{
              padding: '0',
              fontSize: '11px',
              fontFamily: 'Montserrat'
            }}
          >
            <Calendar
              name='endDate'
              disabled={
                user.user.role === 'facilitator' || (user.user.role === 'client' && !updateTask)
                  ? false
                  : true
              }
              startDate={addTaskForm.startDate ? addTaskForm.startDate : new Date()}
              value={moment(addTaskForm.endDate).format('MMM DD, YYYY')}
              onChange={(e) => fieldChangeCalendar(e, 'endDate')}
              dateFormat='MMM DD, YYYY'
              className='hover'
            />
          </div>
          {showWarning ? (
            <Warning className='col-md-12'>{t('project.edit.enter_start_date')}</Warning>
          ) : null}
        </RowBox>
        <RowBox className='row'>
          <FormLabel className='col-md-3 m-0 align-self-center'>
            {t('project.details.task.team')}
          </FormLabel>
          <div className='col-md-9'>
            <div>
              <AddTeamPanel>
                {addTaskForm.assignee.firstName && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      fontFamily: 'Montserrat',
                      fontSize: '12px'
                    }}
                  >
                    {addTaskForm.assignee.profileImage ? (
                      <img
                        src={addTaskForm.assignee.profileImage}
                        style={{
                          width: '30px',
                          borderRadius: '50%',
                          height: '30px'
                        }}
                      />
                    ) : (
                      <Avatar
                        size='30px'
                        round='50%'
                        name={addTaskForm.assignee.firstName + ' ' + addTaskForm.assignee.lastName}
                      />
                    )}
                    <div style={{ margin: 'auto 10px' }}>{addTaskForm.assignee.firstName}</div>
                  </div>
                )}

                {addTaskForm.assignee === '' ? (
                  <>
                    <AddIcon />
                    <p className='add-team-member' onClick={() => setShowTeamBox(!showTeamBox)}>
                      {t('project.details.task.add_team')}
                    </p>
                  </>
                ) : (
                  (user.user.role === 'facilitator' ||
                    (user.user.role === 'client' && !updateTask)) && (
                    <p
                      className='add-team-member align-self-center'
                      onClick={() => setShowTeamBox(!showTeamBox)}
                    >
                      {t('project.details.task.reassign')}
                    </p>
                  )
                )}
                {showTeamBox && (
                  <div
                    style={{
                      width: ' 40%',
                      position: 'absolute',
                      top: '25px'
                    }}
                  >
                    <TeamDropdown
                      close={() => setShowTeamBox(!showTeamBox)}
                      selectedTeam={(value) => selectedTeamMembers(value)}
                      multiple={false}
                      teamMembersOptions={onlyProfessionals(teamMembers)}
                    />
                  </div>
                )}
              </AddTeamPanel>
            </div>
          </div>
        </RowBox>
        {(user.user.role === 'facilitator' ||
          (user.user.role === 'client' && !updateTask) ||
          (user.user.role === 'professional' &&
            updateTask &&
            user.user._id == addTaskForm.assignee._id)) && (
          <div style={{ margin: '30px 0 20px 0' }}>
            {!isLoading && (
              <Button
                text={
                  updateTask
                    ? t('project.details.task.update_task_button')
                    : t('project.details.task.add_task_button')
                }
                onClick={(e) => {
                  setIsLoading(true);
                  submit(e);
                }}
              ></Button>
            )}
            {isLoading && (
              <div class='spinner-grow' role='status' style={{ margin: '10px 0' }}>
                <span class='sr-only'>Loading...</span>
              </div>
            )}
          </div>
        )}
      </ProjectAddTaskForm>
    );
  }
);

export default withTranslation('common')(AddTaskModal);
