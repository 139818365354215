import React from 'react';

const MessageIcon = () => (
  <svg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.1'
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34Z'
      fill='#82C43C'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M23.6304 24.7348C24.4146 24.5605 24.909 23.7835 24.7348 22.9993L24.1844 20.5228C24.718 19.4366 25 18.2379 25 17C25 12.5817 21.4183 9 17 9C12.5817 9 9 12.5817 9 17C9 21.4183 12.5817 25 17 25C18.2379 25 19.4366 24.718 20.5228 24.1844L22.9993 24.7348C23.2071 24.7809 23.4226 24.7809 23.6304 24.7348ZM23.5455 17C23.5455 18.0909 23.2789 19.1417 22.7763 20.0817C22.6943 20.235 22.6699 20.4126 22.7076 20.5824L23.3149 23.3149L20.5824 22.7076C20.4126 22.6699 20.235 22.6943 20.0817 22.7763C19.1417 23.2789 18.0909 23.5455 17 23.5455C13.385 23.5455 10.4545 20.615 10.4545 17C10.4545 13.385 13.385 10.4545 17 10.4545C20.615 10.4545 23.5455 13.385 23.5455 17ZM14.8182 18.4545C15.6215 18.4545 16.2727 17.8033 16.2727 17C16.2727 16.1967 15.6215 15.5455 14.8182 15.5455C14.0149 15.5455 13.3636 16.1967 13.3636 17C13.3636 17.8033 14.0149 18.4545 14.8182 18.4545ZM20.6364 17C20.6364 17.8033 19.9851 18.4545 19.1818 18.4545C18.3785 18.4545 17.7273 17.8033 17.7273 17C17.7273 16.1967 18.3785 15.5455 19.1818 15.5455C19.9851 15.5455 20.6364 16.1967 20.6364 17Z'
      fill='#82C43C'
    />
  </svg>
);

export default MessageIcon;
