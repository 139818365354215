import React from 'react';
//orange
const RedTimeLeft = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.96124 14.5C11.5374 14.5 14.4322 11.5876 14.4322 8C14.4322 4.41244 11.5374 1.5 7.96124 1.5C4.38512 1.5 1.49023 4.41244 1.49023 8C1.49023 11.5876 4.38512 14.5 7.96124 14.5Z'
      stroke='#FC5A5A'
    />
    <path
      d='M7.96135 3.85303V7.99996L5.9873 9.76885'
      stroke='#FC5A5A'
      strokeLinecap='round'
      stroke-linejoin='round'
    />
  </svg>
);

export default RedTimeLeft;
