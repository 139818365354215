import React, { useState, useEffect, useCallback, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { clientService, sendBirdService } from './../../../_services';
import { ProjectConfig } from '../../../public/configuration';
import { ProjectConfig as ProjectConfigJP } from '../../../public/configurationJP';
import styled from 'styled-components';
import { PageHeader, Modal, Button, Loader } from '../../../common';
import * as moment from 'moment';
import EditProject from './editProjectModal';
import AddProjectModal from './AddProjectModal';
import { projectService } from '../../../_services/project.service';
import { wrapComponent } from 'react-snackbar-alert';
import { UserContext } from '../../../common/context/UserContext';
import Search from '../../../public/Search';
import DeletePopUp from '../../../common/DeletePopUp';
import { withTranslation } from 'react-i18next';
import Pagination from '../../../common/Pagination';
import Table from '../../../common/Table';
import 'moment/locale/ja';
import 'moment/locale/en-ca';
let HeaderWrapper = styled.div`
  margin: 0;
  display: flex;
  justify-content: flex-end;
  padding: 50px 70px 0 25px;
`;
let BottomPanel = styled.div`
    position: relative;
    top: 20px;
    bottom: 0;
    display: flex;
    width: 100%;
    
    height: 30px;
    div.pagination-footer{
        position: relative;
    left: 30px;
    bottom: 10px;
    
    }
    div.button-footer{
        position: absolute;
        right: 110px;
        bottom: 30px;
    }

}
`;
let NoProject = styled.div`
  font-weight: 600;
  text-align: left;
  font-size: 15px;
  margin: 40px;
  font-family: Montserrat;
`;

let FilterSearch = styled.input`
  border-radius: 30px;
  border: solid 0.6px #21a8df;
  background-color: #ffffff;
  height: 27px;
  width: 60%;
  margin: 0 20px;
  font-family: Montserrat;
  font-size: 12px;
  padding: 0 30px 0 20px;
`;
const ProjectDashboard = wrapComponent(({ t, i18n, createSnackbar, ...props }) => {
  moment.locale('ja');
  const [noProjectError, setNoProjectError] = useState(false);
  const [{ user }, dispatch] = useContext(UserContext);
  const [totalPageNo, setTotalPageNo] = useState();
  const [showEdit, setShowEdit] = useState(false);
  const [showProjectCreate, setShowProjectCreate] = useState(false);
  const [projectData, setProjectData] = useState({});
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [deleteProject, setDeleteProject] = useState('');
  const type = 'project';
  const statuses = ['not_started', 'in_progress', 'completed'];
  const statusesValues = [
    t('projectStatus.not_started'),
    t('projectStatus.in_progress'),
    t('projectStatus.completed')
  ];
  const columns = [
    'name',
    'category',
    'percentComplete',
    'status',
    'alerts',
    'dueDate',
    'client',
    'team',
    'messageCount',
    'lastMessageDate',
    'action'
  ];
  const [filterTimeout, setFilterTimeout] = useState();

  const showSnackbar = (msg) => {
    createSnackbar({
      theme: 'info',
      message: msg,
      timeout: 2000,
      dismissable: false,
      pauseOnHover: true,
      progressBar: false,
      sticky: false
    });
  };

  const [projectConfig, setProjectConfig] = useState(ProjectConfigJP);
  const [LoaderStatus, setLoader] = useState(true);
  const [inquiriesToTable, setInquiriesToTable] = useState([]);
  const [filterField, setFilterField] = useState({ name: '', type: '', search: '' });

  const handleSort = useCallback((index) => {
    let newConfigSortChange = [...projectConfig];
    newConfigSortChange[index].config.sortType = !newConfigSortChange[index].config.sortType;
    setProjectConfig(newConfigSortChange);
    // setInquiriesToTable(
    //     getSortedData(inquiriesToTable, columns[index], newConfigSortChange[index].config.sortType)
    // );
    setFilterField({
      ...filterField,
      ...{ name: columns[index], type: newConfigSortChange[index].config.sortType ? 'asc' : 'desc' }
    });
    getProjectsData(1, 8, {
      name: columns[index],
      type: newConfigSortChange[index].config.sortType ? 'asc' : 'desc',
      search: filterField.search
    });
  });

  const closeDeleteModalHandler = useCallback(() => {
    setDeleteModalState(false);
  });

  const closeShowProjectModalHandler = useCallback(() => {
    setShowProjectCreate(false);
  });
  const ModelHeader = ({ text }) => (
    <p
      style={{
        fontFamily: 'Montserrat',
        fontSize: '12px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.36',
        letterSpacing: '0.75px',
        textAlign: 'center',
        color: '#282828',
        margin: 'auto'
      }}
    >
      {text}
    </p>
  );

  const deleteConfirm = async () => {
    handleDeleteProject({ project: { _id: deleteProject } });
  };

  const getAction = async (type, field) => {
    if (type === t('universal_actions.open_box')) {
      openDetails(field);
    } else if (type === t('universal_actions.edit_box')) {
      handleShowEdit(field);
    } else {
      setDeleteProject(field._id);
      setDeleteModalState(true);
    }
  };

  const openDetails = (project) => {
    props.history.push(`/home/project_details?project_id=${project._id}`);
  };

  const handleCloseEdit = () => setShowEdit(false);

  const handleShowEdit = (project) => {
    let data = {
      ...project
    };
    //console.log(data);
    setProjectData(data);
    setShowEdit(true);
  };

  const handleDeleteProject = (project) => {
    let data = {
      project_id: project.project._id
    };
    projectService.deleteProject(data).then(
      (response) => {
        showSnackbar('Project Deleted Successfully');
        getProjectsData(1, 8);
        closeDeleteModalHandler();
      },
      (error) => {
        console.error(error);
      }
    );
  };
  const redirectToProject = (project, type) => {
    return { value: project[type], link: '/home/project_details?project_id=' + project._id };
  };
  const lastMessageCount = async (allProject) => {
    let temp = [];
    let count = 0;
    await sendBirdService.retrieveListOfChannel().then((listOfChannels) => {
      allProject.map((project, index) => {
        let found;
        listOfChannels.map(async (channel) => {
          let id = project._id;
          let type = 'Project';
          // console.log("project id", id, "channel.url.split('_')[0]", channel.url.split('_')[0]);
          if (channel.url.split('_')[0] === id && channel.name.split('_')[0] === type && !found) {
            found = { count: 0, lastMessageTime: '', _id: project._id, channel: channel };
          }
        });
        if (found) {
          temp.push(found);
        } else {
          temp.push({ count: 0, lastMessageTime: '', _id: project._id, channel: null });
        }
        // console.log("validList", temp, listOfChannels);
      });
    });
    temp.map((channel, index) => {
      setTimeout(() => {
        if (channel.channel) {
          sendBirdService.retrieveChannelPreviosMessages(channel.channel).then(
            (prevMessages) => {
              count += 1;
              channel.count = prevMessages.length;
              channel.lastMessageTime = prevMessages[0] ? prevMessages[0].createdAt : null;
              channel._id = channel._id;
              if (count === allProject.length) {
                createTable(allProject, temp);
              }
            },
            (err) => {
              count += 1;
              if (count === allProject.length) {
                createTable(allProject, temp);
              }
            }
          );
        } else {
          count += 1;
          if (count === allProject.length) {
            createTable(allProject, temp);
          }
        }
      }, 50 * index);
    });
  };

  const createTable = (allProjects, messages) => {
    let dataToSend = [];
    allProjects.map((field, index) => {
      dataToSend.push({
        name: { type: 'string&link', value: redirectToProject(field, 'name'), fieldValue: field },
        category: {
          type: 'string&link',
          value: redirectToProject(field, 'category'),
          fieldValue: field
        },
        percentComplete: {
          type: 'string%progressbar',
          value: field.projectCompleted,
          fieldValue: field
        },
        status: {
          type: 'string',
          value: statusesValues[statuses.indexOf(field.status)],
          fieldValue: field
        },
        alerts: {
          type: 'day&string',
          value:
            field.status === 'completed'
              ? ''
              : moment(field.endDate).diff(moment(), 'days') + 1 > 0
              ? moment(field.endDate).diff(moment(), 'days') + 1 == '1'
                ? '1 ' + t('alerts.day_left')
                : moment(field.endDate).diff(moment(), 'days') + 1 + ' ' + t('alerts.days_left')
              : t('alerts.overdue'),
          fieldValue: field
        },
        dueDate: { type: 'date', value: field.endDate, fieldValue: field },
        client: {
          type: 'string',
          value: field.owner ? field.owner.firstName + ' ' + field.owner.lastName : '',
          fieldValue: field
        },
        team: { type: 'profileimage', value: field.team, fieldValue: field },
        messageCount: { type: 'message%count', value: messages, fieldValue: field },
        lastMessageDate: { type: 'message%date', value: messages, fieldValue: field },
        action: { type: 'edit', options: getEditOptions(field.tasks), fieldValue: field }
      });
    });
    setInquiriesToTable(dataToSend);
    setLoader(true);
  };
  const filterList = (event) => {
    clearTimeout(filterTimeout);
    setFilterTimeout(
      setTimeout(() => {
        getProjectsData(1, 8, filterField);
      }, 2000)
    );
  };
  const getProjectsData = (page, limit, filter) => {
    setLoader(false);
    let temp = { flag: true, text: '' };
    let data = {};
    if (limit === 0) {
      data = { page: page };
    } else {
      data = { page: page, limit: limit };
    }
    if (filter && filter.name && filter.type) {
      data['orderBy'] = filterField.name;
      data['order'] = filterField.type;
    }
    if (filter && filter.search) data['q'] = filterField.search;
    clientService.getProjects(data).then(
      (projectsData) => {
        if (projectsData.data.result.length === 0) {
          setNoProjectError(true);
          setLoader(true);
          setInquiriesToTable([]);
        } else {
          setTotalPageNo(Math.ceil(projectsData.data.totalCount / 8));
          lastMessageCount(projectsData.data.result);
          setNoProjectError(false);
        }
      },
      (err) => {
        console.error(err);
        setLoader(true);
      }
    );
  };

  useEffect(() => {
    getProjectsData(1, 8);
  }, []);

  const handleEditSubmit = async (data) => {
    data.project_id = data._id;
    let team = [];
    if (data.team.length) {
      data.team.map((t) => {
        team.push(t.member._id);
      });
    }
    data.team = team;
    try {
      await projectService.editProject(data);
      await setShowEdit(false);
      await showSnackbar(t('project.actions.project_edit'));
      await getProjectsData(1, 8);
      await setProjectData(data);
    } catch (err) {
      console.error(err);
    }
  };
  const pageNoClicked = (pageNo) => {
    // if (filterField.name) {
    getProjectsData(pageNo, pageNo === -1 ? -1 : 8, filterField);
    // } else {
    // getProjectsData(pageNo, pageNo === -1 ? -1 : 8)
    // }
  };
  const getEditOptions = (tasks) => {
    if (user.user.role === 'facilitator') {
      if (tasks.length > 0) {
        return [t('universal_actions.open_box'), t('universal_actions.edit_box')];
      }
      return [
        t('universal_actions.open_box'),
        t('universal_actions.edit_box'),
        t('universal_actions.delete_box')
      ];
    }
    return [t('universal_actions.open_box')];
  };

  const openRespectiveProject = (link) => {
    props.history.push(link);
  };
  return (
    <Container fluid='true'>
      <HeaderWrapper className='row'>
        <div
          style={{
            display: 'flex',
            flexGrow: '1'
          }}
        >
          <PageHeader text={t('project.header')} />
        </div>
        <div className='d-flex'>
          <div className='position-relative'>
            <FilterSearch
              type='text'
              placeholder={t('universal_actions.filter')}
              onKeyUp={filterList}
              onChange={(e) => {
                setNoProjectError(false);
                setFilterField({ ...filterField, ...{ search: e.target.value } });
              }}
            />
            <p
              style={{
                position: 'absolute',
                top: '5px',
                right: '60px'
              }}
            >
              <Search />
            </p>
          </div>
        </div>
        {user.user.role === 'facilitator' && (
          <div>
            <Button
              text={t('project.add_project')}
              disabled={false}
              fullWidth='120%'
              font='10px'
              onClick={() => setShowProjectCreate(true)}
            />
            {showProjectCreate && (
              <Modal show={deleteModalState} close={closeShowProjectModalHandler}>
                <AddProjectModal
                  addedProject={() => {
                    getProjectsData(1, 8);
                    closeShowProjectModalHandler();
                  }}
                />
              </Modal>
            )}
          </div>
        )}
      </HeaderWrapper>
      <Row>
        <Container className='pl-0' fluid='true' style={{ overflow: 'scroll' }}>
          {inquiriesToTable.length > 0 ? (
            <>
              <div
                style={{
                  overflow: 'scroll',
                  height: '70vh',
                  width: '120vw'
                }}
              >
                {LoaderStatus ? (
                  <Table
                    config={projectConfig}
                    data={inquiriesToTable}
                    sort={handleSort}
                    action={getAction}
                    openRespectiveProject={openRespectiveProject}
                    from='project'
                  ></Table>
                ) : (
                  <Loader />
                )}
              </div>
              <BottomPanel>
                <div className='pagination-footer'>
                  <Pagination
                    pageNoClicked={pageNoClicked}
                    totalPageNo={totalPageNo}
                    type={'Projects'}
                  />
                </div>
              </BottomPanel>
            </>
          ) : LoaderStatus ? (
            <></>
          ) : (
            <Loader />
          )}
          {noProjectError && <NoProject>{t('project.no_project')}</NoProject>}
          {showEdit && (
            <Modal show={showEdit} close={handleCloseEdit}>
              <EditProject project={projectData} form={handleEditSubmit} />
            </Modal>
          )}
          {deleteModalState && (
            <Modal show={deleteModalState} close={closeDeleteModalHandler}>
              <DeletePopUp delete={deleteConfirm} type={type} />
            </Modal>
          )}
        </Container>
      </Row>
    </Container>
  );
});

export default withTranslation('common')(ProjectDashboard);
