import React, { useState, useEffect, useContext } from 'react';
import {
  Dropdown,
  TextArea,
  Checkbox,
  Input,
  Button,
  PageHeader,
  Table,
  Loader
} from '../../../common';
import { clientService } from './../../../_services';
import { Link } from 'react-router-dom';
import { FileList, Message } from '../../../common';
import styled from 'styled-components';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Download from './../../../public/icons/Download';
import { InquiryDetailFilesConfig } from '../../../public/configuration';
import InquiryFiles from './InquiryFiles';
import { UserContext } from '../../../common/context/UserContext';
import { inquiryService } from '../../../_services/inquiry.service';
import NumberFormat from 'react-number-format';
import * as moment from 'moment';
import Upload from '../../../public/icons/Upload';
import { withTranslation } from 'react-i18next';
import BackToOption from '../../../common/BackToOption';
import { wrapComponent } from 'react-snackbar-alert';
import 'moment/locale/ja';
import 'moment/locale/en-ca';

let LabelField = styled.label`
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #4b4d4e;
  margin: 0;
  @media (min-width: 1600px) {
    font-size: 12px;
  }
`;
let ConvertToInquiry = styled.div`
  position: absolute;
  right: 8%;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.05px;
  text-align: left;
  color: #21a8df;
  p:hover {
    cursor: pointer;
  }
  @media (min-width: 1600px) {
    font-size: 13px;
  }
`;
let UploadConfirm = styled.p`
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  font-family: Montserrat;
  color: black;
  span {
    color: #21a8df;
    text-decoration: underline;
  }
`;
const InquiryDetail = wrapComponent(({ t, i18n, createSnackbar, ...props }) => {
  moment.locale('ja');
  const [{ user }, dispatch] = useContext(UserContext);
  const [fileAttached, setFileAttached] = useState();
  const [inquiry, setInquiry] = useState({
    category: '',
    subCategory: '',
    budget: '',
    currency: '',
    inquiryStatus: '',
    background: '',
    details: '',
    estimatedStart: '',
    latestProposalUploadDate: ''
  });
  useEffect(() => {
    inquiryDetails();
  }, []);

  const inquiryDetails = () => {
    let data = {};
    let params = new URLSearchParams(window.location.search);
    data.inquiry_id = params.get('inquiry_id');
    clientService.getInquiry(data).then(
      (response) => {
        if (!response.data.result) {
          props.history.push('/home/inquiries');
          return;
        }
        let temp = {};
        temp = response.data.result;
        if (!temp.estimatedStart) {
          temp.estimatedStart = '';
        }
        setInquiry(temp);
        setcurreny(temp.currency);
      },
      (err) => {
        console.error(err);
        props.history.push('/home/inquiries');
      }
    );
  };

  const uploadProposal = () => {
    document.getElementById('file-upload-proposal').click();
  };

  const downloadProposal = () => {
    // console.log('clicked');
    document.getElementById('file-download-proposal').click();
  };

  const setUploadFIle = (event) => {
    if (event.target.files[0]) {
      setFileAttached(event.target.files[0]);
      showSnackbar(t('inquiry.actions.file_submit'), 'success');
    }
  };
  const uploadProposalChanged = (event) => {
    if (fileAttached) {
      inquiryStatusChange('created');
      let data = new FormData();
      data.append('document', fileAttached);
      let params = {};
      params.inquiry_id = inquiry._id;
      clientService.uploadProposal(data, params).then(
        (response) => {
          // console.log(response);
          let temp = { ...inquiry };
          temp.ProposalUrl = response.data.result.url;
          setInquiry(temp);
          showSnackbar(t('inquiry.actions.file_success'), 'success');
          inquiryDetails();
          setFileAttached();
        },
        (err) => {
          // console.log(err.response.data);
          showSnackbar(t('inquiry.actions.file_error'), 'error');
        }
      );
    } else {
      event.target.value = null;
    }
  };

  const inquiryStatusChange = (status, event) => {
    if (event) event.preventDefault();
    let data = {
      status: status,
      inquiry_id: inquiry._id
    };
    clientService.inquiryStatusUpdate(data).then(
      (response) => {
        let temp = { ...response.data.result };
        setInquiry(temp);
        if (response.data.result.documentStatus === 'inquiry_approved') {
          updateInquiryStatus('signed');
        } else if (response.data.result.documentStatus === 'inquiry_declined') {
          updateInquiryStatus('proposed');
        }
      },
      (err) => {
        console.error(err);
      }
    );
  };

  const updateInquiryStatus = async (type) => {
    let data = {
      name: inquiry.name,
      background: inquiry.background,
      inquiryStatus: type,
      inquiry_id: inquiry._id
    };
    await inquiryService.editInquiry(data).then(
      (res) => {
        inquiryDetails();
      },
      (err) => {
        console.error('error while updating inquiry status');
      }
    );
  };

  const [currenySign, setcurrenySign] = useState('');

  const setcurreny = (currency) => {
    if (currency === 'USD') {
      setcurrenySign('$');
    } else if (currency === 'EUR') {
      setcurrenySign('€');
    } else {
      setcurrenySign('¥');
    }
  };

  // const getDateOfProposal = (inquiryFilePath) => {
  //   try {
  //     let temp = inquiryFilePath.split('/')
  //     temp = temp[temp.length - 1]
  //     temp = temp.split('-')
  //     temp = temp[1] + '-' + temp[2] + '-' + temp[3] + '-' + temp[4] + '-' + temp[5]
  //     temp = temp.split('T')[0]
  //     console.log("temp", temp)
  //     return moment(temp).format("DD-MMM-YYYY")
  //   } catch (e) {
  //     console.log(e)
  //   }
  //   return ''
  // }
  const showSnackbar = (msg, theme) => {
    createSnackbar({
      theme,
      message: msg,
      timeout: 2000,
      dismissable: false,
      pauseOnHover: true,
      progressBar: false,
      sticky: false
    });
  };
  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    switch (s) {
      case 'draft':
        return t('inquiryStatus.draft');
      case 'submitted':
        return t('inquiryStatus.submitted');
      case 'signed':
        return t('inquiryStatus.signed');
      case 'suspended':
        return t('inquiryStatus.suspended');
      case 'completed':
        return t('inquiryStatus.completed');
      case 'proposed':
        return t('inquiryStatus.proposed');
      default:
        return '';
    }
  };
  return (
    <>
      <div
        className='row m-0 h-100'
        style={{
          width: '84vw',
          overflowX: 'hidden',
          overflowY: 'scroll'
        }}
      >
        {inquiry.name ? (
          <div style={{ padding: '20px' }}>
            <BackToOption link='/home/inquiries' text={t('inquiry.details.my_inquiry')} />
            <div className='row m-0'>
              <PageHeader text={inquiry.name} />
            </div>
            <div className='row'>
              <form className='col-md-6'>
                <div className='row m-0'>
                  <div className='col-md-6' style={{ paddingLeft: '0' }}>
                    <Dropdown
                      text={t('inquiry.details.category')}
                      type='category'
                      name='category'
                      value={inquiry.category}
                      options={[{ value: inquiry.category, label: inquiry.category }]}
                      disabled
                      readOnly={true}
                      width='100%'
                      margin='10px 0'
                      border='solid 0.5px #979797'
                      backgroundColor='#ffffff'
                      color='#000'
                    />
                  </div>
                  <div className='col-md-6' style={{ paddingRight: '0' }}>
                    <Dropdown
                      text={t('inquiry.details.sub_category')}
                      type='subCategory'
                      name='subCategory'
                      value={inquiry.subCategory}
                      options={[{ value: inquiry.subCategory, label: inquiry.subCategory }]}
                      disabled
                      readOnly={true}
                      width='100%'
                      margin='10px 0'
                      border='solid 0.5px #979797'
                      backgroundColor='#ffffff'
                      color='#000'
                    />
                  </div>
                </div>
                <div className='row m-0'>
                  <div className='col-md-4 p-0'>
                    <div className='row flex-column' style={{ margin: '10px 0' }}>
                      <LabelField>{t('inquiry.details.budget')}</LabelField>
                      <div className='row m-0'>
                        <div className='col-md-6 p-0'>
                          <NumberFormat
                            className='currency-inqiry-detail-font'
                            thousandSeparator={true}
                            placeholder='Enter Amount'
                            allowNegative={false}
                            readOnly={true}
                            prefix={currenySign}
                            value={inquiry.budget}
                          />
                        </div>
                        <div className='offset-md-1 col-md-5 p-0'>
                          <Dropdown
                            type='currency'
                            name='currency'
                            value={inquiry.currency}
                            readOnly={true}
                            options={[{ value: inquiry.currency, label: inquiry.currency }]}
                            disabled
                            width='100%'
                            border='solid 0.5px #979797'
                            backgroundColor='#ffffff'
                            color='#000'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <Dropdown
                      text={t('inquiry.details.status')}
                      type='inquiryStatus'
                      name='inquiryStatus'
                      value={inquiry.inquiryStatus}
                      className='text-capitalize'
                      options={[
                        {
                          value: inquiry.inquiryStatus,
                          label: capitalize(inquiry.inquiryStatus)
                        }
                      ]}
                      disabled
                      readOnly={true}
                      width='100%'
                      margin='10px 0'
                      border='solid 0.5px #979797'
                      backgroundColor='#ffffff'
                      color='#000'
                    />
                  </div>
                  <div className='col-md-5 p-0'>
                    <Dropdown
                      text={t('inquiry.details.expected_text')}
                      type='estimatedStart'
                      name='estimatedStart'
                      value={inquiry.estimatedStart}
                      options={[
                        {
                          value: inquiry.estimatedStart,
                          label: inquiry.estimatedStart
                        }
                      ]}
                      disabled
                      readOnly={true}
                      width='100%'
                      margin='10px 0'
                      border='solid 0.5px #979797'
                      backgroundColor='#ffffff'
                      color='#000'
                    />
                  </div>
                  <TextArea
                    text={t('inquiry.details.background')}
                    cols={50}
                    rows={6}
                    name='background'
                    value={inquiry.background}
                    width='100%'
                  />
                  <TextArea
                    text={t('inquiry.details.details')}
                    cols={50}
                    rows={6}
                    name='details'
                    value={inquiry.details}
                    width='100%'
                  />
                </div>
                {/* Uploading proposal is only for facilitaor */}
                <div className='row'>
                  {user.user.role === 'facilitator' && inquiry.inquiryStatus !== 'signed' && (
                    <>
                      <div className='col-md-6'>
                        <LabelField
                          className='row flex-column'
                          style={{ margin: '5px 0', paddingTop: '53px' }}
                        >
                          {t('inquiry.details.project_proposal')}
                        </LabelField>

                        <ConvertToInquiry
                          onClick={(e) => uploadProposal()}
                          className='row'
                          style={{ margin: '5px 0', right: '0', position: 'relative' }}
                        >
                          <Upload />
                          <p style={{ paddingLeft: '10px', paddingTop: '3px' }}>
                            {t('inquiry.details.upload_proposal')}
                          </p>
                          <input
                            type='file'
                            hidden
                            id='file-upload-proposal'
                            onChange={setUploadFIle}
                            accept='.doc,.docx,.png,.jpg,.jpeg,.pdf,.xls,.csv,.xlsx'
                          />
                        </ConvertToInquiry>
                        {fileAttached && (
                          <UploadConfirm onClick={() => uploadProposalChanged()}>
                            <span>Upload </span> ( {fileAttached.name} )
                          </UploadConfirm>
                        )}
                      </div>
                    </>
                  )}
                  {/* once the proposal is uploaded can be viewed here */}
                  {inquiry.ProposalUrl && (
                    <>
                      <div className='col-md-6'>
                        <LabelField
                          className='row flex-column'
                          style={{ margin: '5px 0', paddingTop: '53px' }}
                        >
                          {t('inquiry.details.project_proposal')}
                        </LabelField>
                        <ConvertToInquiry
                          onClick={downloadProposal}
                          className='row'
                          style={{ margin: '5px 0', right: '0', position: 'relative' }}
                        >
                          <Download />
                          <p style={{ paddingLeft: '10px', paddingTop: '3px' }}>
                            {t('inquiry.details.download_proposal')}
                          </p>
                          <a
                            id='file-download-proposal'
                            target='_blank'
                            href={inquiry.ProposalUrl}
                          />
                          <p
                            style={{
                              position: 'absolute',
                              top: '30px'
                            }}
                          >
                            {moment(inquiry.latestProposalUploadDate).format('DD-MMM-YYYY')}
                          </p>
                        </ConvertToInquiry>
                      </div>
                    </>
                  )}
                </div>
              </form>
              <div
                className='col-md-6'
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                {inquiry.files && <InquiryFiles inquiry={inquiry} />}
              </div>
            </div>

            {user.user.role === 'client' &&
              inquiry.inquiryStatus != 'draft' &&
              inquiry.inquiryStatus != 'submitted' &&
              inquiry.inquiryStatus != 'suspended' && (
                <div className='row' style={{ margin: '50px 30px' }}>
                  <div>
                    {inquiry.documentStatus === 'inquiry_approved' && (
                      <PageHeader text={t('inquiry.details.approved')} />
                    )}
                    {(inquiry.documentStatus === 'created' ||
                      inquiry.documentStatus === 'inquiry_declined') && (
                      <Button
                        text={t('inquiry.details.approve')}
                        fullWidth='140%'
                        onClick={(e) => inquiryStatusChange('inquiry_approved', e)}
                      />
                    )}
                  </div>
                  <div style={{ marginLeft: '50px' }}>
                    {inquiry.documentStatus === 'inquiry_declined' && (
                      <PageHeader text={t('inquiry.details.declined')} />
                    )}
                    {inquiry.documentStatus === 'created' && (
                      <Button
                        text={t('inquiry.details.decline')}
                        fullWidth='140%'
                        switch={true}
                        onClick={(e) => inquiryStatusChange('inquiry_declined', e)}
                      />
                    )}
                  </div>
                  <div style={{ marginLeft: '50px' }}>
                    {inquiry.inquiryStatus != 'suspended' && inquiry.inquiryStatus != 'signed' && (
                      <Button
                        text={t('inquiry.details.suspend')}
                        fullWidth='140%'
                        onClick={(e) => updateInquiryStatus('suspended')}
                      />
                    )}
                  </div>

                  {/* {inquiry.documentStatus === 'inquiry_approved' &&
                    <ConvertToInquiry onClick={e => inquiryStatusChange("project_requested", e)}>
                      <p>Convert Inquiry to Contract</p>
                    </ConvertToInquiry>
                  }
                  {inquiry.documentStatus === 'project_requested' &&
                    <PageHeader style={{ position: 'absolute', right: '8%' }} text="PROJECT REQUESTED" />
                  }
                  {inquiry.documentStatus === 'project_approved' &&
                    <Button
                      text="ORDER"
                      fullWidth="140%"
                      switch={true}
                    />
                  } */}
                </div>
              )}

            {user.user.role === 'client' && inquiry.inquiryStatus === 'suspended' && (
              <div className='row' style={{ margin: '50px 30px' }}>
                <div>
                  <Button
                    text={t('inquiry.details.re_use')}
                    fullWidth='140%'
                    onClick={(e) => updateInquiryStatus('draft')}
                  />
                </div>
              </div>
            )}
            {user.user.role === 'client' && inquiry.inquiryStatus === 'submitted' && (
              <div className='row' style={{ margin: '50px 30px' }}>
                <div>
                  <Button
                    text={t('inquiry.details.suspend')}
                    fullWidth='140%'
                    onClick={(e) => updateInquiryStatus('suspended')}
                  />
                </div>
              </div>
            )}

            {user.user.role === 'client' && inquiry.inquiryStatus === 'draft' && (
              <div className='row' style={{ margin: '50px 30px' }}>
                <div>
                  <Button
                    text={t('universal_actions.submit')}
                    fullWidth='140%'
                    onClick={(e) => {
                      updateInquiryStatus('submitted');
                    }}
                  />
                </div>
              </div>
            )}
            {user.user.role === 'facilitator' && inquiry.documentStatus === 'inquiry_declined' && (
              <div className='row m-0'>
                <PageHeader text={t('inquiry.details.declined')} />
              </div>
            )}
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
});

export default withTranslation('common')(InquiryDetail);
