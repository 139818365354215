import React from 'react';

const Find = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.8375 6.41759C11.8375 9.41068 9.41247 11.8375 6.41875 11.8375C3.42503 11.8375 1 9.41068 1 6.41759C1 3.42682 3.42503 1 6.41875 1C9.41247 1 11.8375 3.42682 11.8375 6.41759Z'
      stroke='#4B4D4E'
    />
    <path d='M10.2119 10.2119L15.2091 15.2091' stroke='#4B4D4E' />
  </svg>
);

export default Find;
