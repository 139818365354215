import React from 'react';

const StatusIcon = () => (
  <svg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.1'
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34Z'
      fill='#0073FF'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M12.2 17H13C14.7673 17 16.2 18.4327 16.2 20.2V21.8C16.2 23.5673 14.7673 25 13 25H12.2C10.4327 25 9 23.5673 9 21.8V20.2C9 18.4327 10.4327 17 12.2 17ZM12.2 18.6C11.3163 18.6 10.6 19.3163 10.6 20.2V21.8C10.6 22.6837 11.3163 23.4 12.2 23.4H13C13.8837 23.4 14.6 22.6837 14.6 21.8V20.2C14.6 19.3163 13.8837 18.6 13 18.6H12.2ZM11.8 9C13.3464 9 14.6 10.2536 14.6 11.8V12.6C14.6 14.1464 13.3464 15.4 11.8 15.4C10.2536 15.4 9 14.1464 9 12.6V11.8C9 10.2536 10.2536 9 11.8 9ZM11.8 10.6C11.1373 10.6 10.6 11.1373 10.6 11.8V12.6C10.6 13.2627 11.1373 13.8 11.8 13.8C12.4627 13.8 13 13.2627 13 12.6V11.8C13 11.1373 12.4627 10.6 11.8 10.6ZM21 16.2H21.8C23.5673 16.2 25 17.6327 25 19.4V21.8C25 23.5673 23.5673 25 21.8 25H21C19.2327 25 17.8 23.5673 17.8 21.8V19.4C17.8 17.6327 19.2327 16.2 21 16.2ZM21 17.8C20.1163 17.8 19.4 18.5163 19.4 19.4V21.8C19.4 22.6837 20.1163 23.4 21 23.4H21.8C22.6837 23.4 23.4 22.6837 23.4 21.8V19.4C23.4 18.5163 22.6837 17.8 21.8 17.8H21ZM19 9H22.2C23.7464 9 25 10.2536 25 11.8C25 13.3464 23.7464 14.6 22.2 14.6H19C17.4536 14.6 16.2 13.3464 16.2 11.8C16.2 10.2536 17.4536 9 19 9ZM19 10.6C18.3373 10.6 17.8 11.1373 17.8 11.8C17.8 12.4627 18.3373 13 19 13H22.2C22.8627 13 23.4 12.4627 23.4 11.8C23.4 11.1373 22.8627 10.6 22.2 10.6H19Z'
      fill='#0062FF'
    />
  </svg>
);

export default StatusIcon;
