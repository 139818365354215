import React from 'react';
import styled from 'styled-components';

let ProgressBar = styled.div`
  display: flex;
  div.show-progress-bar {
    height: 4px;
    margin: 0;
    overflow: hidden;
    background-color: #e2e6ea;
    border-radius: 2px;
    width: 90%;
    align-self: center;
    div.show-progress {
      float: left;
      width: ${(props) => (props.width ? props.width + '%' : '0')};
      height: 100%;
      font-size: 12px;
      line-height: 20px;
      color: #fff;
      text-align: center;
      -webkit-transition: width 0.6s ease;
      -o-transition: width 0.6s ease;
      transition: width 0.6s ease;
      border-radius: 1.3px;
      background-color: #21a8df;
      @media (min-width: 1600px) {
        font-size: 13px;
      }
    }
  }
  p.percent-text {
    font-family: Montserrat;
    font-size: 11px;
    line-height: 1.71;
    letter-spacing: 0.05px;
    text-align: left;
    color: #696974;
    margin: 0 10px;
    @media (min-width: 1600px) {
      font-size: 12px;
    }
  }
`;
export default function ProjectBar(props) {
  return (
    <ProgressBar width={props.width}>
      <div className='show-progress-bar'>
        <div className='show-progress'></div>
      </div>
      <p className='percent-text'>{props.width}%</p>
    </ProgressBar>
  );
}
export { ProjectBar };
