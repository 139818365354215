import React, { useState, useCallback, useEffect, useContext } from 'react';
import { authenticationService } from '../_services';
import { sendBirdService } from '../_services';
import { Link } from 'react-router-dom';
import { Input, Button, CardLanding } from '../common';
import { LoginRegisterHeader } from '../common/LoginRegisterHeader';
import styled from 'styled-components';
import { UserContext } from '../common/context/UserContext';
import { withTranslation } from 'react-i18next';
import { wrapComponent } from 'react-snackbar-alert';

let ForgetPassword = styled.p`
  font-family: Montserrat;
  font-size: 11px;
  line-height: 2.2;
  letter-spacing: normal;
  text-align: right;
  color: rgba(75, 77, 78, 0.35);

  strong {
    color: rgba(75, 77, 78, 0.35);
  }
  @media (min-width: 1600px) {
    font-size: 12px;
  }
`;
let LoginForm = styled.form`
  margin: 10% 0;
`;
let SignUp = styled.div`
  margin-top: 20%;

  div {
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    margin-top: 10%;
  }
`;
let ResendLink = styled.p`
  font-size: 11px;
  font-family: Montserrat;
  color: #21a8df;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
`;
const LoginPage = wrapComponent(({ createSnackbar, history, t }) => {
  const [{ user }, dispatch] = useContext(UserContext);
  const [resendLink, setResendLink] = useState(false);
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [errorFlag, setErrorFlag] = useState({
    email: '',
    password: ''
  });

  useEffect(() => {
    authenticationService.isLoggedIn().then((res) => {
      if (res.data.status) {
        history.push('./home');
      } else {
        return null;
      }
    });
  }, []);

  const sendVerificationLink = () => {
    authenticationService.getVerificationLink({ email: credentials.email }).then((success) => {
      setResendLink(false);
      if (success.data.result) {
        showSnackbar(t('login.verification_success'), 'success');
      }
    });
  };

  const submit = useCallback(async (event) => {
    event.preventDefault();
    let data = {
      email: credentials.email,
      password: credentials.password
    };
    await authenticationService
      .login(data)
      .then((response) => {
        authenticationService
          .getDropDown()
          .then((dropdownResponse) => {
            dispatch({
              type: 'SET_USER_DETAILS',
              payload: response.data,
              dropdown: dropdownResponse.data
            });
            authenticationService.setCookie('UID', response.data);
            sendBirdService.connectToSendBirdServer();
            if (response.data.user.role != 'professional') {
              history.push('/home');
            } else {
              history.push('/home/projects');
            }
          })
          .catch((error) => {
            if (error && error.response && error.response.data) {
              error = error.response.data.error;
            }
            setErrorFlag({ password: error });
          });
      })
      .catch((error) => {
        if (error && error.response && error.response.data) {
          error = error.response.data.error;
        }
        if (error.toLowerCase() === 'email_not_verified') {
          setResendLink(true);
        }
        error = t('login.' + error);

        setErrorFlag({ password: error });
      });
  });

  const fieldChange = useCallback((event) => {
    setResendLink(false);
    let data = {};
    data[event.target.name] = event.target.value;
    setCredentials({ ...credentials, ...data });
    setErrorFlag({ password: '', email: '' });
  });
  const showSnackbar = (msg, theme) => {
    createSnackbar({
      theme,
      message: msg,
      timeout: 2000,
      dismissable: false,
      pauseOnHover: true,
      progressBar: false,
      sticky: false
    });
  };
  return (
    <CardLanding>
      <LoginRegisterHeader text={t('login.header')} />
      <LoginForm name='form' onSubmit={submit}>
        <Input
          text={t('login.email')}
          error={errorFlag.email}
          type='email'
          name='email'
          value={credentials.email}
          onChange={fieldChange}
          placeholder='Enter Email'
        />
        <Input
          text={t('login.password')}
          error={errorFlag.password}
          type='password'
          name='password'
          value={credentials.password}
          onChange={fieldChange}
          placeholder='Enter Password'
        />
        {resendLink && (
          <ResendLink onClick={() => sendVerificationLink()}>
            {t('login.verification_resend')}
          </ResendLink>
        )}
        <ForgetPassword>
          <Link to='/forgot-password'>
            <strong>{t('login.forgot_password_text')}</strong>
          </Link>
        </ForgetPassword>
        <SignUp>
          <Button
            text={t('login.singin_button')}
            disabled={!(credentials.email && credentials.password)}
          />
          <div className='form-group'>
            {t('login.no_account_text')}
            <Link to='/register'>
              <strong> {t('login.signup')}</strong>
            </Link>
          </div>
        </SignUp>
      </LoginForm>
    </CardLanding>
  );
});

export default withTranslation('common')(LoginPage);
