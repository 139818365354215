import React, { useState, useCallback, useEffect } from 'react';
import { authenticationService } from '../_services';
import { Link, useParams } from 'react-router-dom';
import { Input, Button, CardLanding } from '../common';
import { LoginRegisterHeader } from '../common/LoginRegisterHeader';
import styled from 'styled-components';
import { wrapComponent } from 'react-snackbar-alert';
import PasswordStrength from '../common/PasswordStrength';
import { withTranslation } from 'react-i18next';

let LoginForm = styled.form`
  margin: 10% 0;
`;
let SignUp = styled.div`
  margin-top: 10%;

  div {
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    margin-top: 10%;
    @media (min-width: 1600px) {
      font-size: 13px;
    }
  }
`;
let PasswordText = styled.p`
  font-family: Montserrat;
  font-size: 9px;
  line-height: 1.22;
  text-align: left;
  color: #828385;
  width: 90%;
  margin: 0 10px;
`;

let BackButton = styled.button`
  bottom: 0;
  border: 0;
  background: none;
  left: 20px;
  font-family: Montserrat;
  font-size: 11px;
  line-height: 1.3;
  color: #4b4d4e;
  text-decoration: underline;
  @media (min-width: 1600px) {
    font-size: 12px;
  }
`;
const PasswordPage = wrapComponent(({ t, createSnackbar, history, props }) => {
  // console.log(props)
  let { id, token } = useParams();
  // console.log(id)
  // console.log(token)
  const [credentials, setCredentials] = useState({ email: '' });
  const passwordRgx = new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[*.!@$%^&(){}[\]:;<>,.?\/~_+-=|])[A-Za-z\d*.!@$%^&(){}[\]:;<>,.?\/~_+-=|]{8,}$/
  );
  const [passwordStrength, setPasswordStrength] = useState('weak');
  const [errorFlag, setErrorFlag] = useState({
    password: '',
    confirmPassword: ''
  });

  useEffect(() => {
    checkLinkExpire();
  }, []);

  const checkLinkExpire = async () => {
    let data = {
      userId: id,
      uuid: token
    };
    await authenticationService
      .linkExpire(data)
      .then((response) => {
        if (response.data.status == true) {
          showSnackbar(t('reset_password.reset_expired'), 'error');
          setTimeout(() => {
            history.push('/forgot-password');
          }, 4000);
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data) {
          error = error.response.data.error;
          showSnackbar(error, 'error');
          setTimeout(() => {
            history.push('/forgot-password');
          }, 4000);
        }
      });
  };
  const submit = useCallback(async (event) => {
    event.preventDefault();
    if (!credentials.password) {
      setErrorFlag({ password: t('reset_password.enter_password') });
    } else if (!passwordRgx.test(credentials.password)) {
      setErrorFlag({ password: t('reset_password.password_unmatch') });
    } else if (!credentials.confirmPassword) {
      setErrorFlag({ cpassword: t('reset_password.confirm_required') });
    } else if (credentials.password !== credentials.confirmPassword) {
      setErrorFlag({ cpassword: t('reset_password.cofirm_password_unmatch') });
    } else {
      let data = {
        userId: id,
        uuid: token,
        password: credentials.password,
        cpassword: credentials.confirmPassword
      };
      await authenticationService
        .resetPassword(data)
        .then((response) => {
          setTimeout(() => {
            history.push('/login');
          }, 4000);
          showSnackbar(t('reset_password.reset_success'), 'info');
        })
        .catch((error) => {
          if (error && error.response && error.response.data) {
            error = error.response.data.error;
            console.error(error.response);
            // console.log(error.response.data);
            // console.log(error.response.data.error);
            showSnackbar(error, 'error');
          }
        });
    }
  });

  const showSnackbar = (msg, theme) => {
    createSnackbar({
      theme,
      message: msg,
      timeout: 4000,
      dismissable: false,
      pauseOnHover: true,
      progressBar: false,
      sticky: false
    });
  };

  const fieldChange = useCallback((event) => {
    let data = {};
    data[event.target.name] = event.target.value;
    setCredentials({ ...credentials, ...data });
    setErrorFlag({ password: '', cpassword: '' });
  });

  const validatePassword = useCallback((event) => {
    let password = event.target.value;
    // Do not show anything when the length of password is zero.
    if (password.length === 0) {
      return;
    }
    const mediumPasswordPattern = new RegExp(
      /^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]+)|(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[@$!%*#?&])[A-Za-z@$!%*#?&]+)|(?:(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Z\d@$!%*#?&]+)|(?:(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[a-z\d@$!%*#?&]+)$/
    );
    if (password.match(passwordRgx)) {
      setPasswordStrength('strong');
    } else if (password.match(mediumPasswordPattern)) {
      setPasswordStrength('medium');
    } else {
      setPasswordStrength('weak');
    }
  });

  return (
    <CardLanding>
      <LoginRegisterHeader text={t('login.reset_header')} />
      <LoginForm name='form' onSubmit={submit}>
        <Input
          text={t('register.new_password')}
          type='password'
          error={errorFlag.password}
          name='password'
          value={props}
          onChange={fieldChange}
          placeholder='Enter New Password'
        />
        <Input
          text={t('register.confirm_new_password')}
          type='password'
          error={errorFlag.cpassword}
          name='confirmPassword'
          onKeyUp={validatePassword}
          value={credentials.confirmPassword}
          onChange={fieldChange}
          placeholder='Confirm Password'
        />
        {!(credentials.password && credentials.confirmPassword) ? (
          <PasswordText>{t('register.password_text')}</PasswordText>
        ) : (
          <PasswordStrength passwordStrength={passwordStrength} />
        )}
        <SignUp>
          <Button text={t('universal_actions.done')} fullWidth={'40%'} />
          <div className='form-group'>
            <BackButton className='button icon-left'>
              <Link to='/login' className='link-text'>
                &#8592; {t('universal_actions.back')}
              </Link>
            </BackButton>
          </div>
        </SignUp>
      </LoginForm>
    </CardLanding>
  );
});

export default withTranslation('common')(PasswordPage);
