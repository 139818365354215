let object = {
  domain: '',
  SENDBIRD_API: ''
};

if (process.env.REACT_APP_ENV === 'production') {
  object.domain = process.env.REACT_APP_API_ENDPOINT;
  object.SENDBIRD_API = process.env.REACT_APP_SENDBIRD_API;
} else if (process.env.REACT_APP_ENV === 'testing') {
  object.domain = process.env.REACT_APP_API_ENDPOINT;
  object.SENDBIRD_API = process.env.REACT_APP_SENDBIRD_API;
} else if (process.env.REACT_APP_ENV === 'staging') {
  object.domain = process.env.REACT_APP_API_ENDPOINT;
  object.SENDBIRD_API = process.env.REACT_APP_SENDBIRD_API;
} else {
  object.domain = process.env.REACT_APP_API_ENDPOINT;
  object.SENDBIRD_API = process.env.REACT_APP_SENDBIRD_API;
}

module.exports = object;
