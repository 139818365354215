import React from 'react';
import styled from 'styled-components';
import Close from '../public/icons/Close';
let ModalWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  backdrop-filter: blur(1px);
  background-color: rgba(40, 40, 40, 0.75);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

let ModalInnerWrapperProfile = styled.div`
  width: fit-content;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  height: fit-content;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 0.5px 1.5px 0 rgba(0, 0, 0, 0.04);
  border: solid 0.5px #eaedf3;
  background-color: #ffffff;
  padding: 0px 20px 20px 0px;
  overflow: hidden;
`;
let ModalInnerWrapper = styled.div`
  width: fit-content;
  position: initial;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  height: fit-content;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 0.5px 1.5px 0 rgba(0, 0, 0, 0.04);
  border: solid 0.5px #eaedf3;
  background-color: #ffffff;
  padding: 20px;
`;

let CloseBox = styled.span`
  :hover {
    cursor: pointer;
  }
`;

const Modal = (props) => (
  <ModalWrapper>
    {props.userType && (
      <ModalInnerWrapperProfile>
        <div className='row m-0 float-right close-btn'>
          <CloseBox onClick={props.close}>
            <Close />
          </CloseBox>
        </div>
        {props.children}
      </ModalInnerWrapperProfile>
    )}
    {!props.userType && (
      <ModalInnerWrapper>
        <div className='row m-0 float-right'>
          <CloseBox onClick={props.close}>
            <Close />
          </CloseBox>
        </div>
        {props.children}
      </ModalInnerWrapper>
    )}
  </ModalWrapper>
);

export { Modal };
