import React, { useCallback, useState, useContext, useMemo } from 'react';
import { authenticationService } from '../../_services';
import { TextArea, Input, Dropdown, Button, LoginRegisterHeader } from '../../common';
import styled from 'styled-components';
import { FormProgress } from '../../common/FormProgress';
import countryList from 'react-select-country-list';
import validator from 'validator';
import { UserContext } from '../../common/context/UserContext';
import { wrapComponent } from 'react-snackbar-alert';
import Avatar from 'react-avatar';
import { withTranslation } from 'react-i18next';
import MultiSelectDropdown from '../../common/MultiSelectDropdown';
import PasswordStrength from '../../common/PasswordStrength';
import { Spinner } from '../../common/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Expand from 'react-expand-animated';
import { useMobile } from '../../hook';

const AvatarLoader = styled.div`
  position: relative;
  .spinner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const RowWrapper = styled.div`
  width: 72vw;
  height: 80vh;
  div.wrapper {
    top: -1px;
    left: 15px;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 9px;
    border: 0;
    div.left-panel {
      height: 100%;
      background-color: #f8f8f8;
      border-top-left-radius: 9px;
      border-bottom-left-radius: 9px;
    }
    div.right-panel {
      div.center-form {
        text-align: center;
        top: 20px;
        height: 85%;
        position: relative;
      }
    }
  }

  .form-progress {
    margin: 1.5rem auto 0 auto;
  }

  @media screen and (max-width: 520px) {
    & {
      div.wrapper {
        div.left-panel {
          overflow: auto;
          padding: 0 2%;
        }
      }

      .profile-card-heading {
        margin: 30px 15px;
      }
    }
  }
`;

const BottomSection = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  width: inherit;
  bottom: -30px;
`;

const BackButton = styled.button`
  bottom: 0;
  border: 0;
  background: none;
  position: absolute;
  top: 20px;
  left: 25%;
  font-family: Montserrat;
  font-size: 11px;
  line-height: 1.3;
  color: #4b4d4e;
  text-decoration: underline;
  @media (min-width: 1600px) {
    font-size: 12px;
  }
`;

const FormDetails = styled.form`
  margin: 3% 0px 2% 0;
`;

const PasswordText = styled.p`
  font-family: Montserrat;
  font-size: 9px;
  line-height: 1.22;
  text-align: left;
  color: #828385;
  width: 100%;
  margin: 0px 10px 1rem 10px;
  @media (min-width: 1600px) {
    font-size: 10px;
  }
`;

const EditTag = styled.a`
  font-size: 12px;
  line-height: 1.33;
  text-align: center;
  color: #4b4d4e !important;
  cursor: pointer;
  text-decoration: underline !important;
`;

const DeleteTag = styled.a`
  font-size: 12px;
  line-height: 1.33;
  text-align: center;
  padding-left: 5px;
  color: #dc3545 !important;
  cursor: pointer;
  text-decoration: underline !important;
`;

const CardItem = styled.h1`
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  margin: 30px;
  cursor: pointer;
  text-align: left;
  display: flex;
  justify-content: space-between;
  svg {
    display: none;
    transition: transform 200ms ease-in-out;
    color: #5e5e5e;
  }

  @media screen and (max-width: 520px) {
    & {
      margin: 30px 15px 10px 15px;

      svg {
        display: block;
      }
    }
  }

  ${(props) =>
    props.active &&
    `
      svg {
        transform: rotatez(90deg)
      }
  `}
`;

const ExpandContent = styled.div`
  padding: 3vw;
  background: white;
  position: relative;
  box-shadow: 1px 2px 0.2px 0.1px #d0cdcd42;
  > div {
    display: block !important;
  }

  > .form-group {
    margin-bottom: 0;
    margin-top: 5%;
  }

  > button {
    left: 25%;
    bottom: 6%;
    top: unset;
  }

  form {
    width: 100%;
    flex: unset;
    max-width: unset;
  }
`;

const ProfileModal = wrapComponent(({ createSnackbar, profileCompleteForm, t }) => {
  const isMobile = useMobile();
  const [{ user, dropdown }, dispatch] = useContext(UserContext);
  // console.log(user);
  // console.log(dropdown);
  const phnRegx = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);
  const passwordRgx = new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[*.!@$%^&(){}[\]:;<>,.?\/~_+-=|])[A-Za-z\d*.!@$%^&(){}[\]:;<>,.?\/~_+-=|]{8,}$/
  );

  const [registrationForm, setRegistrationForm] = useState({
    emailId: user.user.email,
    firstName: user.user.firstName,
    lastName: user.user.lastName,
    country: user.user.country,
    phoneNumber: user.user.phone,
    oldPassword: '',
    password: '',
    confirmPassword: '',
    vision: user.user.role === 'client' && user.user.vision ? user.user.vision : '',
    industry: user.user.role === 'client' ? user.user.industry : '',
    businessModel: (user.user.role === 'client' && user.user.businessModel) || [],
    targetCustomers: user.user.role === 'client' ? user.user.targetCustomers : '',
    companyName: user.user.role === 'client' ? user.user.company.name : '',
    position: user.user.role === 'client' ? user.user.company.position : '',
    expectedServices:
      (user.user.role === 'client' && user.user.futureEndeavors?.expectedServices) || [],
    expectedGlobalInvolved:
      (user.user.role === 'client' && user.user.futureEndeavors?.expectedGlobalInvolved) || [],
    expectedSecondOption:
      user.user.role === 'client' &&
      user.user.futureEndeavors &&
      user.user.futureEndeavors.expectedSecondOption
        ? user.user.futureEndeavors.expectedSecondOption
        : '',
    innovation:
      user.user.role === 'client' && user.user.globalPresence
        ? user.user.globalPresence.innovation
        : '',
    marketUnderstanding:
      user.user.role === 'client' && user.user.globalPresence
        ? user.user.globalPresence.marketUnderstanding
        : '',
    marketInfoSend:
      user.user.role === 'client' && user.user.globalPresence
        ? user.user.globalPresence.marketInfoSend
        : '',
    marketCommunication:
      user.user.role === 'client' && user.user.globalPresence
        ? user.user.globalPresence.marketCommunication
        : '',
    competeAbility:
      user.user.role === 'client' && user.user.globalPresence
        ? user.user.globalPresence.competeAbility
        : '',
    currentFormStage: profileCompleteForm ? profileCompleteForm : 1,
    currentHeading: 'Profile',
    currentCompanyStage: 1,
    currentGlobalStage: 1
  });

  const [passwordStrength, setPasswordStrength] = useState('weak');

  const [errorFlag, setErrorFlag] = useState({
    emailId: '',
    firstName: '',
    lastName: '',
    country: '',
    phoneNumber: '',
    companyName: '',
    position: '',
    vision: '',
    industry: '',
    businessModel: '',
    targetCustomers: '',
    innovation: '',
    marketUnderstanding: '',
    marketInfoSend: '',
    marketCommunication: '',
    competeAbility: ''
  });

  const [avatarLoading, setAvatarLoading] = useState();

  const showSnackbar = (msg, theme) => {
    createSnackbar({
      theme,
      message: msg,
      timeout: 2000,
      dismissable: false,
      pauseOnHover: true,
      progressBar: false,
      sticky: false
    });
  };

  const countriesList = useMemo(() => {
    const vip = [];
    const list = countryList()
      .getData()
      .filter((item) => {
        const match = ['US', 'JP', 'FR'].includes(item.value);
        if (match) {
          vip.push(item);
        }
        return !match;
      });

    const vipOrder = {
      JP: 1,
      US: 2,
      FR: 3
    };
    vip.sort((a, b) => {
      return vipOrder[a.value] - vipOrder[b.value];
    });

    return [...vip, ...list];
  }, []);

  const validateFormStep = () => {
    let erorrMessage = '';
    if (registrationForm.currentFormStage == 1) {
      if (
        registrationForm.emailId &&
        validator.isEmail(registrationForm.emailId) &&
        registrationForm.phoneNumber &&
        phnRegx.test(registrationForm.phoneNumber) &&
        registrationForm.firstName &&
        registrationForm.lastName &&
        registrationForm.country
      ) {
        return [true, erorrMessage];
      }
    } else if (registrationForm.currentFormStage == 2) {
      if (
        registrationForm.oldPassword &&
        registrationForm.password &&
        passwordRgx.test(registrationForm.password) &&
        registrationForm.confirmPassword &&
        registrationForm.password === registrationForm.confirmPassword
      ) {
        return [true, erorrMessage];
      } else if (registrationForm.password !== registrationForm.confirmPassword) {
        erorrMessage = t('register.password_not_match');
        return [false, erorrMessage];
      } else if (!passwordRgx.test(registrationForm.password)) {
        erorrMessage = t('register.password_match');
        return [false, erorrMessage];
      }
    } else if (registrationForm.currentFormStage == 4) {
      if (
        registrationForm.industry &&
        registrationForm.targetCustomers &&
        registrationForm.vision &&
        registrationForm.businessModel &&
        registrationForm.companyName &&
        registrationForm.position
      ) {
        return [true, erorrMessage];
      }
    } else if (registrationForm.currentFormStage == 5) {
      if (
        registrationForm.competeAbility &&
        registrationForm.innovation &&
        registrationForm.marketInfoSend &&
        registrationForm.marketUnderstanding &&
        registrationForm.marketCommunication
      ) {
        return [true, erorrMessage];
      }
    } else if (registrationForm.currentFormStage == 6) {
      if (
        registrationForm.expectedServices &&
        registrationForm.expectedGlobalInvolved &&
        registrationForm.expectedSecondOption
      ) {
        return [true, erorrMessage];
      }
    }
    return [false, erorrMessage];
  };

  const submit = useCallback(() => {
    let verify = true;
    let message;
    [verify, message] = validateFormStep();
    if (verify) {
      let data = {};
      let msg = '';
      const role = user.user.role;
      if (registrationForm.currentFormStage === 1) {
        data = {
          email: registrationForm.emailId,
          firstName: registrationForm.firstName,
          lastName: registrationForm.lastName,
          country: registrationForm.country,
          phone: registrationForm.phoneNumber
        };
        msg = t('user_profile.actions.profile_update');
      } else if (registrationForm.currentFormStage === 2) {
        data = {
          oldPassword: registrationForm.oldPassword,
          password: registrationForm.password,
          cpassword: registrationForm.confirmPassword
        };
        msg = t('user_profile.actions.profile_password_update');
      } else if (registrationForm.currentFormStage == 4) {
        data = {
          industry: registrationForm.industry,
          targetCustomers: registrationForm.targetCustomers,
          vision: registrationForm.vision,
          businessModel: registrationForm.businessModel
        };
        data.company = {
          name: registrationForm.companyName,
          position: registrationForm.position
        };
        msg = t('user_profile.actions.company_update');
      } else if (registrationForm.currentFormStage === 5) {
        data.globalPresence = {
          innovation: registrationForm.innovation,
          marketUnderstanding: registrationForm.marketUnderstanding,
          marketInfoSend: registrationForm.marketInfoSend,
          marketCommunication: registrationForm.marketCommunication,
          competeAbility: registrationForm.competeAbility
        };
        msg = t('user_profile.actions.global_presence_update');
      } else if (registrationForm.currentFormStage === 6) {
        data.futureEndeavors = {
          expectedServices: registrationForm.expectedServices,
          expectedGlobalInvolved: registrationForm.expectedGlobalInvolved,
          expectedSecondOption: registrationForm.expectedSecondOption
        };
        msg = t('user_profile.actions.future_endeavor_update');
      }
      authenticationService.updateProfessional(data, role).then(
        (response) => {
          authenticationService.updateUser().then(
            (res) => {
              if (registrationForm.currentFormStage == 2) {
                data = {
                  oldPassword: '',
                  password: '',
                  confirmPassword: ''
                };
                setRegistrationForm({ ...registrationForm, ...data });
              } else {
                authenticationService.getDropDown().then(
                  (dropdownResponse) => {
                    const newData = { user: res };
                    dispatch({
                      type: 'SET_USER_DETAILS',
                      payload: newData,
                      dropdown: dropdownResponse.data
                    });
                  },
                  (error) => {
                    if (error.response) {
                      showSnackbar(error.response.data.error, 'error');
                    } else {
                      console.error('Error', error.message);
                    }
                  }
                );
              }
              showSnackbar(msg, 'info');
            },
            (error) => {
              if (error.response) {
                showSnackbar(error.response.data.error, 'error');
              } else {
                // console.log("Error", error.message);
              }
            }
          );
        },
        (error) => {
          if (error.response) {
            showSnackbar(error.response.data.error, 'error');
          } else {
            // console.log("Error", error.message);
          }
        }
      );
    } else {
      if (message) {
        showSnackbar(message, 'error');
      } else {
        showSnackbar(t('user_profile.actions.all_details_needed'), 'error');
      }
    }
  });

  const fieldChange = useCallback((event) => {
    let data = {};
    data[event.target.name] = event.target.value;
    setRegistrationForm({ ...registrationForm, ...data });
  });

  const validateField = (event) => {
    let temp = {
      ...errorFlag
    };
    temp[event.target.name] = false;
    if (!event.target.value || (event.target.value + '').length < 2) {
      temp[event.target.name] = t('user_profile.actions.invalid_field');
    } else {
      if (event.target.name == 'emailId' && !validator.isEmail(event.target.value)) {
        temp[event.target.name] = t('user_profile.actions.invalid_field');
      }
      if (event.target.name == 'phoneNumber' && !phnRegx.test(event.target.value)) {
        temp[event.target.name] = t('user_profile.actions.invalid_field');
      }
    }
    setErrorFlag(temp);
  };

  const triggerNextForm = useCallback(async (type, text) => {
    // console.log("registrationForm");
    setRegistrationForm({
      ...registrationForm,
      currentFormStage: type,
      currentHeading: text,
      currentCompanyStage: 1,
      currentGlobalStage: 1
    });
  });

  const validatePassword = useCallback((event) => {
    let password = event.target.value;
    // Do not show anything when the length of password is zero.
    if (password.length === 0) {
      return;
    }
    const mediumPasswordPattern = new RegExp(
      /^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]+)|(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[@$!%*#?&])[A-Za-z@$!%*#?&]+)|(?:(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Z\d@$!%*#?&]+)|(?:(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[a-z\d@$!%*#?&]+)$/
    );
    if (password.match(passwordRgx)) {
      setPasswordStrength('strong');
    } else if (password.match(mediumPasswordPattern)) {
      setPasswordStrength('medium');
    } else {
      setPasswordStrength('weak');
    }
  });

  const checkActiveBtn = (filterName) => {
    return filterName === registrationForm.currentFormStage;
  };

  const submitText = () => {
    if (
      registrationForm.currentCompanyStage === 3 ||
      registrationForm.currentGlobalStage === 2 ||
      registrationForm.currentFormStage === 1 ||
      registrationForm.currentFormStage === 2 ||
      registrationForm.currentFormStage === 6
    ) {
      return t('universal_actions.save');
    } else {
      return t('universal_actions.next');
    }
  };

  const triggerFormData = async (type) => {
    // console.log(registrationForm);
    // console.log("registrationForm");
    if (type === 'next') {
      if (registrationForm.currentFormStage === 4 && registrationForm.currentCompanyStage < 3) {
        setRegistrationForm({
          ...registrationForm,
          currentCompanyStage: registrationForm.currentCompanyStage + 1
        });
      } else if (
        registrationForm.currentFormStage === 5 &&
        registrationForm.currentGlobalStage < 2
      ) {
        setRegistrationForm({
          ...registrationForm,
          currentGlobalStage: registrationForm.currentGlobalStage + 1
        });
      } else {
        setRegistrationForm({ ...registrationForm });
        submit();
      }
    } else {
      if (registrationForm.currentFormStage === 4 && registrationForm.currentCompanyStage > 1) {
        setRegistrationForm({
          ...registrationForm,
          currentCompanyStage: registrationForm.currentCompanyStage - 1
        });
      } else if (
        registrationForm.currentFormStage === 5 &&
        registrationForm.currentGlobalStage > 1
      ) {
        setRegistrationForm({
          ...registrationForm,
          currentGlobalStage: registrationForm.currentGlobalStage - 1
        });
      }
    }
  };

  const fileUpload = useCallback(async (event) => {
    event.preventDefault();
    setAvatarLoading(true);
    var data = new FormData();
    Array.from(event.target.files).map((file) => {
      if (
        file.name.endsWith('.png') ||
        file.name.endsWith('.jpg') ||
        file.name.endsWith('.JPG') ||
        file.name.endsWith('.jpeg')
      ) {
        data.append('document', file);
      } else {
        showSnackbar(t('user_profile.actions.inavlid_file_format'), 'error');
      }
    });

    try {
      const profileRes = await authenticationService.updateProfileImage(data);
      const dropdownRes = await authenticationService.getDropDown();
      const newData = { user: profileRes.data.result };
      dispatch({
        type: 'SET_USER_DETAILS',
        payload: newData,
        dropdown: dropdownRes.data
      });
      showSnackbar(t('user_profile.actions.profile_image_update'), 'info');
    } catch (error) {
      if (error.response) {
        showSnackbar(error.response.data.error, 'error');
      }
    }
    setAvatarLoading(false);
  });

  const handleClearAvatar = useCallback(
    async (event) => {
      event.preventDefault();

      if (avatarLoading) {
        return;
      }
      setAvatarLoading(true);

      try {
        const profileRes = await authenticationService.clearProfileImage();
        const dropdownRes = await authenticationService.getDropDown();
        const newData = { user: profileRes.data.result };
        dispatch({
          type: 'SET_USER_DETAILS',
          payload: newData,
          dropdown: dropdownRes.data
        });
        showSnackbar(t('user_profile.actions.profile_image_delete'), 'info');
      } catch (error) {
        if (error.response) {
          showSnackbar(error.response.data.error, 'error');
        }
      }
      setAvatarLoading(false);
    },
    [avatarLoading]
  );

  let myref = null;
  const handleBrowse = function (e) {
    e.preventDefault();
    myref.click();
  };

  const selectedTeamMembers = (name, items) => {
    let data = {};
    data[name] = items.map((item) => item.value);
    setRegistrationForm({ ...registrationForm, ...data });
  };

  const businessModels = useMemo(() => {
    return dropdown.businessModel.reduce((hs, item) => {
      if (item.value) {
        hs[item.value] = item;
      }
      return hs;
    }, {});
  }, [dropdown.businessModel]);

  const expectedServices = useMemo(() => {
    return dropdown.expectedServices.reduce((hs, item) => {
      if (item.value) {
        hs[item.value] = item;
      }
      return hs;
    }, {});
  }, [dropdown.expectedServices]);

  const expectedGlobalInvolved = useMemo(() => {
    return dropdown.expectedGlobalInvolved.reduce((hs, item) => {
      if (item.value) {
        hs[item.value] = item;
      }
      return hs;
    }, {});
  }, [dropdown.expectedGlobalInvolved]);

  const Buttons = (
    <>
      <Button fullWidth={'20%'} text={submitText()} onClick={() => triggerFormData('next')} />
      {((registrationForm.currentFormStage === 4 && registrationForm.currentCompanyStage > 1) ||
        (registrationForm.currentFormStage === 5 && registrationForm.currentGlobalStage > 1)) && (
        <BackButton className='button icon-left' onClick={() => triggerFormData('back')}>
          &#8592; {t('universal_actions.back')}
        </BackButton>
      )}
    </>
  );

  const ProfileBlock = registrationForm.currentFormStage === 1 && (
    <div className='row justify-content-center'>
      <FormDetails name='form' className='col-8 mt-4'>
        <Input
          text={t('register.email')}
          error={errorFlag.emailId}
          type='email'
          name='emailId'
          readOnly={true}
          value={registrationForm.emailId}
          onChange={fieldChange}
          onBlur={validateField}
          placeholder={t('register.email_placeholder')}
        />
        <Input
          text={t('register.first_name')}
          error={errorFlag.firstName}
          type='text'
          name='firstName'
          value={registrationForm.firstName}
          onChange={fieldChange}
          onBlur={validateField}
          placeholder={t('register.first_name_placeholder')}
        />
        <Input
          text={t('register.last_name')}
          type='text'
          error={errorFlag.lastName}
          name='lastName'
          value={registrationForm.lastName}
          onChange={fieldChange}
          onBlur={validateField}
          placeholder={t('register.last_name_placeholder')}
        />
        <Dropdown
          text={t('register.country')}
          error={errorFlag.country}
          type='country'
          name='country'
          value={registrationForm.country}
          onChange={fieldChange}
          onBlur={validateField}
          options={countriesList}
        />
        <Input
          text={t('register.phone_number')}
          type='text'
          error={errorFlag.phoneNumber}
          name='phoneNumber'
          value={registrationForm.phoneNumber}
          onChange={fieldChange}
          onBlur={validateField}
          placeholder={t('register.phone_number_placeholder')}
        />
      </FormDetails>
    </div>
  );

  const PasswordBlock = registrationForm.currentFormStage === 2 && (
    <div className='row justify-content-center'>
      <FormDetails name='form' className='col-8 mt-4'>
        <Input
          text={t('register.old_password')}
          type='password'
          name='oldPassword'
          value={registrationForm.oldPassword}
          onChange={fieldChange}
          onBlur={validateField}
          placeholder={t('register.password_placeholder')}
        />
        <Input
          text={t('register.new_password')}
          type='password'
          name='password'
          value={registrationForm.password}
          onChange={fieldChange}
          onBlur={validateField}
          placeholder={t('register.password_placeholder')}
        />
        <PasswordText>{t('register.password_text')}</PasswordText>
        <Input
          text={t('register.confirm_new_password')}
          type='password'
          name='confirmPassword'
          onKeyUp={validatePassword}
          value={registrationForm.confirmPassword}
          onChange={fieldChange}
          onBlur={validateField}
          placeholder={t('register.confirm_password_placeholder')}
        />
        {registrationForm.password &&
        registrationForm.confirmPassword &&
        registrationForm.password !== registrationForm.confirmPassword ? (
          <PasswordText>{t('register.password_not_match')}</PasswordText>
        ) : (
          ''
        )}
        {registrationForm.password &&
        registrationForm.confirmPassword &&
        registrationForm.password === registrationForm.confirmPassword ? (
          <PasswordStrength passwordStrength={passwordStrength} />
        ) : (
          ''
        )}
      </FormDetails>
    </div>
  );

  const CompanyBlock = registrationForm.currentFormStage === 4 && (
    <div className='row justify-content-center'>
      <div className='form-progress col-6 mt-4'>
        <FormProgress
          totalForms={3}
          completed={registrationForm.currentCompanyStage}
        ></FormProgress>
      </div>
      {registrationForm.currentCompanyStage === 1 && (
        <FormDetails className='col-8 mt-4' name='form'>
          <Input
            text={t('register.company_name')}
            type='companyName'
            error={errorFlag.companyName}
            name='companyName'
            value={registrationForm.companyName}
            onChange={fieldChange}
            onBlur={validateField}
            placeholder={t('register.company_name_placeholder')}
          />
          <Input
            text={t('register.position')}
            type='text'
            name='position'
            error={errorFlag.position}
            value={registrationForm.position}
            onChange={fieldChange}
            onBlur={validateField}
            placeholder={t('register.position_placeholder')}
          />
        </FormDetails>
      )}
      {registrationForm.currentCompanyStage === 2 && (
        <FormDetails className='col-8 mt-4' name='form'>
          <TextArea
            text={t('user_profile.vision')}
            cols={50}
            rows={6}
            placeholder={t('user_profile.vision_placeholder')}
            name='vision'
            onChange={fieldChange}
            value={registrationForm.vision}
            width='100%'
          />
        </FormDetails>
      )}
      {registrationForm.currentCompanyStage === 3 && (
        <FormDetails className='col-8 mt-4' name='form'>
          <Dropdown
            text={t('user_profile.industry')}
            error={errorFlag.industry}
            type='industry'
            name='industry'
            value={registrationForm.industry}
            onChange={fieldChange}
            onBlur={validateField}
            options={dropdown.industry}
          />
          <MultiSelectDropdown
            text={t('user_profile.business_model')}
            error={errorFlag.businessModel}
            type='businessModel'
            name='businessModel'
            selectedTeam={selectedTeamMembers}
            selectedMember={registrationForm.businessModel.map((item) => businessModels[item])}
            multiple={true}
            teamMembersOptions={dropdown.businessModel}
            value={registrationForm.businessModel
              .map((item) => businessModels[item].label)
              .join(' , ')}
          />
          <Input
            text={t('user_profile.target_customer')}
            type='text'
            error={errorFlag.targetCustomers}
            name='targetCustomers'
            value={registrationForm.targetCustomers}
            onChange={fieldChange}
            onBlur={validateField}
            placeholder={t('user_profile.target_customer_placeholder')}
          />
        </FormDetails>
      )}
    </div>
  );

  const GlobalStageBlock = registrationForm.currentFormStage === 5 && (
    <div className='row justify-content-center'>
      <div className='form-progress col-6 mt-4'>
        <FormProgress totalForms={2} completed={registrationForm.currentGlobalStage}></FormProgress>
      </div>
      {registrationForm.currentGlobalStage === 1 && (
        <FormDetails className='col-8 mt-4' name='form'>
          <Dropdown
            text={t('user_profile.rate_company_knowledge')}
            error={errorFlag.innovation}
            type='innovation'
            name='innovation'
            value={registrationForm.innovation}
            onChange={fieldChange}
            onBlur={validateField}
            options={dropdown.innovation}
          />
          <Dropdown
            text={t('user_profile.company_understanding')}
            error={errorFlag.marketUnderstanding}
            type='marketUnderstanding'
            name='marketUnderstanding'
            value={registrationForm.marketUnderstanding}
            onChange={fieldChange}
            onBlur={validateField}
            options={dropdown.marketUnderstanding}
          />
          <Dropdown
            text={t('user_profile.company_send_info')}
            error={errorFlag.marketInfoSend}
            type='marketInfoSend'
            name='marketInfoSend'
            value={registrationForm.marketInfoSend}
            onChange={fieldChange}
            onBlur={validateField}
            options={dropdown.marketInfoSend}
          />
        </FormDetails>
      )}
      {registrationForm.currentGlobalStage === 2 && (
        <FormDetails className='col-8 mt-4' name='form'>
          <Dropdown
            text={t('user_profile.company_experience')}
            error={errorFlag.marketCommunication}
            type='marketCommunication'
            name='marketCommunication'
            value={registrationForm.marketCommunication}
            onChange={fieldChange}
            onBlur={validateField}
            options={dropdown.marketCommunication}
          />
          <Dropdown
            text={t('user_profile.company_ability')}
            error={errorFlag.competeAbility}
            type='competeAbility'
            name='competeAbility'
            value={registrationForm.competeAbility}
            onChange={fieldChange}
            onBlur={validateField}
            options={dropdown.competeAbility}
          />
        </FormDetails>
      )}
    </div>
  );

  const ExpectedServiceBlock = registrationForm.currentFormStage === 6 && (
    <div className='row justify-content-center'>
      <FormDetails name='form' className='col-8 mt-4'>
        <MultiSelectDropdown
          text={t('user_profile.expected_services')}
          type='expectedServices'
          name='expectedServices'
          selectedTeam={selectedTeamMembers}
          selectedMember={registrationForm.expectedServices.map((item) => expectedServices[item])}
          multiple={true}
          teamMembersOptions={dropdown.expectedServices}
          value={registrationForm.expectedServices
            .map((item) => expectedServices[item].label)
            .join(' , ')}
        />
        <MultiSelectDropdown
          text={t('user_profile.expected_global_involved')}
          type='expectedGlobalInvolved'
          name='expectedGlobalInvolved'
          selectedTeam={selectedTeamMembers}
          selectedMember={registrationForm.expectedGlobalInvolved.map(
            (item) => expectedGlobalInvolved[item]
          )}
          multiple={true}
          teamMembersOptions={dropdown.expectedGlobalInvolved}
          value={registrationForm.expectedGlobalInvolved
            .map((item) => expectedGlobalInvolved[item].label)
            .join(' , ')}
        />
        <Dropdown
          text={t('user_profile.expected_second_option')}
          type='expectedSecondOption'
          name='expectedSecondOption'
          value={registrationForm.expectedSecondOption}
          onChange={fieldChange}
          onBlur={validateField}
          options={dropdown.expectedSecondOption}
        />
      </FormDetails>
    </div>
  );

  console.log('isMobile', isMobile);

  return (
    <>
      <RowWrapper>
        <div className='row wrapper'>
          <div className='col-md-4 left-panel'>
            <AvatarLoader>
              {!!user.user.profileImage ? (
                <img className='profile-img' src={user.user.profileImage} />
              ) : (
                <Avatar
                  className='profile-img-avatar'
                  name={user.user.firstName + ' ' + user.user.lastName}
                />
              )}
              {avatarLoading && (
                <div className='spinner-wrapper'>
                  <Spinner visible />
                </div>
              )}
            </AvatarLoader>

            <input
              id='myInput'
              type='file'
              accept='.png,.jpg,.jpeg,.JPG'
              ref={(r) => {
                myref = r;
              }}
              style={{ display: 'none' }}
              onChange={fileUpload}
            />
            <EditTag onClick={handleBrowse}>{t('universal_actions.edit_box')}</EditTag>
            {!!user.user.profileImage && (
              <DeleteTag onClick={handleClearAvatar}>{t('universal_actions.delete_box')}</DeleteTag>
            )}
            <CardItem
              onClick={() => triggerNextForm(1, t('user_profile.profile'))}
              active={checkActiveBtn(1)}
            >
              {t('user_profile.profile')}
              <FontAwesomeIcon icon={faChevronRight} />
            </CardItem>
            {isMobile && (
              <Expand
                open={checkActiveBtn(1)}
                duration={200}
                transitions={['height', 'opacity', 'background']}
              >
                <ExpandContent>
                  {ProfileBlock}
                  {Buttons}
                </ExpandContent>
              </Expand>
            )}

            <CardItem
              onClick={() => triggerNextForm(2, t('user_profile.change_password'))}
              active={checkActiveBtn(2)}
            >
              {t('user_profile.change_password')}
              <FontAwesomeIcon icon={faChevronRight} />
            </CardItem>
            {isMobile && (
              <Expand
                open={checkActiveBtn(2)}
                duration={200}
                transitions={['height', 'opacity', 'background']}
              >
                <ExpandContent>
                  {PasswordBlock}
                  {Buttons}
                </ExpandContent>
              </Expand>
            )}
            {user.user.role == 'client' && (
              <>
                <CardItem
                  onClick={() => triggerNextForm(4, t('user_profile.company_details'))}
                  active={checkActiveBtn(4)}
                >
                  {t('user_profile.company_details')}
                  <FontAwesomeIcon icon={faChevronRight} />
                </CardItem>
                {isMobile && (
                  <Expand
                    open={checkActiveBtn(4)}
                    duration={200}
                    transitions={['height', 'opacity', 'background']}
                  >
                    <ExpandContent>
                      {CompanyBlock}
                      {Buttons}
                    </ExpandContent>
                  </Expand>
                )}
                <CardItem
                  onClick={() => triggerNextForm(5, t('user_profile.global_presence'))}
                  active={checkActiveBtn(5)}
                >
                  {t('user_profile.global_presence')}
                  <FontAwesomeIcon icon={faChevronRight} />
                </CardItem>
                {isMobile && (
                  <Expand
                    open={checkActiveBtn(5)}
                    duration={200}
                    transitions={['height', 'opacity', 'background']}
                  >
                    <ExpandContent>
                      {GlobalStageBlock}
                      {Buttons}
                    </ExpandContent>
                  </Expand>
                )}
                <CardItem
                  onClick={() => triggerNextForm(6, t('user_profile.future_endevour'))}
                  active={checkActiveBtn(6)}
                >
                  {t('user_profile.future_endevour')}
                  <FontAwesomeIcon icon={faChevronRight} />
                </CardItem>
                {isMobile && (
                  <Expand
                    open={checkActiveBtn(6)}
                    duration={200}
                    transitions={['height', 'opacity', 'background']}
                  >
                    <ExpandContent>
                      {ExpectedServiceBlock}
                      {Buttons}
                    </ExpandContent>
                  </Expand>
                )}
              </>
            )}
          </div>
          {!isMobile && (
            <div className='col-md-8 right-panel'>
              <div className='center-form'>
                <LoginRegisterHeader text={registrationForm.currentHeading} />
                {ProfileBlock}
                {PasswordBlock}
                {CompanyBlock}
                {GlobalStageBlock}
                {/* {registrationForm.currentFormStage === 3 && (
                  <div className="row justify-content-center">
                    <div className="col-10 mt-4">
                    <IframeWrapper>
                    <Iframe url={t('iframeLinks.eisProfile')}
                    width="130%"
                    height="130%"
                        />
                      </IframeWrapper>
                    </div>
                  </div>
                )} */}
                {ExpectedServiceBlock}
                {registrationForm.currentFormStage !== 3 && (
                  <BottomSection>{Buttons}</BottomSection>
                )}
              </div>
            </div>
          )}
        </div>
      </RowWrapper>
    </>
  );
});
export default withTranslation('common')(ProfileModal);
