import React, { useState, useCallback } from 'react';
import * as FileToRead from './0F3B5245D390D7C3B603F7E6C17B060C.txt';

class TextFileReader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: ''
    };
  }

  componentDidMount() {
    this.readTextFile(FileToRead);
  }

  readTextFile = (file) => {
    var rawFile = new XMLHttpRequest();
    rawFile.open('GET', file, false);
    rawFile.onreadystatechange = () => {
      if (rawFile.readyState === 4) {
        if (rawFile.status === 200 || rawFile.status == 0) {
          var allText = rawFile.responseText;
          this.setState({
            text: allText
          });
        }
      }
    };
    rawFile.send(null);
  };

  render() {
    return (
      <div>
        {this.state.text.split('\n').map((item, key) => {
          return (
            <span key={key}>
              {item}
              <br />
            </span>
          );
        })}
      </div>
    );
  }
}

export default TextFileReader;
