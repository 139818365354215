import React, { useContext } from 'react';
import { UserContext } from '../../../common/context/UserContext';
import Avatar from 'react-avatar';

const HeaderProfile = () => {
  const [{ user }, dispatch] = useContext(UserContext);
  return (
    <>
      {user.user.profileImage ? (
        <img src={user.user.profileImage} alt='Avatar' class='avatar' />
      ) : (
        <Avatar size='30px' round='50%' name={user.user.firstName + ' ' + user.user.lastName} />
      )}
    </>
  );
};

export { HeaderProfile };
