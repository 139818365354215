import React, { useState, useEffect, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { Button, Modal, Loader } from '../../../common';
import { projectService } from '../../../_services/project.service';
import { sendBirdService, clientService } from '../../../_services';
import { UserContext } from '../../../common/context/UserContext';
import * as moment from 'moment';
import Avatar from 'react-avatar';
import Message from '../../../common/Message';
import { withTranslation } from 'react-i18next';
import TeamDropdown from '../../../common/TeamDropdown';
import 'moment/locale/ja';
import 'moment/locale/en-ca';

let LeftTeamPanel = styled.div`
  background-color: ${(props) => (props.from === 'main' ? '#ffffff' : '#f8f8f8')};
  padding: ${(props) => (props.from === 'main' ? '30px 0' : '0')};
  p.message-header {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.05px;
    text-align: left;
    color: #171725;
    margin-bottom: 20px;
    margin-left: 20px;
  }
  div.footer-new-message {
    text-align: right;
    margin: 0;
    position: absolute;
    bottom: ${(props) => (props.from === 'main' ? '15px' : '0')};
    width: 60%;
    right: 0;
    padding: 10px 10px 0 10px;
  }
  div.team-list-chat {
    height: ${(props) => (props.from === 'main' ? 'calc(100% - 100px)' : 'calc(100% - 60px)')};
    padding: 0 20px 20px 20px;
    overflow: scroll;
    overflow-x: hidden;
    div.member-row {
      background-color: #ffffff;
      border-radius: 15px;
      height: 60px;
      margin: 10px 0;
    }
  }
`;
let Title = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.29;
  text-align: center;
  color: #696974;
`;

let RowChatPanel = styled.div`
  height: inherit;
  background-color: ${(props) => (props.selected ? '#21a8df' : '#ffffff')};
  border-radius: 15px;
  div.chat-panel-image {
    height: inherit;
    width: 50px;
    align-self: center;
    position: relative;
    img,
    div.sb-avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin: 0 5px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  div.chat-detail-user {
    width: 80%;
    padding: 10px;
    div.chat-detail-name {
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: 0.05px;
      text-align: left;
      color: ${(props) => (props.selected ? '#ffffff' : '#4b4d4e')};
    }
    div.chat-last-message {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.04px;
      text-align: left;
      color: ${(props) => (props.selected ? '#ffffff' : '#4b4d4e')};
      margin-top: 10px;
    }
  }
  :hover {
    cursor: pointer;
  }
`;

let HeaderChatPanel = styled.div`
  box-shadow: 0 0.5px 2px 0 rgba(23, 23, 37, 0.1);
  background-color: #ffffff;
  width: 90%;
  height: 15%;
  position: absolute;
  margin: 30px;
  margin-top: -10%;
  div.data-user-panel {
    display: flex;
    height: 100%;
    padding: 10px;
    p.chat-user-image {
      align-self: center;
      margin: 0 15px;
      img {
        width: 30px;
        height: 30px;
        border: 0;
        border-radius: 50%;
      }
    }
    p.chat-user-name {
      align-self: center;
      margin: 0 15px 0 0;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 600;
      line-height: 1;
      text-align: left;
      color: #4b4d4e;
    }
    p.chat-user-divider {
      align-self: center;
      margin: 0 15px 0 0;
      width: 1px;
      height: 50%;
      background-color: #d8d8d8;
    }
    p.chat-user-details {
      align-self: center;
      margin: 0 15px 0 0;
      font-family: Montserrat;
      font-size: 12px;
      line-height: 1.29;
      letter-spacing: 0.05px;
      text-align: left;
      color: #4b4d4e;
      @media (min-width: 1600px) {
        font-size: 13px;
      }
    }
  }
`;
let ActioBox = styled.div`
position: inherit;
    margin-left: -95px;
    margin-top: 40px;
    width: 200px;
    height: 300px;
    overflow: scroll;
    z-index: 1;
    ul{
        height: auto;
        padding: 0;
        list-style-type: none;
        box-shadow: 0 2.5px 7.5px 0 rgba(68,68,79,0.1);
        border: solid 0.5px #f8f8f8;
        background-color: #ffffff;
        border-radius: 5px;
        margin-top: 0;
        li{
            display: flex;
            border-bottom: solid 0.5px #f8f8f8;
            img, div.sb-avatar{
                width: 30px;
                height: 30px;
                border-radius: 50%;
                align-self: center;
    margin: 10px 10px;
            }
            p{
                font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    align-self: center;
            }
        }
    }
    }
`;
let TooltipUp = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #f8f8f8;
  left: 12px;
  position: relative;
`;

const ProjectMessagesPanel = ({ t, i18n, projectDetails, from, mainChatMemebers }) => {
  moment.locale('ja');
  const [teamMembers, setTeamMembers] = useState([]);
  const [showNewMessage, setShowNewMessage] = useState(false);
  const [showTeamDropdown, setShowTeamDropdown] = useState(true);
  const [selectedMembersToChat, setSelectedMembersToChat] = useState({
    groupName: '',
    members: []
  });
  const [listOfNewChannelURL, setListOfNewChannelURL] = useState(projectDetails.sendBirdChannelURL);
  const [dataOfLeftPanel, setDataOfLeftPanel] = useState([]);
  const [currentChat, setCurrentChat] = useState();
  const [leftPanelLoader, setLeftPanelLoader] = useState(false);
  const [{ user }, dispatch] = useContext(UserContext);
  const [onHovergGroupMembers, setOnHovergGroupMembers] = useState(false);
  const [noMessageFlag, setNoMessageFlag] = useState(false);

  useEffect(() => {
    retriveAllChannel();
  }, []);
  useEffect(() => {
    if (projectDetails._id && from !== 'main') {
      projectService.getTeam({ project_id: projectDetails._id }).then(
        (response) => {
          if (response.data.result) {
            // console.log("response.data.result", response.data.result);
            let setMembertemp = response.data.result.filter(
              (member) => member.member._id != user.user._id
            );
            setTeamMembers([...teamMembers, ...setMembertemp]);
          }
        },
        (err) => {
          console.error('Error while loading team members for New Message Modal', err);
        }
      );
    } else {
      setTeamMembers(mainChatMemebers);
    }
  }, []);

  const showTeamMembers = () => {
    setShowNewMessage(!showNewMessage);
    setShowTeamDropdown(true);
  };
  const closeDeleteModalHandler = () => {
    setShowNewMessage(false);
    setSelectedMembersToChat({ ...selectedMembersToChat, ...{ groupName: '', members: [] } });
  };
  const selectedTeamMembers = (value) => {
    // console.log("alvaue", value)
    let temp = [user.user._id];
    let detail = { data: null, present: false };
    let isSingle = value.length === 1 ? true : false;
    value.map((prof) => {
      temp.push(prof.member._id);
    });
    dataOfLeftPanel.map((prof) => {
      let allMembersId = [];
      if (prof.type === isSingle ? 'Single' : 'Group') {
        prof.membersDetails.map((member) => {
          allMembersId.push(member.userId);
        });
        allMembersId = allMembersId.sort();
        temp = temp.sort();
        if (JSON.stringify(temp) === JSON.stringify(allMembersId)) {
          detail.data = prof;
          detail.present = true;
        }
      }
    });

    if (!detail.present) {
      setSelectedMembersToChat({ ...selectedMembersToChat, members: value });
    } else {
      setCurrentChat(detail.data);
      closeDeleteModalHandler(); //to close modal new message
    }
  };

  const checkValidation = () => {
    if (selectedMembersToChat.members.length > 1) {
      if (!selectedMembersToChat.groupName) {
        return true;
      }
    }
    if (selectedMembersToChat.members.length === 0) {
      return true;
    }
    return false;
  };

  const createChat = () => {
    setLeftPanelLoader(false);
    if (selectedMembersToChat.members.length > 1) {
      //group
      createSendBirdChannel(
        selectedMembersToChat.members,
        selectedMembersToChat.groupName,
        'Group'
      );
    } else {
      createSendBirdChannel(
        selectedMembersToChat.members,
        selectedMembersToChat.members[0].member._id,
        'Single'
      );
    }
  };

  const getId = (array) => {
    let a = [user.user._id];
    array.map((member) => a.push(member.member._id));
    return a;
  };

  // const extractTeam = (teamChannel, setMembertemp) => {
  //     debugger;
  //     let temp = []
  //     teamChannel.map((channelMember, index) => {
  //         setMembertemp.map((member) => {
  //             if (member.member._id === channelMember.userId) {
  //                 temp.push(member.member)
  //             }
  //         })
  //         if (index === teamChannel.length - 1) {
  //             return temp;
  //         }
  //     })
  // }
  const extractName = (channelName) => {
    return channelName.slice(channelName.indexOf('_') + 1);
  };
  const retriveAllChannel = async () => {
    try {
      let channelList = await sendBirdService.retrieveListOfChannel().then(
        async (listOfChannels) => {
          return listOfChannels;
        },
        (error) => {
          console.error('error while retrieving channels', error);
        }
      );

      // console.log("channelList", channelList);
      let validList = [];
      let appendMe = [];
      await channelList.map(async (channel) => {
        let id = from === 'main' ? user.user._id : projectDetails._id;
        let type = from === 'main' ? 'main' : 'Project';
        if (channel.name.split('_')[0] === type) {
          if (from === 'main') {
            channel.members.map((mem) => {
              if (mem.userId === id) {
                validList.push(channel);
              }
            });
          } else {
            if (channel.url.split('_')[0] === id) {
              validList.push(channel);
            }
          }
        }
      });
      // console.log("validList", validList);
      validList.map((channel) => {
        sendBirdService.retrieveChannelPreviosMessages(channel).then((prevMessages) => {
          let temp = {};
          if (channel.customType === 'Group') {
            temp = {
              type: channel.customType,
              name: extractName(channel.name),
              channelURL: channel.url,
              membersDetails: channel.members,
              coverUrl: channel.coverUrl,
              lastMessage:
                prevMessages.length > 0 ? prevMessages[prevMessages.length - 1].message : '',
              lastMessageDate:
                prevMessages.length > 0
                  ? moment(prevMessages[prevMessages.length - 1].createdAt).format('LT')
                  : '',
              lastMessageDateToSort:
                prevMessages.length > 0 ? prevMessages[prevMessages.length - 1].createdAt : 0,
              totalGroupMembers: channel.members.length,
              channelInfo: channel
            };
          } else {
            temp = {
              type: channel.customType,
              name: extractName(channel.name),
              channelURL: channel.url,
              membersDetails: channel.members,
              lastMessage:
                prevMessages.length > 0 ? prevMessages[prevMessages.length - 1].message : '',
              lastMessageDate:
                prevMessages.length > 0
                  ? moment(prevMessages[prevMessages.length - 1].createdAt).format('LT')
                  : '',
              lastMessageDateToSort:
                prevMessages.length > 0 ? prevMessages[prevMessages.length - 1].createdAt : 0,
              onlineStatus: 'Offline',
              channelInfo: channel
            };
          }
          appendMe.push(temp);
        });
      });

      let timmer = setInterval(() => {
        if (appendMe.length === validList.length) {
          // console.warn("appendMeappendMe", appendMe);
          appendMe = appendMe.sort((a, b) =>
            a.lastMessageDateToSort > b.lastMessageDateToSort ? -1 : 1
          );
          clearInterval(timmer);
          setCurrentChat(appendMe[0]);
          setDataOfLeftPanel([...appendMe]);
          setLeftPanelLoader(true);
          if (appendMe.length === 0) {
            setNoMessageFlag(true);
          }
        }
      }, 100);
    } catch (e) {
      console.error('error', e);
    }
  };

  const createSendBirdChannel = (membersInChat, name, type) => {
    let channelParams = {};
    channelParams.users = getId(membersInChat);
    channelParams.creator =
      from === 'main' ? JSON.stringify(user.user._id) : JSON.stringify(projectDetails._id);
    channelParams.name = from === 'main' ? 'main_' + name : 'Project_' + name;
    channelParams.customType = type;
    sendBirdService.createOpenGroupChannel(channelParams).then(
      (createdChannel) => {
        retriveAllChannel();
        setShowNewMessage(false); // hide new message modal
        setSelectedMembersToChat({
          groupName: '',
          members: []
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const lookForImage = (item) => {
    let row = item.membersDetails.map((member) => {
      if (member.userId !== user.user._id) {
        if (member.metaData.profileImage) {
          return <img src={member.metaData.profileImage} />;
        } else {
          return (
            <Avatar
              size='30px'
              round='50%'
              name={member.metaData.firstName + ' ' + member.metaData.lastName}
            />
          );
        }
      }
    });
    return row;
  };

  const getNameSingleChat = (chat) => {
    let temp = '';
    chat.membersDetails.map((member) => {
      if (member.userId !== user.user._id) {
        temp = member.metaData.firstName ? member.metaData.firstName : 'Login your account';
      }
    });
    return temp;
  };
  const generateLeftPanelData = () => {
    let rows = dataOfLeftPanel.map((item, index) => {
      return (
        <div className='member-row' key={'messages-left' + index}>
          <RowChatPanel
            className='d-flex flex-row'
            onClick={() => setCurrentChat(item)}
            selected={currentChat === item}
          >
            <div className='chat-panel-image'>
              {item.type === 'Group' ? (
                <img src={'https://cdn3.iconfinder.com/data/icons/e-commerce-8/91/group-512.png'} />
              ) : (
                lookForImage(item)
              )}
            </div>
            <div className='d-flex flex-column chat-detail-user'>
              <div className='d-flex chat-detail-name justify-content-between'>
                <p
                  className='m-0'
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    height: ' 12px'
                  }}
                >
                  {item.type === 'Group' ? item.name : getNameSingleChat(item)}
                </p>
                <p className='m-0 font-weight-light'>{item.lastMessageDate}</p>
              </div>
              <div className='chat-last-message'>{item.lastMessage}</div>
            </div>
          </RowChatPanel>
        </div>
      );
    });
    return rows;
  };
  const toggleHover = (show) => {
    setOnHovergGroupMembers(show);
  };
  const getOnHoverData = () => {
    let row = (
      <ActioBox>
        <TooltipUp />
        <ul>
          {currentChat.membersDetails.map((member, index) => (
            <li key={'members-present' + index}>
              {member.metaData.profileImage ? (
                <img src={member.metaData.profileImage} />
              ) : (
                <Avatar
                  size='30px'
                  round='50%'
                  name={member.metaData.firstName + ' ' + member.metaData.lastName}
                />
              )}
              <p className='team-member-name'>
                {member.metaData.firstName ? member.metaData.firstName : 'Login your account.'}
              </p>
            </li>
          ))}
        </ul>
      </ActioBox>
    );

    return row;
  };

  const checkStatus = (status, channel) => {
    let temp = currentChat;
    temp.onlineStatus = status === 'online' ? 'Online' : 'Offline';
    setCurrentChat(temp);
  };

  return (
    <>
      {from === 'main' && <div className='messages-divider'></div>}
      <div className='row h-100 m-0'>
        <LeftTeamPanel className='col-md-3 h-100' from={from}>
          {from === 'main' && <p className='message-header'>{t('messages.header')}</p>}
          <div className='team-list-chat'>
            {noMessageFlag ? (
              <p
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '10px',
                  letterSpacing: '0.9px',
                  textAlign: 'left',
                  color: '#21a8df',
                  margin: '10px 0'
                }}
              >
                {t('messages.no_message')}
              </p>
            ) : leftPanelLoader ? (
              generateLeftPanelData()
            ) : (
              <Loader />
            )}
          </div>
          <div className='footer-new-message'>
            <Button
              text={t('messages.new_message_button')}
              disabled={false}
              fullWidth='100%'
              font={from === 'main' ? '13px' : '10px'}
              onClick={showTeamMembers}
            />
          </div>
        </LeftTeamPanel>
        <div className='col-md-9 h-100' style={{ paddingTop: '10%' }}>
          {currentChat && (
            <>
              <HeaderChatPanel>
                <div className='data-user-panel'>
                  <p className='chat-user-image'>
                    {currentChat.type === 'Group' ? (
                      <img
                        src={'https://cdn3.iconfinder.com/data/icons/e-commerce-8/91/group-512.png'}
                      />
                    ) : (
                      lookForImage(currentChat)
                    )}
                  </p>
                  <p className='chat-user-name'>
                    {currentChat.type === 'Group'
                      ? currentChat.name
                      : getNameSingleChat(currentChat)}
                  </p>
                  <p className='chat-user-divider'></p>
                  <p
                    className='chat-user-details hover'
                    onMouseEnter={() => toggleHover(true)}
                    onMouseLeave={() => toggleHover(false)}
                  >
                    {currentChat.type === 'Group'
                      ? currentChat.totalGroupMembers + ' Members'
                      : currentChat.onlineStatus}
                  </p>
                  {onHovergGroupMembers && currentChat.type === 'Group' && getOnHoverData()}
                </div>
              </HeaderChatPanel>
              <Message
                parentType='project_id'
                parentId={projectDetails._id}
                sendBirdChannelURL={currentChat.channelURL}
                group={true}
                checkStatus={checkStatus}
              ></Message>
            </>
          )}
        </div>
      </div>
      {showNewMessage && (
        <Modal show={showNewMessage} close={closeDeleteModalHandler}>
          <div style={{ marginTop: '10%' }}>
            <Title>{t('messages.select_member')}</Title>
            <div
              style={{
                width: ' 30vw',
                height: '38vh',
                position: 'relative'
              }}
            >
              <div>
                {selectedMembersToChat.members.length > 1 && (
                  <input
                    style={{
                      textAlign: 'center',
                      border: '0',
                      borderBottom: '1px solid gray'
                    }}
                    placeholder={t('messages.group_name')}
                    type='text'
                    onKeyUp={(event) =>
                      setSelectedMembersToChat({
                        ...selectedMembersToChat,
                        groupName: event.target.value
                      })
                    }
                  />
                )}
              </div>
              {!showTeamDropdown && (
                <div className='row' style={{ height: '17vh', overflow: 'scroll' }}>
                  {selectedMembersToChat.members.map((team) => (
                    <div
                      className='col-md-6'
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        marginTop: '20px',
                        height: 'fit-content'
                      }}
                    >
                      {team.member.profileImage ? (
                        <img
                          src={team.member.profileImage}
                          style={{
                            width: '30px',
                            borderRadius: '50%',
                            height: '30px'
                          }}
                        />
                      ) : (
                        <Avatar
                          size='30px'
                          round='50%'
                          name={team.member.firstName + ' ' + team.member.lastName}
                        />
                      )}
                      <div style={{ margin: 'auto 10px' }}>{team.member.firstName}</div>
                    </div>
                  ))}
                </div>
              )}
              <div
                style={{
                  width: ' 40%',
                  position: 'absolute',
                  top: '10%',
                  left: '25%'
                }}
              >
                {showTeamDropdown && (
                  <TeamDropdown
                    close={() => setShowTeamDropdown(false)}
                    selectedTeam={selectedTeamMembers}
                    multiple={true}
                    teamMembersOptions={teamMembers}
                    selectedMember={[]}
                  />
                )}
              </div>
              <div
                style={{
                  position: 'absolute',
                  bottom: '-25px',
                  width: '100%'
                }}
              >
                {!showTeamDropdown && (
                  <Button
                    text={t('messages.select_team')}
                    onClick={() => setShowTeamDropdown(true)}
                    fullWidth='max-content'
                  ></Button>
                )}

                <Button
                  text={
                    selectedMembersToChat.members.length > 1
                      ? t('messages.add_group')
                      : t('messages.add_member')
                  }
                  type='submit'
                  onClick={createChat}
                  disabled={checkValidation()}
                  fullWidth='max-content'
                ></Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default withTranslation('common')(ProjectMessagesPanel);
