import React from 'react';

const AddIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    id='Group_9'
    width='20'
    height='20'
    data-name='Group 9'
    viewBox='0 0 24.5 24.5'
  >
    <defs>
      <style></style>
    </defs>
    <circle id='Oval' cx='12.25' cy='12.25' r='12.25' style={{ fill: '#f8f8f8' }} />
    <path
      id='Path_4'
      d='M0 0l7 7'
      style={{
        fill: 'none',
        stroke: '#e2e2ea',
        strokeLinecap: 'round',
        strokeMiterlimit: '10',
        strokeWidth: '2px'
      }}
      data-name='Path 4'
      transform='rotate(45 -2.864 18.864)'
    />
    <path
      id='Path_4_Copy'
      d='M0 0l-7 7'
      style={{
        fill: 'none',
        stroke: '#e2e2ea',
        strokeLinecap: 'round',
        strokeMiterlimit: '10',
        strokeWidth: '2px'
      }}
      data-name='Path 4 Copy'
      transform='rotate(45 -6.364 27.314)'
    />
  </svg>
);

export default AddIcon;
