import Cookies from 'universal-cookie';
const cookies = new Cookies();
const axios = require('axios').default;
const DOMAIN = 'localhost';
const globals = require('./../_helpers/globals');
const URL = globals.domain;

const axiosInstance = axios.create({
  headers: {
    Authorization: 'Bearer ' + getCookie()
  }
});

function getCookie() {
  return localStorage.getItem('UID');
  // return cookies.get('UID')
}

function newInquiry(data) {
  let headers = { Authorization: 'Bearer ' + getCookie() };
  return axios.create({ headers: headers }).post(`${URL}inquiry`, data);
}

function inquirySchedule(data) {
  return axios
    .create({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .post(`${URL}inquiry`, data);
}

function getInquiries(params) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .get(`${URL}inquiry/all`, { params: params });
}

function deleteInquiry(params) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .delete(`${URL}inquiry`, { params: params });
}

function editInquiry(data) {
  return axios
    .create({
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .put(`${URL}inquiry`, data);
}

function getFiles(params) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .get(`${URL}inquiry/file/all`, { params: params });
}

function uploadFile(data) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .post(`${URL}inquiry/file`, data);
}

function getSignedInquiries() {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .get(`${URL}inquiry/signed`);
}
function getAllClients() {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .get(`${URL}inquiry/clients`);
}
export const inquiryService = {
  inquirySchedule,
  getInquiries,
  deleteInquiry,
  editInquiry,
  newInquiry,
  getFiles,
  uploadFile,
  getSignedInquiries,
  getAllClients
};
