import React from 'react';
import styled from 'styled-components';

let MainWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #fbfbfd;
  display: flex;
  justify-content: center;
`;

let CardWrapper = styled.div`
  margin: auto;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 0.5px 5.5px 0 rgba(0, 0, 0, 0.09);
  border: solid 0.5px #eaedf3;
  background-color: #ffffff;
  padding: 30px;
  font-family: Montserrat;
`;

const CardLanding = (props) => (
  <MainWrapper>
    <CardWrapper className='col-md-4'>{props.children}</CardWrapper>
  </MainWrapper>
);

export { CardLanding };
