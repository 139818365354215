import React from 'react';
import { Spinner } from './Spinner';

const Loader = () => (
  <div
    className='d-flex justify-content-center'
    style={{ height: '-webkit-fill-available', width: '100%' }}
  >
    <Spinner visible />
  </div>
);

export { Loader };
