import React from 'react';
//orange
const TimeLeft = () => (
  <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.39093 14.5C11.967 14.5 14.8619 11.5876 14.8619 8C14.8619 4.41244 11.967 1.5 8.39093 1.5C4.81481 1.5 1.91992 4.41244 1.91992 8C1.91992 11.5876 4.81481 14.5 8.39093 14.5Z'
      stroke='#ff974a'
    />
    <path
      d='M8.39103 3.85307V8L6.41699 9.7689'
      stroke='#ff974a'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default TimeLeft;
