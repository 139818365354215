import React, { useCallback, useEffect, useState, useContext } from 'react';
import Iframe from 'react-iframe';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { UserContext } from '../../common/context/UserContext';
let IframeWrapper = styled.div`
  height: 100%;
  padding: 30px;
  div.feature-block {
    height: 100%;
    margin: 0;
    margin-top: 30px;
  }
`;
let HeaderTitleModal = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.61;
  letter-spacing: 0.9px;
  text-align: left;
  color: #21a8df;
  @media (min-width: 1600px) {
    font-size: 17px;
  }
`;
const BusinessTools = ({ t, i18n, ...props }) => {
  const [{ user, webflowLinks }, dispatch] = useContext(UserContext);
  return (
    <>
      <IframeWrapper>
        <HeaderTitleModal>BUSINESS TOOLS</HeaderTitleModal>
        <div className='row'>
          <div className='col-md-3'>
            <Iframe url={webflowLinks.jp.innerBusinessCases} width='100%' height='100%' />
          </div>
          <div className='col-md-3'>
            <Iframe url={webflowLinks.jp.innerBusinessTemplate} width='100%' height='100%' />
          </div>
          <div className='col-md-3'>
            <Iframe url={webflowLinks.jp.innerProfessional} width='100%' height='100%' />
          </div>
        </div>
        <div className='row feature-block'>
          <Iframe url={webflowLinks.jp.featuredBusinessCases} width='100%' height='100%' />
        </div>
      </IframeWrapper>
    </>
  );
};
export default withTranslation('common')(BusinessTools);
