import React from 'react';

const ShareIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
    <defs></defs>
    <g id='Group'>
      <path
        id='Shape'
        d='M1.766 18.227a5.837 5.837 0 0 1-1.721-3.251 3.727 3.727 0 0 1 .95-3.191l4.39-4.374a3.588 3.588 0 0 1 3.2-.946 5.878 5.878 0 0 1 3.263 1.714 7.712 7.712 0 0 1 1.067 1.36.875.875 0 0 1-1.543.827 4.1 4.1 0 0 0-.771-1 4.2 4.2 0 0 0-2.25-1.188 1.987 1.987 0 0 0-1.661.414L2.3 12.966a1.863 1.863 0 0 0-.415 1.655 4.174 4.174 0 0 0 1.186 2.246c1.246 1.241 3.085 1.6 3.916.768l2.314-2.3a.727.727 0 0 1 1.186.118.854.854 0 0 1 0 1.241L8.173 19a3.274 3.274 0 0 1-2.491 1 5.806 5.806 0 0 1-3.916-1.773zm8.9-3.842a5.566 5.566 0 0 1-3.382-1.655A6.821 6.821 0 0 1 6.1 11.016a.824.824 0 0 1 .416-1.183.83.83 0 0 1 1.186.414 3.91 3.91 0 0 0 .831 1.183 3.727 3.727 0 0 0 2.314 1.122 2.32 2.32 0 0 0 1.9-.59l4.865-4.847a2.3 2.3 0 0 0 .588-1.891 3.7 3.7 0 0 0-1.127-2.3A3.723 3.723 0 0 0 14.759 1.8a2.32 2.32 0 0 0-1.9.59l-2.847 2.834a.879.879 0 0 1-1.245-1.241l2.848-2.838A3.917 3.917 0 0 1 14.937.023a5.572 5.572 0 0 1 3.381 1.654 5.535 5.535 0 0 1 1.661 3.37 4.272 4.272 0 0 1-1.127 3.369l-4.865 4.847a4.1 4.1 0 0 1-2.848 1.123z'
        style={{ fill: '#21a8df' }}
      />
    </g>
  </svg>
);

export default ShareIcon;
