import React, { useCallback, useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import * as moment from 'moment';
import { PageHeader, ActionPanel, Loader } from '../../common';
import { authenticationService } from '../../_services';
import OverdueIcon from '../../public/icons/OverdueIcon';
import StatusIcon from '../../public/icons/StatusIcon';
import MessageIcon from '../../public/icons/MessageIcon';

import ThreeDots from '../../public/icons/ThreeDots';
import Avatar from 'react-avatar';
import { withTranslation } from 'react-i18next';
import 'moment/locale/ja';
import 'moment/locale/en-ca';
let Row = styled.div`
  height: 33.333%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  border-radius: 15px;
  margin: 10px 0;
  p {
    margin: 0;
  }
  div.alerts-row {
    display: flex;
    flex-direction: row;
    div.alerts-image {
      width: 60px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
    }
    div.alerts-details {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      padding: 0 20px 0 0;
      div.alerts-title {
        margin: 5px 0;
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        p.detail {
          font-family: Montserrat;
          font-size: 12px;
          font-weight: 600;
          line-height: 1.32;
          text-align: left;
          color: #4b4d4e;
          width: 90%;
          height: auto;
          overflow: hidden;
        }
      }
      p.alerts-timeleft {
        text-align: left;
        font-family: Montserrat;
        font-size: 12px;
        line-height: 1.25;
        color: #92929d;
        font-weight: 200;
        @media (min-width: 1600px) {
          font-size: 13px;
        }
      }
    }
  }
`;

let NoNotification = styled.div`
  font-weight: 600;
  text-align: left;
  font-size: 15px;
  margin: 30px;
  font-family: Montserrat;
`;

const AlertsDashboard = ({ t, i18n, ...props }) => {
  moment.locale('ja');
  const [notification, setNotification] = useState();
  const [noNotification, setNoNotification] = useState(false);

  useEffect(() => {
    setNotificationData();
  }, []);

  const setNotificationData = () => {
    authenticationService.getNotification().then(
      (response) => {
        setNotification(response.data.result);
        if (response.data.result.length === 0) {
          setNoNotification(true);
        } else {
          setNoNotification(false);
        }
      },
      (err) => {
        console.error(err);
        setNoNotification(true);
      }
    );
  };

  const triggerAction = (field) => {
    if (field.isRead) {
      if (field.project) {
        props.history.push(`/home/project_details?project_id=${field.project}`);
      } else if (field.inquiry) {
        props.history.push(`/home/inquiry_details?inquiry_id=${field.inquiry}`);
      }
    } else {
      let data = {
        notificationId: field._id
      };
      authenticationService.readNotification(data).then(
        (response) => {
          if (field.notificationType === 'status') {
            if (field.project) {
              props.history.push(`/home/project_details?project_id=${field.project}`);
            } else if (field.inquiry) {
              props.history.push(`/home/inquiry_details?inquiry_id=${field.inquiry}`);
            }
          } else if (field.notificationType === 'deadline') {
            props.history.push(`/home/project_details?project_id=${field.projects}`);
          } else if (field.notificationType === 'message') {
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
    // if (type === "Open") {
    //   if (field.projectCompleted) {
    //     props.history.push(`/home/project_details?project_id=${field._id}`);
    //   } else {
    //   }
    // }
  };
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexGrow: '1',
          padding: '50px 25px 0 25px'
        }}
      >
        <PageHeader text={t('common.notification')} />
      </div>
      <div className=' m-0' style={{ padding: '10px 30px 30px 30px' }}>
        {notification &&
          notification.map((alertDetails, index) => (
            <Row
              className={!alertDetails.isRead ? 'active-notification hover' : 'hover'}
              onClick={() => {
                triggerAction(alertDetails);
              }}
              index={index}
              key={'row' + index}
            >
              <div className='alerts-row'>
                <div className='alerts-image'>
                  {alertDetails.actor && (
                    <>
                      {alertDetails.actor.profileImage ? (
                        <img src={alertDetails.actor.profileImage} alt='Avatar' />
                      ) : (
                        <Avatar
                          size='30px'
                          round='50%'
                          name={alertDetails.actor.firstName + ' ' + alertDetails.actor.lastName}
                        />
                      )}
                    </>
                  )}
                </div>
                <div className='alerts-details'>
                  <div className='alerts-title'>
                    <p className='detail'>{alertDetails.message}</p>
                    <p>
                      {alertDetails.notificationType === 'status' && <StatusIcon />}
                      {alertDetails.notificationType === 'overdue' && <OverdueIcon />}
                      {alertDetails.notificationType === 'message' && <MessageIcon />}
                    </p>
                  </div>
                  <p className='alerts-timeleft'>
                    {moment(alertDetails.createdAt).startOf('hour').fromNow()}
                    {/* {moment().diff(moment(), "days") * -1}{" "}
                    days ago */}
                  </p>
                </div>
              </div>
            </Row>
          ))}
        {noNotification ? (
          <NoNotification>{t('common.no_notification')}</NoNotification>
        ) : (
          !notification && <Loader />
        )}
      </div>
    </div>
  );
};
export default withTranslation('common')(AlertsDashboard);
