import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import './common.css';
import Close from '../public/icons/Close';
import Find from '../public/icons/Find';
import { withTranslation } from 'react-i18next';

let DropdownSearch = styled.div`
  position: relative;
  div {
    display: flex;
    position: absolute;
    right: 0;
    p.find-icon {
      margin: 0 20px;
    }
    p.done-select-panel {
      margin: 0;
      margin-top: 5px;
    }
  }
`;
let RoundDropdown = styled.input`
    border-radius: 1em;
    box-shadow: inset 0 0 1.5px 0 rgba(0, 0, 0, 0.09);
    background-color: #fbfbfb;
    padding: 5px 35px 5px 10px;
    border:'none'
    font-family: Montserrat;
    font-size: 10.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba(75, 77, 78, 1);
    width: 100%;
    border: 0;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 0px), calc(100% - 15px) calc(1em + 0px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    @media (min-width: 1600px) {
      font-size: 12px;
  }
`;
let InputPanel = styled.div`
  display: grid;
`;
let LabelField = styled.label`
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #4b4d4e;
  margin-bottom: 5px;
  @media (min-width: 1600px) {
    font-size: 12px;
  }
`;
const MultiSelectDropdown = ({ t, ...props }) => {
  // console.log("props.selectedMember", props.selectedMember);

  const [multiple, setMultiple] = useState(props.multiple);
  const [multipleSelection, setMultipleSelection] = useState(props.selectedMember);
  const [options, setOptions] = useState(props.teamMembersOptions);
  const [showMultiSelectDropdown, setShowMultiSelectDropdown] = useState(false);

  const openMultiSelectBox = () => {
    setShowMultiSelectDropdown(true);
    if (props.selectedMember.length > 0) {
      props.selectedMember.map((team) => {
        setTimeout(() => {
          const found = props.teamMembersOptions.some((el) => el.value === team.value);
          if (found) {
            if (document.getElementById('multiple_' + team.value))
              document.getElementById('multiple_' + team.value).classList.add('selected');
          }
        }, 0);
      });
    }
  };

  const filterList = (value) => {
    let filterdList = props.teamMembersOptions.filter(
      (team) => value === '' || team.label.toLowerCase().includes(value.toLowerCase())
    );
    setOptions(filterdList);
  };

  const checkHighlight = (id, member) => {
    // console.log(multipleSelection, member)
    if (multiple) {
      if (multipleSelection.some((el) => el.value === member.value)) {
        let copy = multipleSelection;
        copy = copy.filter((selected) => selected.value != member.value);
        setMultipleSelection(copy);
        document.getElementById(id).classList.remove('selected');
      } else {
        setMultipleSelection([...multipleSelection, ...[member]]);
        document.getElementById(id).classList.add('selected');
      }
    } else {
      props.selectedTeam(member);
    }
  };

  return (
    <>
      <InputPanel className='form-group position-relative'>
        <LabelField htmlFor={props.type}>{props.text}</LabelField>
        {!showMultiSelectDropdown && (
          <RoundDropdown
            value={props.value}
            type='text'
            placeholder={t('common.select_options')}
            name={props.name}
            onClick={() => openMultiSelectBox()}
          />
        )}

        {showMultiSelectDropdown && (
          <>
            <DropdownSearch>
              <div>
                <p className='find-icon'>
                  <Find />
                </p>
                {!multiple ? (
                  <p
                    className='done-select-panel'
                    onClick={() => {
                      props.selectedTeam(props.name, multipleSelection);
                      setShowMultiSelectDropdown(false);
                    }}
                  >
                    <p>{t('common.close')}</p>
                  </p>
                ) : (
                  <p
                    className='done-select-panel'
                    onClick={() => {
                      props.selectedTeam(props.name, multipleSelection);
                      setShowMultiSelectDropdown(false);
                    }}
                  >
                    <p>{t('common.done')}</p>
                  </p>
                )}
              </div>
            </DropdownSearch>
            <input
              type='text'
              placeholder='Search'
              onKeyUp={(event) => filterList(event.target.value)}
              className='select-search-box__search_multi'
            />
            <ul className='select-search-box__options multi-width'>
              {options.length > 0 ? (
                options.map(
                  (member, index) =>
                    member.value && (
                      <li
                        key={'member' + index}
                        onClick={(e) => checkHighlight('multiple_' + member.value, member)}
                        id={'multiple_' + member.value}
                      >
                        <p className='team-member-name'>{member.label}</p>
                      </li>
                    )
                )
              ) : (
                <p className='no-records-found'>{t('common.no_record')}</p>
              )}
            </ul>
          </>
        )}
      </InputPanel>
    </>
  );
};
export default withTranslation('common')(MultiSelectDropdown);
