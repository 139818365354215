import Cookies from 'universal-cookie';

const cookies = new Cookies();
const axios = require('axios').default;
const axiosInstance = axios.create({
  headers: {
    Authorization: 'Bearer ' + getCookie()
  }
});

const globals = require('./../_helpers/globals');
const URL = globals.domain;

export const clientService = {
  getInquiries,
  newInquiry,
  editInquiry,
  deleteInquiry,
  getInquiry,
  inquiryStatusUpdate,
  getInquiryMessages,
  newMessageInsert,
  getProjects,
  getProject,
  getProjectMessages,
  newMessageInsertProject,
  newInquiryChannel,
  newProjectChannel,
  getFiles,
  uploadFile,
  getProjectTasks,
  createProjectTask,
  deleteProjectTask,
  newChannel,
  uploadProposal,
  dashboardAlerts,
  newChannelMainMessages,
  deleteMessagesMain
};

function deleteProjectTask(params) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .delete(`${URL}project/task`, { params: params });
}

function createProjectTask(data) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .post(`${URL}project/task`, data);
}

function getProjectTasks(params) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .get(`${URL}project/task/all`, { params: params });
}

function getProjects(params) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .get(`${URL}project/all`, { params: params });
}

function getProjectMessages(params) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .get(`${URL}project/message/all`, { params: params });
}

function newMessageInsertProject(data) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .post(`${URL}project/message`, data);
}

function getProject(params) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .get(`${URL}project`, { params: params });
}

function newProjectChannel(data) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .post(`${URL}project/channel`, data);
}

function getInquiries() {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .get(`${URL}inquiry/all`);
}

function getInquiryMessages(params) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .get(`${URL}inquiry/message/all`, { params: params });
}

function newMessageInsert(data) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .post(`${URL}inquiry/message`, data);
}

function uploadProposal(data, params) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .post(`${URL}inquiry/upload-proposal`, data, { params: params });
}

function getInquiry(params) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .get(`${URL}inquiry`, { params: params });
}

function inquiryStatusUpdate(params) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .get(`${URL}inquiry/status`, { params: params });
}

function newInquiry(data) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .post(`${URL}inquiry`, data);
}

function newInquiryChannel(data) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .post(`${URL}inquiry/channel`, data);
}

function editInquiry(data) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .put(`${URL}inquiry`, data);
}

function deleteInquiry(params) {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .delete(`${URL}inquiry`, { params: params });
}

function getCookie() {
  return localStorage.getItem('UID');
  // return cookies.get('UID')
}

function getFiles(params) {
  if (params.parent === 'inquiry_id') {
    return axios
      .create({
        headers: {
          Authorization: 'Bearer ' + getCookie()
        }
      })
      .get(`${URL}inquiry/file/all`, { params: params });
  } else {
    return axios
      .create({
        headers: {
          Authorization: 'Bearer ' + getCookie()
        }
      })
      .get(`${URL}project/file/all`, { params: params });
  }
}

function uploadFile(data, parent) {
  if (parent === 'inquiry_id') {
    return axios
      .create({
        headers: {
          Authorization: 'Bearer ' + getCookie()
        }
      })
      .post(`${URL}inquiry/file`, data);
  } else {
    return axios
      .create({
        headers: {
          Authorization: 'Bearer ' + getCookie()
        }
      })
      .post(`${URL}project/file`, data);
  }
}

function newChannel(data) {
  if (data.parent == 'inquiry_id') {
    return axios
      .create({
        headers: {
          Authorization: 'Bearer ' + getCookie()
        }
      })
      .post(`${URL}inquiry/channel`, data);
  } else if (data.parent == 'project_id') {
    return axios
      .create({
        headers: {
          Authorization: 'Bearer ' + getCookie()
        }
      })
      .post(`${URL}project/channel`, data);
  } else if (data.parent == 'message_id') {
    return axios
      .create({
        headers: {
          Authorization: 'Bearer ' + getCookie()
        }
      })
      .post(`${URL}users/message/channel`, data);
  }
}

function dashboardAlerts() {
  return axios
    .create({
      headers: {
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .get(`${URL}users/client/home`);
}

function newChannelMainMessages(data) {
  return axios
    .create({
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .post(`${URL}users/sendBird`, data);
}

function deleteMessagesMain(data) {
  return axios
    .create({
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getCookie()
      }
    })
    .delete(`${URL}users/sendBird`, data);
}
