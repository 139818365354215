export const InquiryConfig = [
  {
    config: {
      name: 'タイトル',
      sort: true,
      sortType: true,
      type: 'string&link',
      width: '15%',
      fieldName: 'title'
    }
  },
  {
    config: {
      name: '背景',
      sort: true,
      sortType: true,
      type: 'string&link',
      width: '30%',
      fieldName: 'background'
    }
  },
  {
    config: {
      name: 'ステータス',
      sort: true,
      sortType: true,
      type: 'string',
      width: '10%',
      fieldName: 'status'
    }
  },
  {
    config: {
      name: '連絡先',
      sort: true,
      sortType: true,
      type: 'string',
      width: '12%',
      fieldName: 'primaryContact'
    }
  },
  {
    config: {
      name: 'ファイル',
      sort: true,
      sortType: true,
      type: 'string',
      width: '8%',
      fieldName: 'files'
    }
  },
  {
    config: {
      name: 'メッセージ回数',
      type: 'message%count',
      width: '5%',
      fieldName: 'messageCount'
    }
  },
  {
    config: {
      name: '最終メッセージ',
      type: 'message%date',
      width: '8%',
      fieldName: 'lastMessageDate'
    }
  },
  {
    config: {
      name: '',
      type: 'string&link',
      width: '7%',
      fieldName: 'projectOverview'
    }
  },
  {
    config: {
      name: '',
      type: 'edit',
      width: '5%',
      fieldName: 'action'
    }
  }
];

export const InquiryDetailFilesConfig = [
  {
    config: {
      name: 'ファイル名',
      sort: true,
      sortType: true,
      type: 'string',
      width: '40%',
      fieldName: 'filename'
    }
  },
  {
    config: {
      name: 'アップロード日',
      sort: true,
      sortType: true,
      type: 'string',
      width: '30%',
      fieldName: 'uploadedon'
    }
  },
  {
    config: {
      name: '',
      type: 'download',
      width: '10%',
      fieldName: 'download'
    }
  },
  {
    config: {
      name: '',
      type: 'share',
      width: '10%',
      fieldName: 'share'
    }
  }
];

export const ProjectConfig = [
  {
    config: {
      name: 'プロジェクト名',
      sort: true,
      sortType: true,
      type: 'string&link',
      width: '15%',
      fieldName: 'name'
    }
  },
  {
    config: {
      name: 'カテゴリー',
      sort: true,
      sortType: true,
      type: 'string&link',
      width: '10%',
      fieldName: 'category'
    }
  },
  {
    config: {
      name: '進捗',
      sort: true,
      sortType: true,
      type: 'string',
      width: '13%',
      fieldName: 'percentComplete'
    }
  },
  {
    config: {
      name: 'ステータス',
      sort: true,
      sortType: true,
      type: 'string',
      width: '8%',
      fieldName: 'status'
    }
  },
  {
    config: {
      name: 'アラート',
      sort: true,
      sortType: true,
      type: 'string',
      width: '8%',
      fieldName: 'alerts'
    }
  },
  {
    config: {
      name: '期限',
      sort: true,
      sortType: true,
      type: 'date',
      width: '8%',
      fieldName: 'dueDate'
    }
  },
  {
    config: {
      name: 'クライアント',
      type: 'string',
      width: '10%',
      fieldName: 'client'
    }
  },
  {
    config: {
      name: 'チームメンバー',
      type: 'string',
      width: '10%',
      fieldName: 'team'
    }
  },
  {
    config: {
      name: 'メッセージ回数',
      type: 'message%count',
      width: '5%',
      fieldName: 'messageCount'
    }
  },
  {
    config: {
      name: '最終メッセージ',
      type: 'message%date',
      width: '8%',
      fieldName: 'lastMessageDate'
    }
  },
  {
    config: {
      name: '',
      type: 'edit',
      width: '5%',
      fieldName: 'action'
    }
  }
];

export const ProjectTasksConfig = [
  {
    config: {
      name: '',
      type: 'checkbox',
      width: '5%',
      fieldName: 'checkbox'
    }
  },
  {
    config: {
      name: 'タスク名',
      sort: true,
      sortType: true,
      type: 'string',
      width: '15%',
      fieldName: 'taskname'
    }
  },
  {
    config: {
      name: 'カテゴリー',
      sort: true,
      sortType: true,
      type: 'string',
      width: '15%',
      fieldName: 'category'
    }
  },
  {
    config: {
      name: '担当者',
      sort: true,
      sortType: true,
      type: 'string&image',
      width: '15%',
      fieldName: 'assignee'
    }
  },
  {
    config: {
      name: '依頼者',
      sort: true,
      sortType: true,
      type: 'string&image',
      width: '15%',
      fieldName: 'assigneedBy'
    }
  },
  {
    config: {
      name: '開始日',
      sort: true,
      sortType: true,
      type: 'date',
      width: '10%',
      fieldName: 'startDate'
    }
  },
  {
    config: {
      name: '終了日',
      sort: true,
      sortType: true,
      type: 'date',
      width: '10%',
      fieldName: 'dueDate'
    }
  },
  {
    config: {
      name: 'ステータス',
      sort: true,
      sortType: true,
      type: 'string',
      width: '10%',
      fieldName: 'status'
    }
  },
  {
    config: {
      name: '',
      type: 'close',
      width: '2.5%',
      fieldName: 'close'
    }
  },
  {
    config: {
      name: '',
      type: 'filter%option',
      width: '2.5%',
      fieldName: 'filter'
    }
  }
];

export const ProjectFilesConfig = [
  {
    config: {
      name: 'ファイル名',
      sort: true,
      sortType: true,
      type: 'string',
      width: '30%',
      fieldName: 'fileName'
    }
  },
  {
    config: {
      name: '所有者',
      sort: true,
      sortType: true,
      type: 'string&image',
      width: '20%',
      fieldName: 'owner'
    }
  },
  {
    config: {
      name: 'アップロード日',
      sort: true,
      sortType: true,
      type: 'date',
      width: '17%',
      fieldName: 'uploadedOn'
    }
  },
  // {
  //     "config":{
  //         "name":"Last Modified",
  //         "sort":true,
  //         "sortType":true,
  //         "type": "date",
  //         "width":"12.5%",
  //         "fieldName": "lastmodified"
  //     }
  // },
  {
    config: {
      name: '',
      type: 'download',
      width: '14%',
      fieldName: 'download'
    }
  },
  {
    config: {
      name: '',
      type: 'share',
      width: '14%',
      fieldName: 'share'
    }
  },
  {
    config: {
      name: '',
      type: 'close',
      width: '10%',
      fieldName: 'close'
    }
  }
];

export const ProjectTeamConfig = [
  {
    config: {
      name: '名前',
      sort: true,
      sortType: true,
      type: 'string&image',
      width: '25%',
      fieldName: 'name'
    }
  },
  {
    config: {
      name: 'ステータス',
      sort: true,
      sortType: true,
      type: 'string',
      width: '25%',
      fieldName: 'status'
    }
  },
  {
    config: {
      name: '日付',
      sort: true,
      sortType: true,
      type: 'date',
      width: '25%',
      fieldName: 'addedOn'
    }
  },
  {
    config: {
      name: '追加者',
      sort: true,
      sortType: true,
      type: 'string&image',
      width: '25%',
      fieldName: 'addedBy'
    }
  }
];

export const ProfessionalTasksConfig = [
  {
    config: {
      name: '',
      type: 'checkbox',
      width: '5%',
      fieldName: 'checkbox'
    }
  },
  {
    config: {
      name: 'タスク名',
      sort: true,
      sortType: true,
      type: 'string',
      width: '15%',
      fieldName: 'taskName'
    }
  },
  {
    config: {
      name: 'カテゴリー',
      sort: true,
      sortType: true,
      type: 'string',
      width: '15%',
      fieldName: 'category'
    }
  },
  {
    config: {
      name: 'プロジェクト',
      sort: true,
      sortType: true,
      type: 'string',
      width: '15%',
      fieldName: 'project'
    }
  },
  {
    config: {
      name: '委任者',
      sort: true,
      sortType: true,
      type: 'string&image',
      width: '15%',
      fieldName: 'assigneedBy'
    }
  },
  {
    config: {
      name: '開始日',
      sort: true,
      sortType: true,
      type: 'date',
      width: '15%',
      fieldName: 'startdate'
    }
  },
  {
    config: {
      name: '期限',
      sort: true,
      sortType: true,
      type: 'date',
      width: '15%',
      fieldName: 'duedate'
    }
  },
  {
    config: {
      name: 'ステータス',
      sort: true,
      sortType: true,
      type: 'string',
      width: '15%',
      fieldName: 'status'
    }
  },
  {
    config: {
      name: '',
      type: 'filter%option',
      width: '5%',
      fieldName: 'filter'
    }
  }
];
