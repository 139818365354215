import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

let BackText = styled.p`
  font-family: Montserrat;
  font-size: 11px;
  line-height: 1.29;
  color: #4b4d4e;
  text-decoration: underline;
  margin: 7px 5px;
  @media (min-width: 1600px) {
    font-size: 12px;
  }
`;
const BackToOption = ({ text, link, t }) => (
  <div className='row m-0'>
    <p className='m-0' style={{ alignSelf: 'center' }}>
      &#8592;
    </p>
    <Link to={link}>
      <BackText>
        {t('universal_actions.back_to')} {text}
      </BackText>
    </Link>
  </div>
);

export default withTranslation('common')(BackToOption);
