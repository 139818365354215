import React, { useState, useCallback } from 'react';
import { authenticationService } from '../_services';
import { Link } from 'react-router-dom';
import { Input, Button, CardLanding } from '../common';
import { LoginRegisterHeader } from '../common/LoginRegisterHeader';
import styled from 'styled-components';
import validator from 'validator';
import { wrapComponent } from 'react-snackbar-alert';
import { withTranslation } from 'react-i18next';

let LoginForm = styled.form`
  margin: 10% 0;
`;
let SignUp = styled.div`
  margin-top: 10%;

  div {
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    margin-top: 10%;
  }
`;

let BackButton = styled.button`
  bottom: 0;
  border: 0;
  background: none;
  left: 20px;
  font-family: Montserrat;
  font-size: 11px;
  line-height: 1.3;
  color: #4b4d4e;
  text-decoration: underline;
  @media (min-width: 1600px) {
    font-size: 12px;
  }
`;
const ResetPage = wrapComponent(({ t, createSnackbar, history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [credentials, setCredentials] = useState({ email: '' });
  const [errorFlag, setErrorFlag] = useState({
    email: ''
  });

  const submit = useCallback(async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (credentials.email && validator.isEmail(credentials.email)) {
      let data = {
        email: credentials.email
      };
      await authenticationService
        .sendResetLink(data)
        .then((response) => {
          showSnackbar(t('login.reset_link'), 'info');
          setTimeout(() => {
            history.push('/login');
          }, 100);
        })
        .catch((error) => {
          setIsLoading(false);
          if (error && error.response && error.response.data) {
            error = error.response.data.error;
            if (error === 'user not found') {
              error = t('login.no_user');
            }
            showSnackbar(error, 'error');
          }
        });
    } else {
      setErrorFlag({ email: t('reset_password.enter_email') });
      setIsLoading(false);
    }
  });

  const showSnackbar = (msg, theme) => {
    createSnackbar({
      theme,
      message: msg,
      timeout: 4000,
      dismissable: false,
      pauseOnHover: true,
      progressBar: false,
      sticky: false
    });
  };

  const fieldChange = useCallback((event) => {
    let data = {};
    data[event.target.name] = event.target.value;
    setCredentials({ ...credentials, ...data });
    setErrorFlag({ email: '' });
  });

  return (
    <CardLanding>
      <LoginRegisterHeader text={t('login.reset_header')} />
      <LoginForm name='form' onSubmit={submit}>
        <Input
          text={t('login.email')}
          error={errorFlag.email}
          type='text'
          name='email'
          value={credentials.email}
          onChange={fieldChange}
          placeholder={t('register.email_placeholder')}
        />
        <SignUp>
          {!isLoading && (
            <Button
              text={t('universal_actions.continue')}
              fullWidth={'40%'}
              disabled={!credentials.email}
            />
          )}
          {isLoading && (
            <div class='spinner-grow' role='status' style={{ display: 'flex', margin: 'auto' }}>
              <span class='sr-only'>Loading...</span>
            </div>
          )}
          <div className='form-group'>
            <BackButton className='button icon-left'>
              <Link to='/login' className='link-text'>
                &#8592; {t('universal_actions.back')}
              </Link>
            </BackButton>
          </div>
        </SignUp>
      </LoginForm>
    </CardLanding>
  );
});

export default withTranslation('common')(ResetPage);
