import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { ActionPanel } from './ActionPanel';
import ThreeDots from '../public/icons/ThreeDots';
import ShareIcon from '../public/icons/Share';
import Download from '../public/icons/Download';
import Attachments from '../public/icons/Attachments';
import TimeLeft from '../public/icons/TimeLeft';
import { TeamMembersIcon } from './TeamMembersIcon';
import FilterIcon from '../public/FilterIcon';
import Close from '../public/icons/Close';
import ProjectBar from './ProjectBar';
import { UserContext } from '../common/context/UserContext';
import RedTimeLeft from '../public/icons/RedTimeLeft';
import GrayTimeLeft from '../public/icons/GrayTimeLeft';
import Avatar from 'react-avatar';
import moment from 'moment';
import { wrapComponent } from 'react-snackbar-alert';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { withTranslation } from 'react-i18next';
import 'moment/locale/ja';
import 'moment/locale/en-ca';
let TableRow = styled.div`
  display: flex;
  height: 50px;
  object-fit: contain;
  border-radius: 12px;
  background-color: ${(props) => (props.index % 2 == 0 ? 'rgb(248, 248, 248)' : '#ffffff')};
  border: ${(props) => (props.index % 2 == 0 ? '0' : 'solid 0.5px #f8f8f8')};
  margin: 10px;
`;
let SortArrow = styled.i`
  margin: 2px;
  border: solid #4b4d4e;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
  ${({ type }) =>
    type === 'Up'
      ? `
            transform: rotate(-135deg);
            margin: 2px 2px 0px 5px;
            `
      : `
            transform: rotate(45deg);
            margin: 2px 2px 2px 5px;
            `}
`;
let TableHeader = styled.p`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #4b4d4e;
  width: fit-content;
  float: ${(props) => (props.float ? 'right' : 'left')};
  margin: ${(props) => (props.float ? '0' : '0 0 0 10px')};
  :hover {
    cursor: pointer;
  }
  @media (min-width: 1600px) {
    font-size: 13px;
  }
`;
let TableHeaderWrapper = styled.div`
  width: ${(props) => props.width};
`;
let RespectiveRowDetail = styled.div`
  width: ${(props) => props.width};
  padding: 10px;
  font-family: Montserrat;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.05px;
  text-align: left;
  color: #4b4d4e;
  text-align: center;
  vertical-align: middle;
  position: relative;
  margin: auto 0;
  text-align: left;
  @media (min-width: 1600px) {
    font-size: 12px;
  }
`;
let EditBox = styled.div`
  :hover, li: hover {
    cursor: pointer;
  }
`;
let TimeLeftPanel = styled.div`
  position: relative;
  width: fit-content;
  height: 20px;
  div.background-panel {
    position: absolute;
    width: 100%;
    height: 20px;
    padding: 2px;
    background-color: ${(props) =>
      props.value > 7 ? '#4B4D4E' : props.value > 0 ? '#ff974a' : '#FC5A5A'};
    opacity: 0.1;
    border-radius: 2.5px;
  }
  p.icon-timeleft {
    position: absolute;
    margin: 0;
    padding: 2px;
  }
  p.text-timeleft {
    margin-left: 20px;
    padding: 4px 4px 4px 0;
    font-family: Montserrat;
    font-size: 11px;
    line-height: 1.29;
    letter-spacing: 0.05px;
    text-align: left;
    color: ${(props) => (props.value > 7 ? '#4B4D4E' : props.value > 0 ? '#ff974a' : '#FC5A5A')};
    @media (min-width: 1600px) {
      font-size: 12px;
    }
  }
`;

let RadioButtonPanel = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #4b4d4e;
  padding: 0;
  margin: 15px 10px;
  padding-left: 25px;
  input {
    margin: 0 0 0 -20px;
    top: 2px;
  }
  @media (min-width: 1600px) {
    font-size: 13px;
  }
`;
let MenuFilterPanel = styled.div`
  position: absolute;
  right: 2%;
  box-shadow: 0 2.5px 7.5px 0 rgba(68, 68, 79, 0.1);
  border: solid 0.5px #f1f1f5;
  background-color: #ffffff;
  width: 200px;
  border-radius: 3px;
  z-index: 1;
  top: 20px;
  div.filter-header {
    display: flex;
    height: 30px;
    margin: 0 15px;
    p.header-status {
      margin: 0;
      align-self: center;
    }
    div.divider-status {
      width: 2px;
      background: #f1f1f5;
      position: absolute;
      right: 30px;
      height: 30px;
    }
    div.arrow-down {
      align-self: center;
      position: absolute;
      right: 10px;
    }
  }
  p.close-button {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: 0.04px;
    text-align: right;
    color: #21a8df;
    margin: 10px;
    @media (min-width: 1600px) {
      font-size: 13px;
    }
  }
`;
const Table = wrapComponent(
  ({
    t,
    i18n,
    createSnackbar,
    config,
    data,
    sort,
    action,
    menuFilterStatus,
    checkboxSelection,
    selectedFilterValue,
    openRespectiveProject,
    updateTaskModal,
    deleteDate,
    from,
    User
  }) => {
    moment.locale('ja');
    const [currentEdit, setCurrentEdit] = useState('');
    const [filterMenu, setFilterMenu] = useState(false);
    const [filterMenuOption, setFilterMenuOption] = useState();
    const [{ user }, dispatch] = useContext(UserContext);

    // console.log(user);
    const getWidth = (config, index) => {
      return config[index].config.width;
    };
    const convertDate = (inputFormat) => {
      function pad(s) {
        return s < 10 ? '0' + s : s;
      }
      var d = new Date(inputFormat);
      return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('-');
    };

    const truncate = (str) => {
      if (str) {
        return str.length > 140 ? str.substring(0, 140) + '...' : str;
      } else {
        return str;
      }
    };

    const triggerAction = (type, field) => {
      setCurrentEdit('');
      action(type, field);
    };
    const fieldChange = (event, field) => {
      setFilterMenuOption(field);
    };
    const changeHandlerFilter = (event, field) => {
      checkboxSelection(event.target.checked, field, event);
    };
    const showSnackbar = (msg) => {
      createSnackbar({
        theme: 'info',
        message: msg,
        timeout: 2000,
        dismissable: false,
        pauseOnHover: true,
        progressBar: false,
        sticky: false
      });
    };
    const getRespectiveRow = (row, totalRowsIndex) => {
      var singleRow = [];
      for (let [key, value] of Object.entries(row)) {
        singleRow.push(
          <>
            <RespectiveRowDetail width={getWidth(config, singleRow.length)}>
              {value.type === 'edit' ? (
                <EditBox id={'editBox' + totalRowsIndex}>
                  {/* <div onClick={() => setCurrentEdit("editBox" + totalRowsIndex)}> */}
                  <div
                    onMouseEnter={() => setCurrentEdit('editBox' + totalRowsIndex)}
                    onMouseLeave={() => setCurrentEdit('')}
                  >
                    <ThreeDots />
                    <ActionPanel
                      selected={'editBox' + totalRowsIndex === currentEdit}
                      options={value.options}
                      triggerAction={triggerAction}
                      fieldValue={value.fieldValue}
                    />
                  </div>
                </EditBox>
              ) : value.type === 'string&image' ? (
                value.value && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    {value.value.profileImage ? (
                      <img
                        src={value.value.profileImage}
                        style={{
                          width: '30px',
                          borderRadius: '50%',
                          height: '30px'
                        }}
                      />
                    ) : (
                      <Avatar
                        size='30px'
                        round='50%'
                        name={value.value.firstName + ' ' + value.value.lastName}
                      />
                    )}
                    <div style={{ margin: 'auto 10px' }}>{value.value.firstName}</div>
                  </div>
                )
              ) : value.type === 'number&image' ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <Attachments /> <p style={{ margin: 'auto 5px' }}>{value.value.length}</p>
                </div>
              ) : value.type === 'message%date' ? (
                <p className='m-0'>
                  {parseCount(value.value, value.fieldValue, 'lastMessageTime')
                    ? moment(parseCount(value.value, value.fieldValue, 'lastMessageTime')).format(
                        'DD-MMM-YYYY'
                      )
                    : ''}
                </p>
              ) : value.type === 'message%count' ? (
                <p className='m-0'>{parseCount(value.value, value.fieldValue, 'count')}</p>
              ) : value.type === 'day&string' ? (
                value.value != '' && (
                  <TimeLeftPanel value={Number(value.value.split(' ')[0])}>
                    <div className='background-panel'></div>
                    <p className='icon-timeleft'>
                      {Number(value.value.split(' ')[0]) > 7 ? (
                        <GrayTimeLeft />
                      ) : Number(value.value.split(' ')[0]) > 0 ? (
                        <TimeLeft />
                      ) : (
                        <RedTimeLeft />
                      )}
                    </p>
                    <p className='text-timeleft'>{value.value}</p>
                  </TimeLeftPanel>
                )
              ) : value.type === 'string%progressbar' ? (
                <ProjectBar width={value.value} />
              ) : value.type === 'string&link' ? (
                value.value != '' && (
                  <p
                    style={{
                      height: 'auto',
                      overflow: 'hidden',
                      maxHeight: '30px'
                    }}
                    onClick={() => openRespectiveProject(value.value.link)}
                    className='m-0 hover hover-hyperlink'
                  >
                    {truncate(value.value.value)}
                  </p>
                )
              ) : value.type === 'profileimage' ? (
                <div className='d-flex'>
                  <TeamMembersIcon team={value.value} maxTeam={3} />
                </div>
              ) : value.type === 'download' ? (
                <a href={value.value} download target='_blank'>
                  <Download />
                </a>
              ) : value.type === 'share' ? (
                <CopyToClipboard
                  text={value.fieldValue.url}
                  onCopy={() => copyToChipboard(value.fieldValue)}
                >
                  <div className='hover'>
                    <ShareIcon />
                  </div>
                </CopyToClipboard>
              ) : value.type === 'checkbox' ? (
                <label className='checkbox-container' checked='checked'>
                  <input
                    type='checkbox'
                    disabled={
                      user.user.role === 'facilitator'
                        ? false
                        : user.user.role === 'professional' &&
                          user.user._id == value.fieldValue.assignee._id
                        ? false
                        : true
                    }
                    onChange={(event) => changeHandlerFilter(event, value.fieldValue)}
                    className={'tasks-checkbox'}
                  />
                  <span className='checkbox-checkmark'></span>
                </label>
              ) : value.type === 'close' ? (
                <p className='hover m-0' onClick={() => deleteDate(value.fieldValue)}>
                  <Close />
                </p>
              ) : value.type === 'string&hyperlink' ? (
                <p
                  className='m-0 underline-hover'
                  style={{
                    maxHeight: '30px',
                    overflow: 'hidden',
                    float: 'left'
                  }}
                  onClick={() => updateTaskModal(value.fieldValue)}
                >
                  {truncate(value.value)}
                </p>
              ) : value.type === 'date' ? (
                <p
                  className='m-0'
                  style={{
                    height: '100%',
                    overflow: 'hidden'
                  }}
                >
                  {convertDate(value.value)}
                </p>
              ) : (
                <p
                  className='m-0'
                  style={{
                    maxHeight: '30px',
                    overflow: 'hidden'
                    // float: "left"
                  }}
                >
                  {truncate(value.value)}
                </p>
              )}
            </RespectiveRowDetail>
          </>
        );
      }
      return singleRow;
    };

    const parseCount = (messages, field, fetch) => {
      let parse;
      messages.map((x) => {
        if (x._id === field._id) {
          parse = x[fetch];
        }
      });
      return parse;
    };

    const generateRows = () => {
      let row = data.map((row, index) => {
        return (
          <TableRow
            key={'row' + index}
            index={index}
            className={
              from === 'inquiry'
                ? row.title.fieldValue.inquiryStatus === 'signed'
                  ? 'highlight-row'
                  : ''
                : from === 'project'
                ? row.name.fieldValue.status === 'completed'
                  ? 'highlight-row'
                  : ''
                : ''
            }
          >
            {getRespectiveRow(row, index)}
          </TableRow>
        );
      });
      return row;
    };

    const copyToChipboard = () => {
      showSnackbar('Copied');
    };

    const generateHeaderForm = () => {
      let formCreater = config.map((field, index) => {
        let fieldDetails = field.config;
        return (
          <TableHeaderWrapper key={'i' + index} width={fieldDetails.width}>
            <TableHeader
              onClick={() => {
                if (fieldDetails.sort) {
                  sort(index);
                }
              }}
              float={fieldDetails.type === 'filter%option'}
            >
              {fieldDetails.name}
              {fieldDetails.sort && fieldDetails.sortType && <SortArrow type='Down' />}
              {fieldDetails.sort && !fieldDetails.sortType && <SortArrow type='Up' />}
              {fieldDetails.type === 'filter%option' && (
                <>
                  <div className='m-0' onClick={() => setFilterMenu(!filterMenu)}>
                    <FilterIcon />
                  </div>
                  {user.user.role !== 'client' && filterMenu && (
                    <MenuFilterPanel>
                      <div className='filter-header'>
                        <p className='header-status'>
                          <small>{t('common.mark_as')} </small>
                          <strong>{filterMenuOption ? filterMenuOption.label : ''}</strong>
                        </p>
                        <div className='divider-status'></div>
                        <div class='arrow-down'></div>
                      </div>
                      {menuFilterStatus.map((field) => (
                        <RadioButtonPanel className='form-check'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='planStart'
                            value={field.value}
                            label={field.label}
                            onChange={(event) => fieldChange(event, field)}
                          />
                          <label className='form-check-label'>{field.label}</label>
                        </RadioButtonPanel>
                      ))}
                      <div className='d-flex justify-content-between'>
                        <p
                          className='close-button'
                          onClick={() => {
                            selectedFilterValue(filterMenuOption.value);
                            setFilterMenu(!filterMenu);
                            setFilterMenuOption();
                          }}
                        >
                          {t('common.apply')}
                        </p>
                        <p
                          className='close-button'
                          onClick={() => {
                            setFilterMenu(!filterMenu);
                            setFilterMenuOption();
                          }}
                        >
                          {t('common.close')}
                        </p>
                      </div>
                    </MenuFilterPanel>
                  )}
                </>
              )}
            </TableHeader>
          </TableHeaderWrapper>
        );
      });
      return formCreater;
    };

    return (
      <div style={{ padding: '20px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            padding: '0 10px'
          }}
        >
          {generateHeaderForm()}
        </div>
        <div>{generateRows()}</div>
      </div>
    );
  }
);
export default withTranslation('common')(Table);
