import { useEffect } from 'react';

export const GTM = () => {
  useEffect(() => {
    //   <!-- Global site tag (gtag.js) - Google Analytics -->
    const script1 = document.createElement('script');
    script1.setAttribute(
      'src',
      `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTAG_ID}`
    );
    document.body.appendChild(script1);

    const script2 = document.createElement('script');
    script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() {
        dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', '${process.env.REACT_APP_GTAG_ID}');
    `;
    document.body.appendChild(script2);
  }, []);
  
  return null;
};
