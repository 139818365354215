import React, { useCallback, useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { ProjectTasksConfig } from '../../../public/configuration';
import { ProjectTasksConfig as ProjectTasksConfigJP } from '../../../public/configurationJP';
import { Button, Modal, Loader } from '../../../common';
import AddTaskModal from './AddTaskModal';
import { projectService } from '../../../_services/project.service';
import { wrapComponent } from 'react-snackbar-alert';
import { UserContext } from '../../../common/context/UserContext';
import DeletePopUp from '../../../common/DeletePopUp';
import { withTranslation } from 'react-i18next';
import Pagination from '../../../common/Pagination';
import Table from '../../../common/Table';

let BottomPanel = styled.div`
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    div.button-footer{
        position: absolute;
    right: 50px;
    bottom: 0;
    }

}
`;
const ProjectTaskPanel = wrapComponent(({ t, i18n, createSnackbar, projectDetails }) => {
  const [{ user, dropdown }, dispatch] = useContext(UserContext);
  const [dataToDisplay, setDataToDisplay] = useState();
  const [selectedTask, setSelectedTask] = useState([]);
  const [deleteTask, setDeleteTask] = useState('');
  const [totalPageNo, setTotalPageNo] = useState();
  const [LoaderStatus, setLoader] = useState(false);
  const [filterField, setFilterField] = useState({ name: '', type: '' });
  const type = 'Task';
  const columns = [
    'checkbox',
    'taskName',
    'category',
    'assignee',
    'assignedBy',
    'startDate',
    'dueDate',
    'status'
  ];
  const [updateTask, setUpdateTask] = useState();
  useEffect(() => {
    getTasks(1, 0);
  }, []);

  const getStatus = (status) => {
    if (status === 'not_started') {
      return t('projectStatus.not_started');
    } else if (status === 'in_progress') {
      return t('projectStatus.in_progress');
    } else if (status === 'completed') {
      return t('projectStatus.completed');
    } else {
      return '';
    }
  };

  const showSnackbar = (msg, theme) => {
    createSnackbar({
      theme: 'info',
      message: msg,
      timeout: 2000,
      dismissable: false,
      pauseOnHover: true,
      progressBar: false,
      sticky: false
    });
  };

  const getTasks = (page, limit, orderBy, order) => {
    setLoader(false);
    let data = {};
    if (limit === 0) {
      data = { project_id: projectDetails._id, page: page };
    } else {
      data = { project_id: projectDetails._id, page: page, limit: limit };
    }
    if (orderBy) {
      data['orderBy'] = orderBy;
      data['order'] = order;
    }
    projectService.getTask(data).then(
      (response) => {
        // console.log("response", response)
        for (let i = 0; i < document.getElementsByClassName('tasks-checkbox').length; i++) {
          document.getElementsByClassName('tasks-checkbox')[i].checked = false;
          document
            .getElementsByClassName('tasks-checkbox')
            [i].parentElement.parentElement.parentElement.childNodes[1].classList.remove(
              'checkbox-bold'
            );
        }
        setTotalPageNo(Math.ceil(response.data.totalCount / 6));
        let dataToSend = [];
        response.data.result.map((field, index) => {
          dataToSend.push({
            checkbox: { type: 'checkbox', value: field.project, fieldValue: field },
            taskName: { type: 'string&hyperlink', value: field.name, fieldValue: field },
            category: { type: 'string', value: field.category, fieldValue: field },
            assignee: { type: 'string&image', value: field.assignee, fieldValue: field },
            assignedBy: { type: 'string&image', value: field.creator, fieldValue: field },
            startDate: { type: 'date', value: field.startDate, fieldValue: field },
            dueDate: { type: 'date', value: field.endDate, fieldValue: field },
            status: { type: 'string', value: getStatus(field.status), fieldValue: field },
            action: {
              type: user.user.role === 'facilitator' ? 'close' : '',
              value: '',
              fieldValue: field
            }
          });
        });
        setDataToDisplay(dataToSend);
        setLoader(true);
      },
      (err) => {
        console.error('failed to load task');
        setLoader(true);
      }
    );
  };
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const [projectConfig, setProjectConfig] = useState(ProjectTasksConfigJP);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const closeDeleteModalHandler = useCallback(() => {
    setDeleteModalState(false);
  });
  const handleSort = useCallback((index) => {
    let newConfigSortChange = [...projectConfig];
    newConfigSortChange[index].config.sortType = !newConfigSortChange[index].config.sortType;
    setProjectConfig(newConfigSortChange);
    setFilterField({
      ...filterField,
      ...{ name: columns[index], type: newConfigSortChange[index].config.sortType ? 'asc' : 'desc' }
    });
    getTasks(1, 6, columns[index], newConfigSortChange[index].config.sortType ? 'asc' : 'desc');
    // setDataToDisplay(
    //     getSortedData(dataToDisplay, columns[index], newConfigSortChange[index].config.sortType)
    // );
  });

  const addTaskForm = async (form) => {
    await projectService.addTask(form).then(
      (response) => {
        // console.log("response add task", response)
        showSnackbar(t('project.actions.add_task'));
        setShowAddTaskModal(false);
        getTasks(totalPageNo, 0);
      },
      (err) => {
        console.error('error', err);
      }
    );
  };

  const updateTaskForm = async (form) => {
    await projectService.editTask(form).then(
      (response) => {
        // console.log("response update task", response)
        showSnackbar(t('project.actions.update_task'));
        setUpdateTask();
        setShowAddTaskModal(false);
        getTasks(totalPageNo, 0);
      },
      (err) => {
        console.error('error', err);
      }
    );
  };

  const checkedTasks = [];
  const checkboxHandler = (checked, field, event) => {
    if (checked) {
      event.target.parentElement.parentElement.parentElement.childNodes[1].classList.add(
        'checkbox-bold'
      );
      checkedTasks.push(field);
    } else {
      const index = checkedTasks.indexOf(field);
      if (index > -1) {
        event.target.parentElement.parentElement.parentElement.childNodes[1].classList.remove(
          'checkbox-bold'
        );
        checkedTasks.splice(index, 1);
      }
    }
  };

  const updateTaskStatus = (data) => {
    projectService.updateTasks(data).then(
      (response) => {
        // console.log("resoposne", response)
        getTasks(1, 0);
      },
      (err) => {
        console.error('error  while updating task', err);
      }
    );
  };
  const selectedFilterValue = (statusValue) => {
    let data = {
      tasks: [],
      status: statusValue,
      project_id: [projectDetails._id]
    };
    checkedTasks.map((task) => data.tasks.push(task._id));
    updateTaskStatus(data);
  };

  const pageNoClicked = (pageNo) => {
    if (filterField.name) {
      getTasks(pageNo, pageNo === -1 ? -1 : 6, filterField.name, filterField.type);
    } else {
      getTasks(pageNo, pageNo === -1 ? -1 : 0);
    }
  };

  const updateTaskModal = (field) => {
    // console.log("field", field)
    setShowAddTaskModal(true);
    setUpdateTask(field);
  };

  const deleteDate = async () => {
    let data = {};
    data.task_id = deleteTask;
    await projectService.deleteTask(data).then(
      (response) => {
        setDeleteModalState(false);
        showSnackbar(t('project.actions.delete_task'));
        getTasks(1, 0);
      },
      (err) => {
        console.error('error', err);
      }
    );
  };

  const deleteAction = async (field) => {
    setDeleteTask(field._id);
    setDeleteModalState(true);
  };

  return (
    <>
      <div className='project-details-tabs'>
        {dataToDisplay ? (
          <>
            {LoaderStatus ? (
              <Table
                config={projectConfig}
                data={dataToDisplay}
                sort={handleSort}
                menuFilterStatus={dropdown.TaskStatus.slice(1)}
                checkboxSelection={checkboxHandler}
                selectedFilterValue={selectedFilterValue}
                updateTaskModal={updateTaskModal}
                deleteDate={deleteAction}
              ></Table>
            ) : (
              <Loader />
            )}
            <BottomPanel>
              <div>
                <Pagination
                  pageNoClicked={pageNoClicked}
                  totalPageNo={totalPageNo}
                  type={'Tasks'}
                />
              </div>
              {user.user.role !== 'professional' && (
                <div className='button-footer'>
                  <Button
                    text={t('project.details.task.add_task')}
                    fullWidth='140%'
                    switch={true}
                    onClick={(e) => setShowAddTaskModal(true)}
                  />
                </div>
              )}
            </BottomPanel>
          </>
        ) : LoaderStatus ? (
          <></>
        ) : (
          <Loader />
        )}
        {showAddTaskModal && (
          <Modal
            show={showAddTaskModal || updateTask}
            close={() => {
              setUpdateTask();
              setShowAddTaskModal(false);
            }}
          >
            <AddTaskModal
              projectDetails={projectDetails}
              addTaskSendForm={addTaskForm}
              updateTask={updateTask}
              updateTaskForm={updateTaskForm}
            />
          </Modal>
        )}
        {deleteModalState && (
          <Modal show={deleteModalState} close={closeDeleteModalHandler}>
            <DeletePopUp delete={deleteDate} type={type} />
          </Modal>
        )}
      </div>
    </>
  );
});

export default withTranslation('common')(ProjectTaskPanel);
