import React from 'react';
import styled from 'styled-components';

let StatusMain = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 60%;
  margin: auto;
`;
let StatusDiv = styled.div`
  flex: 1 1 15%;
  width: 100%;
  height: 3px;
  background: red;
  border-radius: 8px;
  box-shadow: 0 0.5px 1.5px 0 rgba(0, 0, 0, 0.04);
  background-color: ${(props) => (props.color ? '#21a8df' : '#fbfbfd')};
  margin: 2px;
`;

const FormProgress = ({ totalForms, completed }) => (
  <StatusMain>
    {[...Array(completed).keys()].map((d, i) => (
      <StatusDiv key={i} color={true}></StatusDiv>
    ))}
    {[...Array(totalForms - completed).keys()].map((d, i) => (
      <StatusDiv key={i + completed}></StatusDiv>
    ))}
  </StatusMain>
);

export { FormProgress };
