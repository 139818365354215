import React, { useState } from 'react';
import styled from 'styled-components';
import { CardLanding, LoginRegisterHeader, Loader } from '../common';
import { useEffect } from 'react';
import { authenticationService } from '../_services';
import { Link } from 'react-router-dom';

let Message = styled.p`
  text-transform: capitalize;
  text-align: center;
  margin: 20px 0;
  font-family: Montserrat;
  font-size: 15px;
`;
const VerifyPage = (props) => {
  const [verified, setVerified] = useState(false);
  const [message, setMessage] = useState('');
  useEffect(() => {
    let data = {
      userId: props.location.search.split('&token=')[0].split('?id=')[1],
      token: props.location.search.split('&token=')[1]
    };
    authenticationService.verifyAccount(data).then(
      (res) => {
        setMessage(res.data.message);
        setVerified(true);
      },
      (err) => {
        setVerified(true);
      }
    );
  }, []);
  return verified ? (
    <CardLanding>
      <LoginRegisterHeader text='VERIFY YOUR ACCOUNT' />
      <Message>{message}</Message>
      <Link to='/login' className='link-text'>
        &#8592; Login
      </Link>
    </CardLanding>
  ) : (
    <Loader />
  );
};

export { VerifyPage };
