import React, { useState, useCallback, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Button, Calendar } from '../../../common';
import AddIcon from '../../../public/icons/AddIcon';
import { projectService } from '../../../_services/project.service';
import * as moment from 'moment';
import { wrapComponent } from 'react-snackbar-alert';
import NumberFormat from 'react-number-format';
import { UserContext } from '../../../common/context/UserContext';
import Avatar from 'react-avatar';
import { withTranslation } from 'react-i18next';
import TeamDropdown from '../../../common/TeamDropdown';
import 'moment/locale/ja';
import 'moment/locale/en-ca';

let HeaderModal = styled.p`
  font-family: Montserrat;
  font-size: 0.8em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.05px;
  text-align: left;
  color: #4b4d4e;
`;

let HeaderTitleModal = styled.p`
  font-family: Montserrat;
  font-size: 1.1em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.07px;
  text-align: left;
  color: #21a8df;
`;

let FormLabel = styled.div`
  font-family: Montserrat;
  font-size: 0.8em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.05px;
  text-align: left;
  color: #696974;
  margin: auto;
`;

let InputBox = styled.input`
  border: 1px solid #e5e5e5 !important;
  border-radius: 3px;
  font-family: Montserrat;
  font-size: 0.8em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #4b4d4e;
  border: 0;
  width: 100%;
  resize: none;
`;

let TextBoxBox = styled.textarea`
  border: 1px solid #e5e5e5 !important;
  border-radius: 3px;
  font-family: Montserrat;
  font-size: 0.8em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.7px;
  text-align: left;
  color: #4b4d4e;
  border: 0;
  height: 90px;
  width: 100%;
  resize: none;
  scrollbar-width: none;
`;

let SelectBox = styled.select`
  font-family: Montserrat;
  font-size: 0.8em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #4b4d4e;
  border: 0;
  width: 100%;
  float: left;
  padding: 10px;
`;

let RowBox = styled.div`
  padding: 10px 0;
  div.primaryContact {
    img {
      width: 30px;
      border-radius: 50%;
      height: 30px;
    }
    div {
      margin: auto 10px;
      font-family: Montserrat;
      font-size: 0.8em;
    }
  }
`;

let InquiryDetailForm = styled.form`
  width: 70vh;
`;

let AddTeamPanel = styled.div`
  display: flex;
  p.add-team-member {
    font-family: Montserrat;
    font-size: 12px;
    line-height: 1.43;
    letter-spacing: 0.05px;
    text-align: left;
    color: #21a8df;
    margin: 0 10px;
    :hover {
      cursor: hover;
    }
  }
`;

let Warning = styled.div`
  font-family: Montserrat;
  font-size: 0.8em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.05px;
  text-align: right;
  color: #f44336;
  align-self: center;
`;

let MemberIcon = styled.div`
  border-radius: 50%;
  margin-right: 5px;
  height: 29px;
  width: 30px;
  overflow: hidden;
  border: ${(props) => (props.number ? 'solid 0.5px #4b4d4e' : 'none')};
  img {
    height: 29px;
    width: 30px;
    border-radius: 50%;
  }
  p {
    align-self: center;
    padding: 7px 0;
    text-align: center;
    overflow: hidden;
    height: 100%;
    line-height: 1.25;
    color: #4b4d4e;
    font-family: Montserrat;
    font-size: 11px;
  }
`;

const EditProject = wrapComponent(({ t, i18n, createSnackbar, project, form }) => {
  moment.locale('ja');

  const [{ user, dropdown }, dispatch] = useContext(UserContext);

  const showSnackbar = (msg) => {
    createSnackbar({
      theme: 'error',
      message: msg,
      timeout: 2000,
      dismissable: false,
      pauseOnHover: true,
      progressBar: false,
      sticky: false
    });
  };

  const currencies = [
    { value: 'USD', label: 'USD' },
    { value: 'EUR', label: 'EUR' },
    { value: 'JPY', label: 'JPY' }
  ];

  const [currenySign, setcurrenySign] = useState(() => {
    if (project.currency === 'USD') {
      return '$';
    } else if (project.currency === 'EUR') {
      return '€';
    } else {
      return '¥';
    }
  });

  const [editProject, setEditProject] = useState({
    _id: project._id,
    name: project.name,
    category: project.category,
    subCategory: project.subCategory,
    status: project.status,
    budget: project.budget,
    currency: project.currency,
    startDate: project.startDate,
    endDate: project.endDate,
    background: project.background,
    details: project.details,
    // owner: project.owner,
    team: project.team
  });

  const [showWarning, setWarning] = useState(0);

  const fieldChange = useCallback((event) => {
    let data = {};
    data[event.target.name] = event.target.value;
    if (event.target.name === 'currency') {
      if (event.target.value === 'USD') {
        setcurrenySign('$');
      } else if (event.target.value === 'EUR') {
        setcurrenySign('€');
      } else {
        setcurrenySign('¥');
      }
    }
    setEditProject({ ...editProject, ...data });
    if (event.target.name === 'category') {
      setSubCategories(
        dropdown.subCategoriesBank[
          dropdown.categories.findIndex((item) => {
            return item.value === event.target.value;
          })
        ]
      );
    }
  });

  const validateFormData = () => {
    let msg;
    if (editProject.name === '' || editProject.name === null) {
      msg = t('project.actions.title_empty');
    } else if (editProject.category === '' || editProject.category === null) {
      msg = t('project.actions.category_empty');
    } else if (editProject.subCategory === '' || editProject.subCategory === null) {
      msg = t('project.actions.sub_category_empty');
    } else if (editProject.status === '' || editProject.status === null) {
      msg = t('project.actions.status_empty');
    } else if (editProject.startDate === '' || editProject.startDate === null) {
      msg = t('project.actions.start_date_empty');
    } else if (editProject.endDate === '' || editProject.endDate === null) {
      msg = t('project.actions.end_date_empty');
    } else if (editProject.budget === '' || editProject.budget === null) {
      msg = t('project.actions.budget_empty');
    } else if (editProject.currency === '' || editProject.currency === null) {
      msg = t('project.actions.currency_empty');
    } else if (editProject.details === '' || editProject.details === null) {
      msg = t('project.actions.detail_empty');
    } else if (editProject.background === '' || editProject.background === null) {
      msg = t('project.actions.background_empty');
    }

    // else if (editProject.team.length === 0) {
    //   msg = (t('project.actions.professional_empty'));
    // };

    if (msg) {
      showSnackbar(msg);
      return false;
    } else {
      return true;
    }
  };

  const [subCategories, setSubCategories] = useState(dropdown.subCategoriesBank[0]);

  const [showTeamBox, setShowTeamBox] = useState(false);

  const submit = (e) => {
    e.preventDefault();
    if (validateFormData()) {
      form(editProject);
    }
  };

  const [teamMembers, setTeamMembers] = useState();
  useEffect(() => {
    // if (projectDetails._id) {
    projectService.getProfesstional().then(
      (response) => {
        if (response.data.result) {
          // console.log('response team', response)
          let res = response.data.result;
          let data = [];
          res.map((r) => {
            data.push({ member: r });
          });
          setTeamMembers(data);
        }
      },
      (err) => {
        console.error('error project filers', err);
      }
    );
  }, []);

  const selectedTeamMembers = (value) => {
    setShowTeamBox(false);
    setEditProject({ ...editProject, team: value });
    // console.log(editProject)
  };

  const fieldChangeCalendar = (value, type) => {
    let data = {};
    data[type] = moment(value).format('YYYY-MM-DD');
    if (type === 'endDate' && moment(value).format('YYYY-MM-DD') < editProject.startDate) {
      setWarning(1);
      showSnackbar('End date cannot be less than start date', 'error');
      return false;
    }
    if (type === 'endDate' && moment(value).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
      setWarning(1);
      showSnackbar('End date cannot be less than todays date', 'error');
      return false;
    }
    if (type === 'startDate' && moment(value).format('YYYY-MM-DD') > editProject.endDate) {
      setWarning(1);
      showSnackbar('Start date cannot be more than end date', 'error');
      return false;
    }
    setWarning(0);
    setEditProject({ ...editProject, ...data });
  };
  return (
    <InquiryDetailForm name='form' onSubmit={submit}>
      <HeaderModal>{t('project.edit.header')}</HeaderModal>
      <HeaderTitleModal>{editProject.name || t('project.edit.header')}</HeaderTitleModal>
      <RowBox className='row'>
        <FormLabel className='col-md-3'>{t('project.edit.title')}</FormLabel>
        <div className='col-md-9'>
          <InputBox
            name='name'
            value={editProject.name}
            onChange={(e) => fieldChange(e)}
            maxLength='100'
          />
        </div>
      </RowBox>
      <RowBox className='row'>
        <FormLabel className='col-md-3'>{t('project.edit.category')}</FormLabel>
        <div className='col-md-9'>
          <SelectBox name='category' value={editProject.category} onChange={(e) => fieldChange(e)}>
            {dropdown.categories.map((item, index) => (
              <option value={item.label} key={index}>
                {item.label}
              </option>
            ))}
          </SelectBox>
        </div>
      </RowBox>
      <RowBox className='row'>
        <FormLabel className='col-md-3'>{t('project.edit.sub-category')}</FormLabel>
        <div className='col-md-9'>
          <SelectBox
            name='subCategory'
            value={editProject.subCategory}
            onChange={(e) => fieldChange(e)}
          >
            {subCategories.map((value, index) => (
              <option value={value} key={index}>
                {value}
              </option>
            ))}
          </SelectBox>
        </div>
      </RowBox>
      <RowBox className='row'>
        <FormLabel className='col-md-3 m-0'>{t('project.edit.detail')}</FormLabel>
        <div className='col-md-9'>
          <TextBoxBox name='details' value={editProject.details} onChange={(e) => fieldChange(e)} />
        </div>
      </RowBox>
      <RowBox className='row'>
        <FormLabel className='col-md-3'>{t('project.edit.status')}</FormLabel>
        <SelectBox
          className='col-md-3'
          name='status'
          value={editProject.status}
          onChange={(e) => fieldChange(e)}
        >
          {dropdown.TaskStatus.map(({ value, label }, index) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </SelectBox>
        <FormLabel className='col-md-2'>{t('project.edit.budget')}</FormLabel>
        <NumberFormat
          className='col-md-2 currency-font'
          thousandSeparator={true}
          allowNegative={false}
          prefix={currenySign}
          value={editProject.budget}
          onValueChange={(values) => {
            const { value } = values;
            editProject.budget = value;
          }}
        />
        <SelectBox
          className='col-md-2'
          name='currency'
          value={editProject.currency}
          onChange={(e) => fieldChange(e)}
        >
          {currencies.map(({ value, label }, index) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </SelectBox>
      </RowBox>
      <RowBox className='row'>
        <FormLabel className='col-md-3 m-0'>{t('project.edit.start_date')} </FormLabel>
        <div
          className='col-md-3'
          style={{
            padding: '0',
            fontSize: '11px',
            fontFamily: 'Montserrat'
          }}
        >
          <Calendar
            className='col-md-3'
            name='startDate'
            value={moment(editProject.startDate).format('MMM DD, YYYY')}
            onChange={(e) => fieldChangeCalendar(e, 'startDate')}
            dateFormat='MMM DD, YYYY'
            className='hover'
          />
        </div>
        <FormLabel className='col-md-3 m-0'>{t('project.edit.end_date')}</FormLabel>
        <div
          className='col-md-3'
          style={{
            padding: '0',
            fontSize: '11px',
            fontFamily: 'Montserrat'
          }}
        >
          <Calendar
            className='col-md-3'
            name='endDate'
            value={moment(editProject.endDate).format('MMM DD, YYYY')}
            onChange={(e) => fieldChangeCalendar(e, 'endDate')}
            dateFormat='MMM DD, YYYY'
            className='hover'
            startDate={editProject.startDate ? editProject.startDate : new Date()}
          />
        </div>
        {/* {showWarning ? <Warning className='col-md-12'>{t('project.edit.enter_start_date')}</Warning> : null} */}
      </RowBox>
      <RowBox className='row'>
        <FormLabel className='col-md-3 m-0'>{t('project.edit.team')}</FormLabel>
        <div className='col-md-9'>
          <div>
            <AddTeamPanel>
              {editProject.team.length > 0 &&
                editProject.team
                  .slice(0, 5 - 1)
                  .map((icon, index) => (
                    <MemberIcon key={'profile-icon' + index}>
                      {icon.member && icon.member.profileImage ? (
                        <img src={icon.member.profileImage} />
                      ) : (
                        icon.member && (
                          <Avatar
                            size='30px'
                            round='50%'
                            name={icon.member.firstName + ' ' + icon.member.lastName}
                          />
                        )
                      )}
                    </MemberIcon>
                  ))}
              {editProject.team.length > 4 && (
                <>
                  <MemberIcon number={true}>
                    <p>+{editProject.team.length - (5 - 1)}</p>
                  </MemberIcon>
                </>
              )}

              {editProject.team.length === 0 ? (
                <>
                  <AddIcon />
                  <p className='add-team-member' onClick={() => setShowTeamBox(!showTeamBox)}>
                    {t('project.edit.select_team')}
                  </p>
                </>
              ) : (
                <p
                  className='add-team-member align-self-center'
                  onClick={() => setShowTeamBox(!showTeamBox)}
                >
                  {t('project.edit.select_team')}
                </p>
              )}
              {showTeamBox && (
                <div
                  style={{
                    width: ' 40%',
                    position: 'absolute',
                    top: '25px'
                  }}
                >
                  <TeamDropdown
                    close={() => setShowTeamBox(!showTeamBox)}
                    selectedTeam={(value) => selectedTeamMembers(value)}
                    selectedMember={editProject.team}
                    multiple={true}
                    teamMembersOptions={teamMembers}
                  />
                </div>
              )}
            </AddTeamPanel>
          </div>
        </div>
      </RowBox>
      <Button text={t('universal_actions.save')} type='submit'></Button>
    </InquiryDetailForm>
  );
});

export default withTranslation('common')(EditProject);
