import React from 'react';
import styled from 'styled-components';

let Header = styled.h3`
  font-family: Montserrat;
  font-size: 15px;
  letter-spacing: 0.9px;
  color: #21a8df;
  font-weight: bold;
  @media (min-width: 1600px) {
    font-size: 16px;
  }
`;
const PageHeader = (props) => <Header {...props}>{props.text}</Header>;

export { PageHeader };
