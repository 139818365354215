import React, { useCallback, useEffect, useState, useContext } from 'react';
import Iframe from 'react-iframe';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { UserContext } from '../../common/context/UserContext';
let IframeWrapper = styled.div`
  height: 100%;
  padding: 30px;
`;

const BusimessCases = ({ t, i18n, ...props }) => {
  const [{ user, webflowLinks }, dispatch] = useContext(UserContext);
  return (
    <>
      <IframeWrapper>
        <Iframe
          id='content_iframe'
          url={webflowLinks.jp.businessCases}
          width='100%'
          height='100%'
        />
      </IframeWrapper>
    </>
  );
};
export default withTranslation('common')(BusimessCases);
