import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Button, PageHeader, Modal } from '../../../common';
import { Loader } from '../../../common';
import { InquiryConfig } from '../../../public/configuration';
import { InquiryConfig as InquiryConfigJP } from '../../../public/configurationJP';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { inquiryService } from '../../../_services/inquiry.service';
import EditInquiry from './EditModal';
import { UserContext } from '../../../common/context/UserContext';
import { wrapComponent } from 'react-snackbar-alert';
import { sendBirdService } from '../../../_services';
import Search from '../../../public/Search';
import { withTranslation } from 'react-i18next';
import DeletePopUp from '../../../common/DeletePopUp';
import Pagination from '../../../common/Pagination';
import Table from '../../../common/Table';

let HeaderWrapper = styled.div`
  margin: 0;
  display: flex;
  justify-content: flex-end;
  padding: 40px 70px 0 30px;
`;
let BottomPanel = styled.div`
    position: relative;
    top: 20px;
    bottom: 0;
    display: flex;
    width: 100%;
    div.pagination-footer{
        top: -10px;
    position: relative;
    left: 30px;
    
    }
    div.button-footer{
        position: absolute;
        right: 110px;
        bottom: 30px;
    }

}
`;
let NoProject = styled.div`
  font-weight: 600;
  text-align: left;
  font-size: 15px;
  margin: 30px;
  font-family: Montserrat;
`;
let FilterSearch = styled.input`
  border-radius: 30px;
  border: solid 0.6px #21a8df;
  background-color: #ffffff;
  height: 27px;
  width: 60%;
  margin: 0 20px;
  font-family: Montserrat;
  font-size: 12px;
  padding: 0 30px 0 20px;
`;
const InquiryDashboard = wrapComponent(({ createSnackbar, t, i18n, ...props }) => {
  const [{ user }, dispatch] = useContext(UserContext);
  const [LoaderStatus, setLoader] = useState(false);
  const [inquiryConfig, setInquiryConfig] = useState(InquiryConfigJP);
  const [allInquiry, setAllInquiry] = useState([]); //this is for future use - contains all data
  const [inquiriesToTable, setInquiriesToTable] = useState([]);
  const [editInquiry, setEditInquiry] = useState();
  const [deleteInquiry, setDeleteInquiry] = useState('');
  const columns = [
    'title',
    'background',
    'status',
    'primaryContact',
    'files',
    'messageCount',
    'lastMessageDate',
    'projectOverview',
    'action'
  ];
  const [apiProcess, setApiProcess] = useState({ text: '', flag: false });
  const type = 'inquiry';
  const [boxStateValue, setBoxStateValue] = useState('hide');
  const [totalPageNo, setTotalPageNo] = useState();
  const [filterField, setFilterField] = useState({ name: '', type: '', search: '' });
  const [filterTimeout, setFilterTimeout] = useState();
  const handleSort = useCallback((index) => {
    let newConfigSortChange = [...inquiryConfig];
    newConfigSortChange[index].config.sortType = !newConfigSortChange[index].config.sortType;
    setInquiryConfig(newConfigSortChange);
    setFilterField({
      ...filterField,
      ...{ name: columns[index], type: newConfigSortChange[index].config.sortType ? 'asc' : 'desc' }
    });
    getAllInquiries(1, 8, {
      name: columns[index],
      type: newConfigSortChange[index].config.sortType ? 'asc' : 'desc',
      search: filterField.search
    });
    // setInquiriesToTable(getSortedData(inquiriesToTable, columns[index], newConfigSortChange[index].config.sortType));
  });

  const [deleteModalState, setDeleteModalState] = useState(false);

  const closeDeleteModalHandler = useCallback(() => {
    setDeleteModalState(false);
  });

  const closeModalHandler = useCallback(() => {
    setEditInquiry();
  });
  useEffect(() => {
    getAllInquiries(1, 8);
  }, []);
  const openRespectiveProject = (link) => {
    props.history.push(link);
  };
  const getEditOptions = () => {
    if (user.user.role === 'client') {
      return [
        t('universal_actions.open_box'),
        t('universal_actions.edit_box'),
        t('universal_actions.delete_box')
      ];
    } else if (user.user.role === 'facilitator') {
      return [
        t('universal_actions.open_box'),
        t('universal_actions.edit_box'),
        t('universal_actions.delete_box')
      ];
    }
    return [];
  };

  const checkProjectStatus = (inquiry) => {
    if (inquiry.project) {
      return {
        value: t('inquiry.view_project'),
        link: '/home/project_details?project_id=' + inquiry.project
      };
    }
    return { value: '' };
  };

  const redirectToInquiry = (inquiry, type) => {
    return { value: inquiry[type], link: '/home/inquiry_details?inquiry_id=' + inquiry._id };
  };

  const showSnackbar = (msg) => {
    createSnackbar({
      theme: 'info',
      message: msg,
      timeout: 2000,
      dismissable: false,
      pauseOnHover: true,
      progressBar: false,
      sticky: false
    });
  };

  const lastMessageCount = (allInquiry) => {
    let temp = [];
    let count = 0;
    allInquiry.map((inquiry, index) => {
      setTimeout(() => {
        if (inquiry.sendBirdChannelURL) {
          sendBirdService
            .getChannel({ url: inquiry.sendBirdChannelURL, parent: inquiry._id, type: null })
            .then(
              (connectedChannel) => {
                sendBirdService.retrieveChannelPreviosMessages(connectedChannel).then(
                  (prevMessages) => {
                    count += 1;
                    temp.push({
                      count: prevMessages.length,
                      lastMessageTime: prevMessages[prevMessages.length - 1]
                        ? prevMessages[prevMessages.length - 1].createdAt
                        : null,
                      _id: inquiry._id
                    });
                    if (count === allInquiry.length) {
                      createTable(allInquiry, temp);
                    }
                  },
                  (err) => {
                    count += 1;
                    temp.push({ count: 0, lastMessageTime: '', _id: inquiry._id });
                    if (count === allInquiry.length) {
                      createTable(allInquiry, temp);
                    }
                  }
                );
              },
              (err) => {
                count += 1;
                temp.push({ count: 0, lastMessageTime: '', _id: inquiry._id });
                if (count === allInquiry.length) {
                  createTable(allInquiry, temp);
                }
              }
            );
        } else {
          count += 1;
          temp.push({ count: 0, lastMessageTime: '', _id: inquiry._id });
          if (count === allInquiry.length) {
            createTable(allInquiry, temp);
          }
        }
      }, 50 * index);
    });
  };

  const createTable = (allInquiries, messages) => {
    let dataToSend = [];
    allInquiries.map(async (field, index) => {
      dataToSend.push({
        title: { type: 'string&link', value: redirectToInquiry(field, 'name'), fieldValue: field },
        background: {
          type: 'string&link',
          value: redirectToInquiry(field, 'background'),
          fieldValue: field
        },
        status: { type: 'string', value: capitalize(field.inquiryStatus), fieldValue: field },
        primarycontact: { type: 'string&image', value: field.creator, fieldValue: field },
        files: { type: 'number&image', value: field.files, fieldValue: field },
        messageCount: { type: 'message%count', value: messages, fieldValue: field },
        lastMessageDate: { type: 'message%date', value: messages, fieldValue: field },
        projectOverview: {
          type: 'string&link',
          value: checkProjectStatus(field),
          fieldValue: field
        },
        action: { type: 'edit', options: getEditOptions(), fieldValue: field }
      });
    });
    setInquiriesToTable(dataToSend);
    setAllInquiry(allInquiries);
    setEditInquiry(); //to remove the modal
    let temp = { flag: false, text: '' };
    if (allInquiries.length == 0) {
      temp.text = t('inquiry.no_inquiry');
    }
    setApiProcess(temp);
  };
  const getAllInquiries = async (page, limit, filter) => {
    setLoader(false);
    let temp = { flag: true, text: '' };
    let data = {};
    if (limit === 0) {
      data = { page: page };
    } else {
      data = { page: page, limit: limit };
    }
    if (filter && filter.name && filter.type) {
      data['orderBy'] = filterField.name;
      data['order'] = filterField.type;
    }
    if (filter && filter.search) data['q'] = filterField.search;
    setApiProcess(temp);
    inquiryService.getInquiries(data).then((res) => {
      if (res.data.result && res.data.result.length > 0) {
        setTotalPageNo(Math.ceil(res.data.totalCount / 8));
        lastMessageCount(res.data.result);
      } else {
        setAllInquiry([]);
        setInquiriesToTable([]);
        let temp = { flag: false, text: '' };
        temp.text = t('inquiry.actions.no_inquiry');
        setApiProcess(temp);
      }
      setLoader(true);
    });
  };
  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    switch (s) {
      case 'draft':
        return t('inquiryStatus.draft');
      case 'submitted':
        return t('inquiryStatus.submitted');
      case 'signed':
        return t('inquiryStatus.signed');
      case 'suspended':
        return t('inquiryStatus.suspended');
      case 'completed':
        return t('inquiryStatus.completed');
      case 'proposed':
        return t('inquiryStatus.proposed');
      default:
        return '';
    }
  };
  const getAction = async (type, field) => {
    if (type === t('universal_actions.open_box')) {
      props.history.push(`/home/inquiry_details?inquiry_id=${field._id}`);
    } else if (type === t('universal_actions.edit_box')) {
      setEditInquiry(field);
    } else {
      setDeleteInquiry(field._id);
      setDeleteModalState(true);
    }
  };

  const editAction = async (editedForm) => {
    // console.log("editedForm", editedForm);
    await inquiryService.editInquiry(editedForm).then(
      (res) => {
        showSnackbar(t('inquiry.actions.inquiry_edit'));
        getAllInquiries(1, 8);
      },
      (err) => {
        console.error('error while update');
      }
    );
  };

  const closeActionBox = () => {
    setBoxStateValue('hide');
  };

  const deleteConfirm = async () => {
    let data = {};
    data.inquiry_id = deleteInquiry;
    await inquiryService.deleteInquiry(data).then(
      (res) => {
        const newList = inquiriesToTable.filter(function (el) {
          return el.action.fieldValue._id != deleteInquiry;
        });
        showSnackbar(t('inquiry.actions.inquiry_delete'));
        setInquiriesToTable(newList);
        closeDeleteModalHandler();
      },
      (err) => {
        console.error('delete failed');
      }
    );
  };
  const pageNoClicked = (pageNo) => {
    // if (filterField.name) {
    getAllInquiries(pageNo, pageNo === -1 ? -1 : 8, filterField);
    // } else {
    // getAllInquiries(pageNo, pageNo === -1 ? -1 : 8)
    // }
  };
  const filterList = (event) => {
    clearTimeout(filterTimeout);
    setFilterTimeout(
      setTimeout(() => {
        getAllInquiries(1, 8, filterField);
      }, 2000)
    );
  };
  return (
    <>
      <HeaderWrapper onClick={closeActionBox} className='row'>
        <div
          style={{
            display: 'flex',
            flexGrow: '1'
          }}
        >
          <PageHeader text={t('inquiry.header')} />
        </div>
        <div className='d-flex'>
          <div className='position-relative'>
            <FilterSearch
              type='text'
              placeholder={t('inquiry.filter')}
              onKeyUp={filterList}
              onChange={(e) => setFilterField({ ...filterField, ...{ search: e.target.value } })}
            />
            <p
              style={{
                position: 'absolute',
                top: '5px',
                right: '60px'
              }}
            >
              <Search />
            </p>
          </div>
        </div>
        {user.user.role !== 'professional' && (
          <div>
            <Link to='/home/inquiry_new'>
              <Button
                text={t('inquiry.new_inquiry')}
                disabled={false}
                fullWidth='120%'
                font='10px'
              />
            </Link>
          </div>
        )}
      </HeaderWrapper>
      <div
        style={{
          flexGrow: '1',
          display: 'flex',
          padding: '0 0 0 30px'
        }}
      >
        <PageHeader text={t('inquiry.upcoming')} />
      </div>
      <div style={{ overflow: 'scroll' }}>
        {inquiriesToTable.length > 0 ? (
          <>
            <div
              style={{
                overflow: 'scroll',
                height: '70vh',
                width: '110vw'
              }}
            >
              {LoaderStatus ? (
                <Table
                  boxState={boxStateValue}
                  config={inquiryConfig}
                  data={inquiriesToTable}
                  sort={handleSort}
                  action={getAction}
                  openRespectiveProject={openRespectiveProject}
                  from={'inquiry'}
                ></Table>
              ) : (
                <Loader />
              )}
            </div>
            <BottomPanel>
              <div className='pagination-footer'>
                <Pagination
                  pageNoClicked={pageNoClicked}
                  totalPageNo={totalPageNo}
                  type={'Inquiries'}
                />
              </div>
            </BottomPanel>
          </>
        ) : LoaderStatus ? (
          <></>
        ) : (
          <Loader />
        )}
      </div>
      {inquiriesToTable.length === 0 && <NoProject>{apiProcess.text}</NoProject>}
      {editInquiry && (
        <Modal show={editInquiry} close={closeModalHandler}>
          <EditInquiry inquiry={editInquiry} form={editAction} />
        </Modal>
      )}
      {deleteModalState && (
        <Modal show={deleteModalState} close={closeDeleteModalHandler}>
          <DeletePopUp delete={deleteConfirm} type={type} />
        </Modal>
      )}
    </>
  );
});
export default withTranslation('common')(InquiryDashboard);
