import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

let ButtonWrapper = styled.div`
  left: 70%;
  margin-top: -10px;
  position: absolute;

  input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
`;

let UButton = styled.button`
  border-radius: 50px;
  box-shadow: 0 0.5px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  font-family: Montserrat;
  font-size: 0.8em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.6px;
  text-align: center;
  color: #21a8df;
  border: 0;
  padding: 0.4em 1.2em;
  width: max-content;
  right: 20px;
  position: relative;
  @media (min-width: 1600px) {
    font-size: 14px;
  }
`;
const UploadButton = ({ t, ...props }) => (
  <ButtonWrapper>
    <UButton>{t('universal_actions.upload')}</UButton>
    <input type='file' {...props} />
  </ButtonWrapper>
);

export default withTranslation('common')(UploadButton);
