import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import './common.css';
import Close from '../public/icons/Close';
import Find from '../public/icons/Find';
import Avatar from 'react-avatar';
import { withTranslation } from 'react-i18next';

let DropdownSearch = styled.div`
  display: flex;
  position: absolute;
  width: max-content;
  right: -15px;
  height: 100%;
  p {
    margin: 0px 5%;
    align-self: center;
    width: max-content;
  }
`;
const TeamDropdown = ({ t, ...props }) => {
  const [multiple, setMultiple] = useState(props.multiple);
  const [multipleSelection, setMultipleSelection] = useState(props.selectedMember);
  const [options, setOptions] = useState(props.teamMembersOptions);
  const filterList = (value) => {
    let filterdList = props.teamMembersOptions.filter(
      (team) => value === '' || team.member.firstName?.toLowerCase().includes(value.toLowerCase())
    );
    setOptions(filterdList);
  };

  const checkHighlight = (id, member) => {
    // console.log(multipleSelection)
    if (multiple) {
      if (multipleSelection.some((el) => el.member._id === member.member._id)) {
        let copy = multipleSelection;
        copy = copy.filter((selected) => selected.member._id != member.member._id);
        setMultipleSelection(copy);
        document.getElementById(id).classList.remove('selected');
      } else {
        let copy = multipleSelection;
        copy = copy.concat(member);
        setMultipleSelection(copy);
        document.getElementById(id).classList.add('selected');
      }
    } else {
      props.selectedTeam(member);
    }
  };
  useState(() => {
    if (props.selectedMember) {
      props.selectedMember.map((team) => {
        setTimeout(() => {
          const found = props.teamMembersOptions.some((el) => el.member._id === team.member._id);
          if (found) {
            document.getElementById('multiple' + team.member._id).classList.add('selected');
          }
        }, 0);
      });
    }
  }, []);
  return (
    <>
      <DropdownSearch>
        <p>
          <Find />
        </p>
        {!multiple ? (
          <p onClick={() => props.close()} className='done-select-panel'>
            <p>{t('common.close')}</p>
          </p>
        ) : (
          <p
            onClick={() => {
              props.selectedTeam(multipleSelection);
              props.close();
            }}
            className='done-select-panel'
          >
            <p>{t('common.done')}</p>
          </p>
        )}
      </DropdownSearch>
      <input
        type='text'
        placeholder='Search'
        onKeyUp={(event) => filterList(event.target.value)}
        className='select-search-box__search'
      />
      <ul className='select-search-box__options'>
        {options.length > 0 ? (
          options.map(
            (member, index) =>
              member.member && (
                <li
                  key={'member' + member.member._id}
                  onClick={(e) => checkHighlight('multiple' + member.member._id, member)}
                  id={'multiple' + member.member._id}
                >
                  {member.member.profileImage ? (
                    <img src={member.member.profileImage} alt={member.member.firstName} />
                  ) : (
                    <Avatar
                      size='30px'
                      round='50%'
                      name={member.member.firstName + ' ' + member.member.lastName}
                    />
                  )}
                  <p className='team-member-name'>{member.member.firstName}</p>
                </li>
              )
          )
        ) : (
          <p className='no-records-found'>{t('common.no_record')}</p>
        )}
      </ul>
    </>
  );
};
export default withTranslation('common')(TeamDropdown);
