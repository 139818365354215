import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ProjectDashboard from './ProjectDashboard/ProjectDashboard';
import ProjectDetail from './ProjectDetail/ProjectDetail';

const routes = (
  <Switch>
    <Route path='/home/projects' component={ProjectDashboard} />
    <Route path='/home/projects_details' component={ProjectDetail} />
  </Switch>
);

export default routes;
