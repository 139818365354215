import React from 'react';
import styled from 'styled-components';
import Close from '../public/icons/Close';
let ModalWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  backdrop-filter: blur(1px);
  background-color: rgba(40, 40, 40, 0.75);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

let ModalInnerWrapperProfile = styled.div`
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  height: fit-content;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 0.5px 1.5px 0 rgba(0, 0, 0, 0.04);
  border: solid 0.5px #eaedf3;
  background-color: #ffffff;
  padding: 0;
  overflow: hidden;

  .close-btn {
    position: absolute;
    z-index: 3;
    top: 10px;
    right: 10px;

    span {
      margin-top: 0 !important;
    }
  }

  @media screen and (max-width: 520px) {
    & {
      width: 100%;
      height: 100vh;
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  z-index: 2;

  @media screen and (max-width: 520px) {
    & {
      height: 100%;
    }
  }
`;

let CloseBox = styled.span`
  :hover {
    cursor: pointer;
  }
`;

const Modal2 = (props) => (
  <ModalWrapper>
    <ModalInnerWrapperProfile>
      <div className='row m-0 float-right close-btn'>
        <CloseBox onClick={props.close}>
          <Close />
        </CloseBox>
      </div>
      <ModalContent>{props.children}</ModalContent>
    </ModalInnerWrapperProfile>
  </ModalWrapper>
);

export { Modal2 };
