import React from 'react';

const OverdueIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    id='prefix__Group_2'
    width='20'
    height='20'
    data-name='Group 2'
    viewBox='0 0 34 34'
  >
    <g id='prefix__ic_likes_yellow' data-name='ic_likes yellow'>
      <circle
        id='prefix__Shape'
        cx='17'
        cy='17'
        r='17'
        style={{ opacity: '.1', fill: '#ffc542' }}
      />
    </g>
    <g id='prefix__ic_Dashboard_Copy' data-name='ic_Dashboard Copy' transform='translate(9 9)'>
      <path
        id='prefix__Combined_Shape'
        d='M5.091 16A5.1 5.1 0 010 10.909V5.091A5.1 5.1 0 015.091 0h5.818A5.1 5.1 0 0116 5.091v5.818A5.1 5.1 0 0110.909 16zM1.454 5.091v5.818a3.641 3.641 0 003.637 3.637h5.818a3.641 3.641 0 003.637-3.637V5.091a3.641 3.641 0 00-3.637-3.637H5.091a3.641 3.641 0 00-3.637 3.637zM6.79 10.7L4.572 8.51a.727.727 0 011.022-1.035l1.652 1.63 3.094-3.75a.728.728 0 011.122.926l-3.6 4.363a.726.726 0 01-1.072.056z'
        data-name='Combined Shape'
        style={{ fill: '#ffc542' }}
      />
    </g>
  </svg>
);

export default OverdueIcon;
