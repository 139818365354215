import React from 'react';
import styled from 'styled-components';
import ReactPaginate from 'react-paginate';
import { withTranslation } from 'react-i18next';

let ViewAllText = styled.p`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0.04px;
  color: #4b4d4e;
  padding: 0 10px;
  align-self: center;
  :hover {
    cursor: pointer;
  }
  @media (min-width: 1600px) {
    font-size: 13px;
  }
`;
const Pagination = ({ t, totalPageNo, pageNoClicked, type }) => {
  const handlePageClick = (data) => {
    // let selected = data.selected;
    // let offset = Math.ceil(selected * this.props.perPage);
    // console.log("data", data.selected + 1)
    pageNoClicked(data.selected + 1);
  };
  return (
    <div className='d-flex'>
      <ReactPaginate
        previousLabel={<div className='arrow-left'></div>}
        nextLabel={<div className='arrow-right'></div>}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={totalPageNo}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
      />
      <ViewAllText onClick={() => handlePageClick({ selected: -2 })}>
        {t('common.view_all', { type: type })}
      </ViewAllText>
    </div>
  );
};
export default withTranslation('common')(Pagination);
