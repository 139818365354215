import React from 'react';

const Attachments = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    id='ic_attachment'
    width='24'
    height='24'
    viewBox='0 0 24 24'
  >
    <defs></defs>
    <path
      id='Shape'
      d='M8.215 15.512a2.5 2.5 0 0 1-3.483-3.587l7.778-7.779a.5.5 0 1 1 .707.707l-7.778 7.779a1.5 1.5 0 0 0 2.121 2.121l7.778-7.778a3.5 3.5 0 0 0-4.95-4.95L2.611 9.8a5.5 5.5 0 0 0 7.778 7.778l6.364-6.364a.5.5 0 1 1 .707.707l-6.36 6.368a6.5 6.5 0 0 1-9.244-9.14l7.83-7.831a4.5 4.5 0 1 1 6.364 6.364l-7.83 7.83z'
      style={{ fill: '#92929d' }}
      transform='translate(3.318 1.99)'
    />
  </svg>
);

export default Attachments;
