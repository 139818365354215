import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

let ProgressBar = styled.div`
  box-shadow: 0 0.5px 1.5px 0 rgba(0, 0, 0, 0.04);
  border: solid 0.5px #eaedf3;
  background-color: #ffffff;
  border-radius: 2px;
  height: 3px;
  background-color: #eaedf3;
`;
let ProgressBarStrength = styled.div`
  width: ${(props) =>
    props.passwordStrength === 'weak'
      ? '30%'
      : props.passwordStrength === 'medium'
      ? '60%'
      : props.passwordStrength === 'strong'
      ? '100%'
      : '0%'};
  height: 3px;
  background-color: ${(props) =>
    props.passwordStrength === 'weak'
      ? '#fc5a5a'
      : props.passwordStrength === 'medium'
      ? '#ffc542'
      : props.passwordStrength === 'strong'
      ? '#21a8df'
      : '#fc5a5a'};
  border-radius: 2px;
`;
let StrengthText = styled.p`
  font-family: Montserrat;
  font-size: 9px;
  text-align: left;
  color: #828385;
  margin-top: 10px;
  @media (min-width: 1600px) {
    font-size: 10px;
  }
`;
const PasswordStrength = ({ t, passwordStrength }) => {
  return (
    <>
      <ProgressBar>
        <ProgressBarStrength passwordStrength={passwordStrength}></ProgressBarStrength>
      </ProgressBar>
      <StrengthText>
        {t('common.password_strength')} <strong>{t(`register.${passwordStrength}`)}</strong>
      </StrengthText>
    </>
  );
};
export default withTranslation('common')(PasswordStrength);
