import React from 'react';

const Upload = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0.909091 12.7283C0.407014 12.7283 0 13.1353 0 13.6374V16.3646C0 18.3729 1.62806 20.001 3.63636 20.001H16.3636C18.3719 20.001 20 18.3729 20 16.3646V13.6374C20 13.1353 19.593 12.7283 19.0909 12.7283C18.5888 12.7283 18.1818 13.1353 18.1818 13.6374V16.3646C18.1818 17.3688 17.3678 18.1828 16.3636 18.1828H3.63636C2.63221 18.1828 1.81818 17.3688 1.81818 16.3646V13.6374C1.81818 13.1353 1.41117 12.7283 0.909091 12.7283Z'
      fill='#21A8DF'
    />
    <path
      d='M10 15.2987C10.5021 15.2987 10.9091 14.8917 10.9091 14.3896V3.03962L13.0068 5.04644C13.3696 5.39413 13.9455 5.38192 14.2932 5.01917C14.6409 4.65641 14.6287 4.08049 14.2659 3.7328L10.6296 0.253256C10.2778 -0.0844188 9.72225 -0.0844188 9.37048 0.253256L5.73411 3.7328C5.37136 4.08049 5.35915 4.65641 5.70684 5.01917C6.05453 5.38192 6.63045 5.39413 6.99321 5.04644L9.09093 3.03962V14.3896C9.09093 14.8917 9.49795 15.2987 10 15.2987Z'
      fill='#21A8DF'
    />
  </svg>
);

export default Upload;
