import React, { useCallback, useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { ProfessionalTasksConfig } from '../../public/configuration';
import { ProfessionalTasksConfig as ProfessionalTasksConfigJP } from '../../public/configurationJP';
import { Loader, PageHeader } from '../../common';
import { projectService } from '../../_services/project.service';
import { wrapComponent } from 'react-snackbar-alert';
import { UserContext } from '../../common/context/UserContext';
import Pagination from '../../common/Pagination';
import { withTranslation } from 'react-i18next';
import Table from '../../common/Table';

let BottomPanel = styled.div`
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    div.button-footer{
        position: absolute;
    right: 50px;
    bottom: 0;
    }

}
`;
let NoProject = styled.div`
  font-weight: 600;
  text-align: left;
  font-size: 15px;
  margin: 40px;
  font-family: Montserrat;
`;
let HeaderWrapper = styled.div`
  // padding: 50px 70px 0 25px;
`;
const ProfessionalTaskPanel = wrapComponent(({ t, i18n, createSnackbar, projectDetails }) => {
  const [{ user, dropdown }, dispatch] = useContext(UserContext);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [selectedTask, setSelectedTask] = useState([]);
  const [totalPageNo, setTotalPageNo] = useState();
  const [notaskPresent, setNotaskPresent] = useState(false);
  const columns = [
    'checkbox',
    'taskname',
    'category',
    'project',
    'assigneedBy',
    'startdate',
    'duedate',
    'status'
  ];
  const [updateTask, setUpdateTask] = useState();
  useEffect(() => {
    getTasks(1, 8);
  }, []);

  const getStatus = (status) => {
    if (status === 'not_started') {
      return t('projectStatus.not_started');
    } else if (status === 'in_progress') {
      return t('projectStatus.in_progress');
    } else if (status === 'completed') {
      return t('projectStatus.completed');
    } else {
      return '';
    }
  };

  const showSnackbar = (msg, theme) => {
    createSnackbar({
      theme: 'info',
      message: msg,
      timeout: 2000,
      dismissable: false,
      pauseOnHover: true,
      progressBar: false,
      sticky: false
    });
  };

  const getTasks = (page, limit) => {
    let data = {};
    if (limit === 0) {
      data = { page: page };
    } else {
      data = { page: page, limit: limit };
    }
    projectService.getProfessionalTask(data).then(
      (response) => {
        if (response.data.result.length > 0) {
          // console.log("response", response)
          for (let i = 0; i < document.getElementsByClassName('tasks-checkbox').length; i++) {
            document.getElementsByClassName('tasks-checkbox')[i].checked = false;
            document
              .getElementsByClassName('tasks-checkbox')
              [i].parentElement.parentElement.parentElement.childNodes[1].classList.remove(
                'checkbox-bold'
              );
          }
          setTotalPageNo(Math.ceil(response.data.totalCount / 10));
          let dataToSend = [];
          response.data.result.map((field, index) => {
            dataToSend.push({
              checkbox: { type: 'checkbox', value: field.project, fieldValue: field },
              taskname: { type: 'string', value: field.name, fieldValue: field },
              category: { type: 'string', value: field.category, fieldValue: field },
              project: { type: 'string', value: field.project.name, fieldValue: field },
              assigneedBy: { type: 'string&image', value: field.creator, fieldValue: field },
              startdate: { type: 'date', value: field.startDate, fieldValue: field },
              duedate: { type: 'date', value: field.endDate, fieldValue: field },
              status: { type: 'string', value: getStatus(field.status), fieldValue: field },
              action: {
                type: user.user.role === 'facilitator' ? 'close' : '',
                value: '',
                fieldValue: field
              }
            });
          });
          setDataToDisplay(dataToSend);
          setNotaskPresent(false);
        } else {
          setNotaskPresent(true);
        }
      },
      (err) => {
        console.error('failed to load task');
      }
    );
  };

  const [projectConfig, setProjectConfig] = useState(ProfessionalTasksConfigJP);

  const handleSort = useCallback((index) => {
    let newConfigSortChange = [...projectConfig];
    newConfigSortChange[index].config.sortType = !newConfigSortChange[index].config.sortType;
    setProjectConfig(newConfigSortChange);
    setDataToDisplay(
      getSortedData(dataToDisplay, columns[index], newConfigSortChange[index].config.sortType)
    );
  });

  const getSortedData = (data, prop, isAsc) => {
    return data.sort(
      (a, b) =>
        (a[prop].value.toLowerCase() < b[prop].value.toLowerCase() ? -1 : 1) * (isAsc ? 1 : -1)
    );
  };

  const updateTaskForm = async (form) => {
    await projectService.editTask(form).then(
      (response) => {
        // console.log("response update task", response)
        showSnackbar('Task Updated Successfully');
        setUpdateTask();
        //   setShowAddTaskModal(false)
        getTasks(totalPageNo, 8);
      },
      (err) => {
        console.error('error', err);
      }
    );
  };

  const checkedTasks = [];
  const checkboxHandler = (checked, field, event) => {
    if (checked) {
      event.target.parentElement.parentElement.parentElement.childNodes[1].classList.add(
        'checkbox-bold'
      );
      checkedTasks.push(field);
    } else {
      const index = checkedTasks.indexOf(field);
      if (index > -1) {
        event.target.parentElement.parentElement.parentElement.childNodes[1].classList.remove(
          'checkbox-bold'
        );
        checkedTasks.splice(index, 1);
      }
    }
  };

  const updateTaskStatus = (data) => {
    projectService.updateTasks(data).then(
      (response) => {
        // console.log("resoposne", response)
        getTasks(1, 8);
      },
      (err) => {
        console.error('error  while updating task', err);
      }
    );
  };
  const selectedFilterValue = (statusValue) => {
    let data = {
      tasks: [],
      status: statusValue,
      project_id: []
    };
    checkedTasks.map((task) => {
      data.tasks.push(task._id);
      data.project_id.push(task.project._id);
    });
    // console.log('data')
    // console.log(data)
    updateTaskStatus(data);
  };

  const pageNoClicked = (pageNo) => {
    getTasks(pageNo, pageNo === -1 ? -1 : 8);
  };

  const updateTaskModal = (field) => {
    // console.log("field", field)
    // setShowAddTaskModal(true)
    setUpdateTask(field);
  };

  const deleteDate = async (field) => {
    let data = {};
    data.task_id = field._id;
    await projectService.deleteTask(data).then(
      (response) => {
        showSnackbar(t('task.delete_task'));
        getTasks(totalPageNo, 8);
      },
      (err) => {
        console.error('error', err);
      }
    );
  };

  return (
    <>
      <HeaderWrapper>
        <div
          style={{
            display: 'flex',
            flexGrow: '1',
            padding: '50px 25px 0 25px'
          }}
        >
          <PageHeader text={t('task.header')} />
        </div>
        <div className='project-details-tabs'>
          {dataToDisplay.length > 0 ? (
            <>
              <Table
                config={projectConfig}
                data={dataToDisplay}
                sort={handleSort}
                menuFilterStatus={dropdown.TaskStatus.slice(1)}
                checkboxSelection={checkboxHandler}
                selectedFilterValue={selectedFilterValue}
                updateTaskModal={updateTaskModal}
                deleteDate={deleteDate}
              ></Table>

              <BottomPanel>
                <div>
                  <Pagination
                    pageNoClicked={pageNoClicked}
                    totalPageNo={totalPageNo}
                    type={'Tasks'}
                  />
                </div>
              </BottomPanel>
            </>
          ) : notaskPresent ? (
            <NoProject> {t('task.no_task')}</NoProject>
          ) : (
            <Loader />
          )}
        </div>
      </HeaderWrapper>
    </>
  );
});
export default withTranslation('common')(ProfessionalTaskPanel);
