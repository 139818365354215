import React from 'react';
import { Switch, Route } from 'react-router-dom';
import InquiryDashboard from './InquiryDashboard/InquiryDashboard';
import NewInquiry from './NewInquiry/NewInquiry';

const routes = (
  <Switch>
    <Route path='/home/inquiries' exact component={InquiryDashboard} />
    <Route path='/home/inquiries/new' exact component={NewInquiry} />
  </Switch>
);

export default routes;
