import React from 'react';

const Download = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    id='Group_12_Copy'
    width='20'
    height='20.001'
    data-name='Group 12 Copy'
    viewBox='0 0 20 20.001'
  >
    <defs></defs>
    <path
      id='Path'
      d='M.909 0A.909.909 0 0 0 0 .909v2.727a3.636 3.636 0 0 0 3.636 3.637h12.728A3.636 3.636 0 0 0 20 3.636V.909a.909.909 0 0 0-1.818 0v2.727a1.817 1.817 0 0 1-1.818 1.818H3.636a1.818 1.818 0 0 1-1.818-1.818V.909A.909.909 0 0 0 .909 0z'
      style={{ fill: '#21a8df' }}
      transform='translate(0 12.728)'
    />
    <path
      id='Path-2'
      d='M4.546-15.3a.909.909 0 0 1 .909.909V-3.04l2.1-2.007a.91.91 0 0 1 1.286.027.91.91 0 0 1-.027 1.286L5.176-.253a.909.909 0 0 1-1.259 0L.28-3.733a.91.91 0 0 1-.027-1.286.91.91 0 0 1 1.286-.027l2.1 2.007V-14.39a.909.909 0 0 1 .907-.91z'
      style={{ fill: '#21a8df' }}
      data-name='Path'
      transform='translate(5.454 15.299)'
    />
  </svg>
);

export default Download;
