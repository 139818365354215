import React from 'react';
import styled from 'styled-components';

let TextBoxArea = styled.textarea`
  border: 1px solid #e5e5e5 !important;
  border-radius: 3px;
  font-family: Montserrat;
  font-size: 11px;
  line-height: 16px;
  text-align: left;
  color: #4b4d4e;
  border-radius: 12px;
  border: solid 0.5px #979797;
  background-color: #ffffff;
  padding: 10px;
  width: ${(props) => (props.width ? props.width : '')};
  resize: none;
  display: flex;
  @media (min-width: 1600px) {
    font-size: 12px;
  }
`;
let LabelField = styled.label`
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #4b4d4e;
  margin-top: 15px;
  margin-bottom: 5px;
  display: flex;
  @media (min-width: 1600px) {
    font-size: 12px;
  }
`;

const TextArea = ({ rows, cols, placeholder, text, ...props }) => (
  <>
    <LabelField>{text}</LabelField>
    <TextBoxArea rows={rows} cols={cols} {...props} placeholder={placeholder}></TextBoxArea>
  </>
);

export { TextArea };
