import React from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ScheduleCalendar from '../public/icons/ScheduleCalendar';

let CalendarIcon = styled.div`
  position: absolute;
  right: 5px;
  top: 0;
`;
const Calendar = (props) => (
  <>
    <DatePicker {...props} />
    <CalendarIcon>
      <ScheduleCalendar />
    </CalendarIcon>
  </>
);

export { Calendar };
