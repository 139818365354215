import React from 'react';

const FilterIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='4' height='18' viewBox='0 0 4 18'>
    <defs>
      <style></style>
    </defs>
    <g id='ic_More' transform='rotate(90 2 2)'>
      <path
        id='Shape'
        d='M0 16a2 2 0 1 1 2 2 2 2 0 0 1-2-2zm0-7a2 2 0 1 1 2 2 2 2 0 0 1-2-2zm0-7a2 2 0 1 1 2 2 2 2 0 0 1-2-2z'
        style={{ fill: '#21a8df' }}
        transform='rotate(-90 2 2)'
      />
    </g>
  </svg>
);

export default FilterIcon;
