import React from 'react';
import Group from '../public/icons/Group.svg';
import styled from 'styled-components';

let HeaderName = styled.h2`
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #282828;
  width: 100%;
  text-transform: uppercase;
  @media (min-width: 1600px) {
    font-size: 17px;
  }
`;
const LoginRegisterHeader = ({ text }) => (
  <div className='row justify-content-center'>
    <img src={Group} alt='Logo' style={{ margin: '20px' }} />
    <HeaderName>{text}</HeaderName>
  </div>
);

export { LoginRegisterHeader };
