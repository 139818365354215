import React, { useCallback, useEffect, useState, useContext } from 'react';
import ProjectMessagesPanel from '../Project/ProjectDetail/ProjectMessagesPanel';
import { UserContext } from '../../common/context/UserContext';
import { authenticationService } from '../../_services';

const Messages = (props) => {
  const [{ user }, dispatch] = useContext(UserContext);
  const [chatmembers, setChatmembers] = useState([]);
  let temp = {
    sendBirdChannelURL: user.user.sendBirdChannelURL,
    _id: user.user._id
  };
  useEffect(() => {
    authenticationService.mainMessagesMembers().then((response) => {
      let temp = [];
      response.data.result.map((team) => {
        temp.push({ member: team });
      });
      setChatmembers([...chatmembers, ...temp]);
    });
  }, []);
  return (
    <>
      {chatmembers.length > 0 && (
        <ProjectMessagesPanel projectDetails={temp} from='main' mainChatMemebers={chatmembers} />
      )}
    </>
  );
};
export { Messages };
