import React, { useReducer } from 'react';

const initialState = {
  user: null,
  dropdownAll: null,
  dropdown: null,
  webflowLinks: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER_DETAILS':
      return { ...state, user: action.payload, dropdownAll: action.dropdownAll };
    case 'SET_DROPDOWN':
      return { ...state, dropdown: action.dropdown };
    case 'SET_WEBFLOW':
      return { ...state, webflowLinks: action.webflowLinks };
    default:
      return state;
  }
};

export const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
  return (
    <UserContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </UserContext.Provider>
  );
};
