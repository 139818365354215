import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { authenticationService } from '../_services';
import { CardLanding, Input, Dropdown, Button, LoginRegisterHeader } from '../common';
import styled from 'styled-components';
import { FormProgress } from '../common/FormProgress';
import countryList from 'react-select-country-list';
import validator from 'validator';
import { wrapComponent } from 'react-snackbar-alert';
import moment from 'moment-timezone';
import { withTranslation } from 'react-i18next';
import PasswordStrength from '../common/PasswordStrength';
import 'moment/locale/ja';
import 'moment/locale/en-ca';

let BottomSection = styled.div`
  bottom: 20px;
  position: inherit;
  left: 0;
  width: inherit;
`;
let BackButton = styled.button`
  position: absolute;
  bottom: 0;
  border: 0;
  background: none;
  left: 20px;
  font-family: Montserrat;
  font-size: 11px;
  line-height: 1.3;
  color: #4b4d4e;
  text-decoration: underline;
  @media (min-width: 1600px) {
    font-size: 12px;
  }
`;

let FormDetails = styled.form`
  margin: 10% 0 15% 0;
`;
let PasswordText = styled.p`
  font-family: Montserrat;
  font-size: 9px;
  line-height: 1.22;
  text-align: left;
  color: #828385;
  width: 90%;
  margin: 0 10px;
`;
let PasswordNotMatched = styled.p`
  font-family: Montserrat;
  font-size: 9px;
  line-height: 1.22;
  text-align: left;
  width: 90%;
  margin: 10px;
  color: red;
`;
let AlreadySignInOption = styled.div`
  position: absolute;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  left: 0;
  font-family: Montserrat;

  b {
    color: #21a8df;
    font-weight: 700;
    cursor: pointer;
    text-decoration: underline;
  }
`;
const RegisterPage = wrapComponent(({ t, i18n, createSnackbar, ...props }) => {
  moment.locale('ja');
  const phnRegx = new RegExp(/^[0-9]{10,12}$/im);
  const passwordRgx = new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[*.!@$%^&(){}[\]:;<>,.?\/~_+-=|])[A-Za-z\d*.!@$%^&(){}[\]:;<>,.?\/~_+-=|]{8,}$/
  );

  const [dropdown, setDropdown] = useState([]);
  useEffect(() => {
    authenticationService.getDropDown().then(
      (dropdownResponse) => {
        dropdownResponse.data.englishData.markets.splice(0, 0, { value: '', label: 'Select' });
        dropdownResponse.data.japaneseData.markets.splice(0, 0, { value: '', label: '選ぶ' });

        dropdownResponse.data.englishData.categories.splice(0, 0, { value: '', label: 'Select' });
        dropdownResponse.data.japaneseData.categories.splice(0, 0, { value: '', label: '選ぶ' });
        setDropdown(dropdownResponse.data);
      },
      (err) => {
        showSnackbar(err, 'error');
      }
    );
  }, []);

  const [registrationForm, setRegistrationForm] = useState({
    role: props.userType,
    emailId: '',
    firstName: '',
    lastName: '',
    country: countryList().getData()[0].label,
    phoneNumber: '',
    companyName: '',
    position: '',
    marketInterests: '',
    projectInterests: '',
    password: '',
    confirmPassword: '',
    market: '',
    category: '',
    currentFormStage: 1
  });

  const showSnackbar = (msg, theme) => {
    createSnackbar({
      theme,
      message: msg,
      timeout: 3000,
      dismissable: false,
      pauseOnHover: true,
      progressBar: false,
      sticky: false
    });
  };

  const [passwordStrength, setPasswordStrength] = useState('weak');

  const [errorFlag, setErrorFlag] = useState({
    companyName: '',
    position: '',
    emailId: '',
    firstName: '',
    lastName: '',
    country: '',
    phoneNumber: ''
  });

  const countriesList = useMemo(() => {
    const vip = [];
    const list = countryList()
      .getData()
      .filter((item) => {
        const match = ['US', 'JP', 'FR'].includes(item.value);
        if (match) {
          vip.push(item);
        }
        return !match;
      });

    const vipOrder = {
      JP: 1,
      US: 2,
      FR: 3
    };
    vip.sort((a, b) => {
      return vipOrder[a.value] - vipOrder[b.value];
    });

    return [...vip, ...list];
  }, []);

  const validateFormStep = () => {
    if (registrationForm.currentFormStage == 1) {
      if (
        registrationForm.emailId &&
        validator.isEmail(registrationForm.emailId) &&
        registrationForm.phoneNumber &&
        phnRegx.test(registrationForm.phoneNumber) &&
        registrationForm.firstName &&
        registrationForm.lastName &&
        registrationForm.country
      ) {
        return true;
      }
    } else if (registrationForm.currentFormStage == 2 && props.userType == 'client') {
      if (registrationForm.companyName && registrationForm.position) {
        return true;
      }
    } else if (registrationForm.currentFormStage == 3 && props.userType == 'client') {
      if (registrationForm.marketInterests && registrationForm.projectInterests) {
        return true;
      }
    } else if (registrationForm.currentFormStage == 4) {
      if (
        registrationForm.password &&
        passwordRgx.test(registrationForm.password) &&
        registrationForm.confirmPassword &&
        registrationForm.password === registrationForm.confirmPassword
      ) {
        return true;
      }
    }
    return false;
  };

  const submit = useCallback(() => {
    let data = {
      role: props.userType,
      email: registrationForm.emailId,
      password: registrationForm.password,
      cpassword: registrationForm.confirmPassword,
      firstName: registrationForm.firstName,
      lastName: registrationForm.lastName,
      country: registrationForm.country,
      phone: registrationForm.phoneNumber,
      market: registrationForm.marketInterests,
      category: registrationForm.projectInterests,
      timeZone: moment.tz.guess()
    };
    if (props.userType == 'client') {
      data.company = {
        name: registrationForm.companyName,
        position: registrationForm.position
      };
      data.eisKey = 'someone@eis.com';
      data.eisSecret = 'some_password';
      authenticationService.register(data).then(
        (response) => {
          showSnackbar('Registered Successfully', 'info');
          props.history.push('/login');
        },
        (error) => {
          if (error && error.response && error.response.data) {
            error = error.response.data.error;
            showSnackbar(error, 'error');
          }
        }
      );
    } else {
      data.eisEmployeeSecret = 'some_password';
      authenticationService.registerProfessional(data).then(
        (response) => {
          props.history.push('/login');
          showSnackbar('Registered Successfully', 'info');
        },
        (error) => {
          if (error && error.response && error.response.data) {
            error = error.response.data.error;
            showSnackbar(error, 'error');
          }
        }
      );
    }
  });

  const fieldChange = useCallback((event) => {
    let data = {};
    if (event.target.value != ' ') {
      data[event.target.name] = event.target.value;
      setRegistrationForm({ ...registrationForm, ...data });
    }
  });

  const validateField = (event) => {
    let temp = {
      ...errorFlag
    };
    temp[event.target.name] = false;
    if (!event.target.value || (event.target.value + '').length < 2) {
      temp[event.target.name] = t('universal_actions.invalid_field');
    } else {
      if (event.target.name == 'emailId' && !validator.isEmail(event.target.value)) {
        temp[event.target.name] = t('universal_actions.invalid_field');
      }
      if (event.target.name == 'phoneNumber' && !phnRegx.test(event.target.value)) {
        if (event.target.value.includes('-')) {
          temp[event.target.name] = t('universal_actions.invalid_phone3');
        } else if (event.target.value.startsWith('+')) {
          temp[event.target.name] = t('universal_actions.invalid_phone2');
        } else if (RegExp(/[A-Za-z]/).test(event.target.value)) {
          temp[event.target.name] = t('universal_actions.invalid_phone');
        } else {
          temp[event.target.name] = t('universal_actions.invalid_field');
        }
      }
    }

    setErrorFlag(temp);
  };

  const triggerNextForm = useCallback(async (type) => {
    let verify = true;
    if (type === 'next') {
      verify = validateFormStep();
    }
    if (verify && registrationForm.currentFormStage === 1 && props.userType == 'client') {
      let result = await authenticationService.checkEmailExist({ email: registrationForm.emailId });
      if (result.data.result !== 0) {
        verify = false;
        let temp = { ...errorFlag };
        temp['emailId'] = 'Email Already Exist';
        setErrorFlag(temp);
      }
    }
    if (verify) {
      let step;
      if (props.userType === 'client') {
        step = 1;
      } else {
        step = 3;
      }
      if (type === 'next') {
        if (registrationForm.currentFormStage < 4) {
          setRegistrationForm({
            ...registrationForm,
            currentFormStage: registrationForm.currentFormStage + step
          });
        } else {
          submit();
          setRegistrationForm({ ...registrationForm, currentFormStage: 4 });
        }
      } else {
        if (registrationForm.currentFormStage < 1) {
          setRegistrationForm({ ...registrationForm, currentFormStage: 1 });
        } else {
          setRegistrationForm({
            ...registrationForm,
            currentFormStage: registrationForm.currentFormStage - step
          });
        }
      }
    }
  });
  const validatePassword = useCallback((event) => {
    let password = event.target.value;
    // Do not show anything when the length of password is zero.
    if (password.length === 0) {
      return;
    }
    const mediumPasswordPattern = new RegExp(
      /^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]+)|(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[@$!%*#?&])[A-Za-z@$!%*#?&]+)|(?:(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Z\d@$!%*#?&]+)|(?:(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[a-z\d@$!%*#?&]+)$/
    );
    if (password.match(passwordRgx)) {
      setPasswordStrength('strong');
    } else if (password.match(mediumPasswordPattern)) {
      setPasswordStrength('medium');
    } else {
      setPasswordStrength('weak');
    }
  });

  return (
    <>
      <CardLanding>
        <LoginRegisterHeader text={t('register.header', { type: props.userTypeToDisplay })} />
        {props.userType == 'client' && (
          <FormProgress totalForms={4} completed={registrationForm.currentFormStage}></FormProgress>
        )}
        {props.userType == 'professional' && (
          <FormProgress
            totalForms={2}
            completed={registrationForm.currentFormStage == 4 ? 2 : 1}
          ></FormProgress>
        )}
        {registrationForm.currentFormStage === 1 && (
          <FormDetails name='form'>
            <Input
              text={t('register.email')}
              error={errorFlag.emailId}
              type='email'
              name='emailId'
              value={registrationForm.emailId}
              onChange={fieldChange}
              onBlur={validateField}
              placeholder={t('register.email_placeholder')}
            />
            <Input
              text={t('register.first_name')}
              error={errorFlag.firstName}
              type='text'
              name='firstName'
              value={registrationForm.firstName}
              onChange={fieldChange}
              onBlur={validateField}
              placeholder={t('register.first_name_placeholder')}
            />
            <Input
              text={t('register.last_name')}
              type='text'
              error={errorFlag.lastName}
              name='lastName'
              value={registrationForm.lastName}
              onChange={fieldChange}
              onBlur={validateField}
              placeholder={t('register.last_name_placeholder')}
            />
            <Dropdown
              text={t('register.country')}
              error={errorFlag.country}
              type='country'
              name='country'
              value={registrationForm.country}
              onChange={fieldChange}
              onBlur={validateField}
              options={countriesList}
            />
            <Input
              text={t('register.phone_number')}
              type='text'
              error={errorFlag.phoneNumber}
              name='phoneNumber'
              value={registrationForm.phoneNumber}
              onChange={fieldChange}
              onBlur={validateField}
              placeholder={t('register.phone_number_placeholder')}
            />
          </FormDetails>
        )}
        {registrationForm.currentFormStage === 2 && props.userType == 'client' && (
          <FormDetails name='form'>
            <Input
              text={t('register.company_name')}
              type='companyName'
              error={errorFlag.companyName}
              name='companyName'
              value={registrationForm.companyName}
              onChange={fieldChange}
              onBlur={validateField}
              placeholder={t('register.company_name_placeholder')}
            />
            <Input
              text={t('register.position')}
              type='text'
              name='position'
              error={errorFlag.position}
              value={registrationForm.position}
              onChange={fieldChange}
              onBlur={validateField}
              placeholder={t('register.position_placeholder')}
            />
          </FormDetails>
        )}
        {registrationForm.currentFormStage === 3 && props.userType == 'client' && (
          <FormDetails name='form'>
            <Dropdown
              text={t('register.market_interests')}
              type='marketInterests'
              name='marketInterests'
              value={registrationForm.marketInterests}
              onChange={fieldChange}
              onBlur={validateField}
              options={dropdown.japaneseData.markets}
            />
            <Dropdown
              text={t('register.project_interests')}
              type='projectInterests'
              name='projectInterests'
              value={registrationForm.projectInterests}
              onChange={fieldChange}
              onBlur={validateField}
              options={dropdown.japaneseData.categories}
            />
          </FormDetails>
        )}
        {registrationForm.currentFormStage === 4 && (
          <FormDetails name='form'>
            <Input
              text={t('register.password')}
              type='password'
              name='password'
              value={registrationForm.password}
              onChange={fieldChange}
              onBlur={validateField}
              placeholder={t('register.password_placeholder')}
            />
            <Input
              text={t('register.confirm_password')}
              type='password'
              name='confirmPassword'
              onKeyUp={validatePassword}
              value={registrationForm.confirmPassword}
              onChange={fieldChange}
              onBlur={validateField}
              placeholder={t('register.confirm_password_placholder')}
            />

            {registrationForm.password && registrationForm.confirmPassword && (
              <>
                <PasswordStrength passwordStrength={passwordStrength} />
                {registrationForm.password != registrationForm.confirmPassword && (
                  <PasswordNotMatched>{t('register.password_match')}</PasswordNotMatched>
                )}
              </>
            )}
            <PasswordText>{t('register.password_text')}</PasswordText>
          </FormDetails>
        )}
        <BottomSection>
          <Button
            disabled={!validateFormStep()}
            text={
              registrationForm.currentFormStage === 4
                ? t('universal_actions.done')
                : t('universal_actions.next')
            }
            onClick={() => triggerNextForm('next')}
          />
          {registrationForm.currentFormStage > 1 && (
            <BackButton className='button icon-left' onClick={() => triggerNextForm('back')}>
              &#8592; {t('universal_actions.back')}
            </BackButton>
          )}
        </BottomSection>
        <AlreadySignInOption>
          <p>
            <span>{t('register.already_account')}</span>{' '}
            <b onClick={() => props.history.push('/login')}>{t('universal_actions.sign_in')}</b>{' '}
          </p>
        </AlreadySignInOption>
      </CardLanding>
    </>
  );
});

export default withTranslation('common')(RegisterPage);
