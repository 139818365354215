import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Avatar from 'react-avatar';

let MemberIcon = styled.div`
  border-radius: 50%;
  margin-right: 5px;
  height: 29px;
  width: 30px;
  overflow: hidden;
  border: ${(props) => (props.number ? 'solid 0.5px #4b4d4e' : 'none')};
  img {
    height: 29px;
    width: 30px;
    border-radius: 50%;
  }
  p {
    align-self: center;
    padding: 7px 0;
    text-align: center;
    overflow: hidden;
    height: 100%;
    line-height: 1.25;
    color: #4b4d4e;
    font-family: Montserrat;
    font-size: 12px;
    @media (min-width: 1600px) {
      font-size: 13px;
    }
  }
`;
const TeamMembersIcon = ({ team, maxTeam }) => {
  const [contacts, setContacts] = useState([]);
  useEffect(() => {
    let refinedTeam = team.filter((member) => member.member != null);
    setContacts(refinedTeam);
  }, [team]);
  return (
    <>
      {contacts.length > 0 && (
        <>
          {contacts.slice(0, maxTeam - 1).map((icon, index) => (
            <MemberIcon key={'profile-icon' + index}>
              {icon.member.profileImage ? (
                <img src={icon.member.profileImage} />
              ) : (
                <Avatar
                  size='30px'
                  round='50%'
                  name={icon.member.firstName + ' ' + icon.member.lastName}
                />
              )}
            </MemberIcon>
          ))}
          {contacts.length >= maxTeam && (
            <>
              <MemberIcon number={true}>
                <p>+{contacts.length - (maxTeam - 1)}</p>
              </MemberIcon>
            </>
          )}
        </>
      )}
    </>
  );
};

export { TeamMembersIcon };
