import React, { useEffect, useState } from 'react';
import { Route, Switch, BrowserRouter, withRouter, Redirect } from 'react-router-dom';
import { history } from './../_helpers';
import { HomePage } from '../components';
import RegisterPage from './RegisterPage';
import ResetPage from './ResetPage';
import PasswordPage from './PasswordPage';
import LoginPage from './LoginPage';
import { UserProvider } from '../common/context/UserContext';
import { SnackbarProvider } from 'react-snackbar-alert';
import CustomSnackbarComponent from '../common/Snackbar';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { VerifyPage } from './VerifyPage';
import PrivateRoute from '../_components/PrivateRoute';
import { authenticationService } from '../_services';
import TextFileReader from './TextFileReader';
import { GTM } from '../common/GTM';

const App = ({ t, i18n }) => {
  const reload = () => {};
  return (
    <SnackbarProvider component={CustomSnackbarComponent}>
      <UserProvider>
        <GTM />
        <BrowserRouter history={history}>
          <Switch>
            <Route
              path={
                process.env.PUBLIC_URL +
                '/.well-known/pki-validation/0F3B5245D390D7C3B603F7E6C17B060C.txt'
              }
              render={(props) => <TextFileReader {...props} />}
            />

            <Route path={['/', '/login']} exact component={LoginPage} />
            <Route
              path='/professional-register'
              render={(props) => (
                <RegisterPage
                  {...props}
                  userType='professional'
                  userTypeToDisplay={t('universal_actions.professional')}
                />
              )}
            />
            <Route
              path='/register'
              render={(props) => (
                <RegisterPage
                  {...props}
                  userType='client'
                  userTypeToDisplay={t('universal_actions.client')}
                />
              )}
            />
            <Route path={'/forgot-password'} component={ResetPage} />
            <Route
              path='/reset-password/:id/:token'
              render={(props) => <PasswordPage {...props} />}
            />
            <Route path='/verify' render={(props) => <VerifyPage {...props} />} />
            <PrivateRoute path='/home' component={withRouter(HomePage)} />
            <Redirect from='*' to='/login' />
          </Switch>
        </BrowserRouter>
      </UserProvider>
    </SnackbarProvider>
  );
};

export default withTranslation('common')(App);
