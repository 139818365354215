import React from 'react';
import styled from 'styled-components';

let RoundDropdown = styled.select`
  border-radius: 1em;
  box-shadow: inset 0 0 1.5px 0 rgba(0, 0, 0, 0.09);
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '#fbfbfb')};
  padding: 5px 35px 5px 10px;
  border: ${(props) => (props.border ? props.border : 'none')};
  font-family: Montserrat;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => (props.color ? props.color : 'rgba(75, 77, 78, 1)')};
  width: ${(props) => (props.width ? props.width : '100%')};
  @media (min-width: 1600px) {
    font-size: 12px;
  }
`;
let InputPanel = styled.div`
  display: grid;
  margin: ${(props) => (props.margin ? props.margin : '')};
`;
let LabelField = styled.label`
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #4b4d4e;
  margin-bottom: 5px;
  @media (min-width: 1600px) {
    font-size: 12px;
  }
`;
const Dropdown = ({ type, text, options, value, arrayType, ...props }) => (
  <InputPanel className={'form-group'} {...props}>
    <LabelField htmlFor={type}>{text}</LabelField>
    <RoundDropdown value={value} {...props}>
      {!arrayType &&
        options.map(({ value, label }, index) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      {arrayType === 'string' &&
        options.map((item, index) => (
          <option value={item} key={index}>
            {item}
          </option>
        ))}
    </RoundDropdown>
  </InputPanel>
);

export { Dropdown };
