import React from 'react';
import styled from 'styled-components';

let ActioBox = styled.div`
  position: absolute;
  z-index: 1;
  display: ${(props) => (props.selected ? '' : 'none')};
  ul {
    padding: 0;
    list-style-type: none;
    width: 100px;
    margin-left: -60px;
    box-shadow: 0 2.5px 7.5px 0 rgba(68, 68, 79, 0.1);
    border: solid 0.5px #f8f8f8;
    background-color: #ffffff;
    border-radius: 5px;
    margin-top: 0;
    li {
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: start;
      font-family: Montserrat;
      font-size: 11px;
      font-weight: 500;
      line-height: 1.17;
      letter-spacing: 0.04px;
      color: #828385;
      padding: 0 10px;
      border-bottom: solid 0.5px #f8f8f8;
      @media (min-width: 1600px) {
        font-size: 12px;
      }
    }
  }
`;
let TooltipUp = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #ffffff;
  position: absolute;
  top: -4px;
  left: 12px;
`;

const ActionPanel = ({ selected, options, triggerAction, fieldValue }) => (
  <ActioBox selected={selected}>
    <TooltipUp />
    <ul>
      {options.map((value, index) => (
        <li onClick={() => triggerAction(value, fieldValue)} key={'action' + index}>
          {value}
        </li>
      ))}
    </ul>
  </ActioBox>
);

export { ActionPanel };
