import React from 'react';
import styled from 'styled-components';

let RoundInput = styled.input`
  border-radius: 1em;
  box-shadow: inset 0 0 1.5px 0 rgba(0, 0, 0, 0.09);
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '#fbfbfb')};
  padding: 5px 10px;
  border: ${(props) => (props.border ? props.border : 'none')};
  font-family: Montserrat;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => (props.color ? props.color : 'rgba(75, 77, 78, 1)')};
  width: ${(props) => (props.width ? props.width : '100%')};
  @media (min-width: 1600px) {
    font-size: 12px;
  }
`;
let InputPanel = styled.div`
  display: grid;
  font-family: 'Montserrat';
  font-weight: normal;
  font-style: normal;
  position: relative;
`;
let LabelField = styled.label`
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #4b4d4e;
  margin-bottom: 5px;
  @media (min-width: 1600px) {
    font-size: 12px;
  }
`;
let ErrorPTag = styled.p`
  color: red;
  position: absolute;
  top: 46px;
  left: 2px;
  font-size: 9px;
  @media (min-width: 1600px) {
    font-size: 10px;
  }
`;

const Input = ({ text, type, error, ...props }) => (
  <InputPanel className={'form-group'}>
    <LabelField htmlFor={type}>{text}</LabelField>
    <RoundInput {...props} type={type} />
    {error && <ErrorPTag>{error}</ErrorPTag>}
  </InputPanel>
);

export { Input };
