import React, { useState, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { Button, Dropdown } from '../../../common';
import { UserContext } from '../../../common/context/UserContext';
import { wrapComponent } from 'react-snackbar-alert';
import Avatar from 'react-avatar';
import NumberFormat from 'react-number-format';
import { withTranslation } from 'react-i18next';

let HeaderModal = styled.p`
  font-family: Montserrat;
  font-size: 0.8em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.05px;
  text-align: left;
  color: #4b4d4e;
`;
let HeaderTitleModal = styled.p`
  font-family: Montserrat;
  font-size: 1.1em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.07px;
  text-align: left;
  color: #21a8df;
`;
let FormLabel = styled.div`
  font-family: Montserrat;
  font-size: 0.8em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.05px;
  text-align: left;
  color: #696974;
  margin: auto;
`;
let InputBox = styled.input`
  font-family: Montserrat;
  font-size: 0.8em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #4b4d4e;
  border: 0;
  width: 100%;
  resize: none;
`;
let TextBoxBox = styled.textarea`
  font-family: Montserrat;
  font-size: 0.8em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.7px;
  text-align: left;
  color: #4b4d4e;
  border: 0;
  height: 90px;
  width: 100%;
  resize: none;
  scrollbar-width: none;
`;
let SelectBox = styled.select`
  font-family: Montserrat;
  font-size: 0.8em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #4b4d4e;
  border: 0;
  width: 50%;
  float: left;
  padding: 10px;
`;
let RowBox = styled.div`
  padding: 10px 0;
  div.primaryContact {
    img {
      width: 30px;
      border-radius: 50%;
      height: 30px;
    }
    div {
      margin: auto 10px;
      font-family: Montserrat;
      font-size: 0.8em;
    }
  }
`;
let InquiryDetailForm = styled.form`
  width: 40vw;
  display: flex;
  flex-direction: column;
  position: relative;
  left: -10px;
  padding: 10px;
`;
const EditInquiry = wrapComponent(({ createSnackbar, inquiry, form, t }) => {
  const [{ user, dropdown }, dispatch] = useContext(UserContext);
  const [currenySign, setcurrenySign] = useState('');
  const currencies = ['JPY', 'EUR', 'USD'];
  const setcurreny = (currency) => {
    if (currency === 'USD') {
      setcurrenySign('$');
    } else if (currency === 'EUR') {
      setcurrenySign('€');
    } else {
      setcurrenySign('¥');
    }
  };
  const [editInquiry, setEditInquiry] = useState({
    name: inquiry.name,
    background: inquiry.background,
    inquiryStatus: inquiry.inquiryStatus,
    inquiry_id: inquiry._id,
    budget: inquiry.budget,
    currency: inquiry.currency
  });

  useState(() => {
    setcurreny(inquiry.currency);
  }, []);

  const fieldChange = useCallback((event) => {
    let data = {};
    if (event.target.name === 'currency') {
      if (event.target.value === 'USD') {
        setcurrenySign('$');
      } else if (event.target.value === 'EUR') {
        setcurrenySign('€');
      } else {
        setcurrenySign('¥');
      }
      if (!editInquiry.currency) {
        data['currency'] = 'JPY';
      }
    }
    data[event.target.name] = event.target.value;
    setEditInquiry({ ...editInquiry, ...data });
  });

  const showSnackbar = (msg) => {
    createSnackbar({
      theme: 'error',
      message: msg,
      timeout: 2000,
      dismissable: false,
      pauseOnHover: true,
      progressBar: false,
      sticky: false
    });
  };
  const capitalize = (s) => {
    debugger;
    if (typeof s !== 'string') return '';
    switch (s.toLowerCase()) {
      case 'draft':
        return t('inquiryStatus.draft');
      case 'submitted':
        return t('inquiryStatus.submitted');
      case 'signed':
        return t('inquiryStatus.signed');
      case 'suspended':
        return t('inquiryStatus.suspended');
      case 'completed':
        return t('inquiryStatus.completed');
      case 'proposed':
        return t('inquiryStatus.proposed');
      default:
        return '';
    }
  };
  const validateFormStep = () => {
    let msg;
    if (editInquiry.name === '' || editInquiry.name === null) {
      msg = t('inquiry.actions.title_empty');
    } else if (editInquiry.background === '' || editInquiry.background === null) {
      msg = t('inquiry.actions.background_empty');
    } else if (editInquiry.inquiryStatus === '' || editInquiry.inquiryStatus === null) {
      msg = t('inquiry.actions.details_empty');
    }

    if (msg) {
      showSnackbar(msg);
      return false;
    } else {
      return true;
    }
  };

  const submit = (e) => {
    e.preventDefault();
    if (validateFormStep()) {
      form(editInquiry);
    }
  };
  const manipulateStatus = (inquiryStatusData) => {
    if (user.user.role === 'client') {
      let showDropdown;
      if (editInquiry.inquiryStatus === 'draft') {
        showDropdown = inquiryStatusData.filter(
          (status) => status.value === 'draft' || status.value === 'submitted'
        );
        return showDropdown;
      } else if (editInquiry.inquiryStatus === 'submitted') {
        showDropdown = inquiryStatusData.filter(
          (status) => status.value === 'suspended' || status.value === 'submitted'
        );
        return showDropdown;
      } else if (editInquiry.inquiryStatus === 'suspended') {
        showDropdown = inquiryStatusData.filter(
          (status) => status.value === 'draft' || status.value === 'suspended'
        );
        return showDropdown;
      } else if (editInquiry.inquiryStatus === 'proposed') {
        showDropdown = inquiryStatusData.filter(
          (status) =>
            status.value === 'suspended' || status.value === 'signed' || status.value === 'proposed'
        );
        return showDropdown;
      }
    }
    return inquiryStatusData.filter((status) => status.value === inquiry.inquiryStatus);
  };

  return (
    <InquiryDetailForm name='form' onSubmit={submit}>
      <HeaderModal>{t('inquiry.edit.header')}</HeaderModal>
      <HeaderTitleModal>{inquiry.name}</HeaderTitleModal>
      <RowBox className='row'>
        <FormLabel className='col-md-3'>{t('inquiry.edit.title')}</FormLabel>
        <div className='col-md-9'>
          <InputBox name='name' value={editInquiry.name} onChange={(e) => fieldChange(e)} />
        </div>
      </RowBox>
      <RowBox className='row'>
        <FormLabel className='col-md-3 m-0'>{t('inquiry.edit.background')}</FormLabel>
        <div className='col-md-9'>
          <TextBoxBox
            name='background'
            value={editInquiry.background}
            onChange={(e) => fieldChange(e)}
          />
        </div>
      </RowBox>
      <RowBox className='row'>
        <FormLabel className='col-md-3'>{t('inquiry.edit.status')}</FormLabel>
        <div className='col-md-9'>
          <div className='row m-0'>
            <div className='col-md-8 p-0'>
              <NumberFormat
                className='currency-inqiry-font'
                thousandSeparator={true}
                placeholder='Enter Amount'
                allowNegative={false}
                prefix={currenySign}
                value={editInquiry.budget}
                prefix={currenySign}
                onValueChange={(values) => {
                  const { value } = values;
                  editInquiry.budget = value;
                }}
              />
            </div>
            <div className='col-md-4' style={{ marginLeft: '-50px' }}>
              <Dropdown
                type='country'
                name='currency'
                value={editInquiry.currency}
                options={currencies}
                arrayType='string'
                onChange={fieldChange}
                width='100%'
                border='solid 0.5px #979797'
                backgroundColor='#ffffff'
                color='#000'
              />
            </div>
          </div>
        </div>
      </RowBox>
      <RowBox className='row'>
        <FormLabel className='col-md-3'>{t('inquiry.edit.status')}</FormLabel>
        <div className='col-md-9'>
          <SelectBox
            name='inquiryStatus'
            value={editInquiry.inquiryStatus}
            onChange={(e) => fieldChange(e)}
          >
            {manipulateStatus(dropdown.InquiryStatus).map(({ value, label }, index) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </SelectBox>
        </div>
      </RowBox>
      <RowBox className='row'>
        <FormLabel className='col-md-3'>{t('inquiry.edit.primary_contact')}</FormLabel>
        <div className='col-md-9 d-flex primaryContact'>
          {inquiry.creator.profileImage ? (
            <img src={inquiry.creator.profileImage} />
          ) : (
            <Avatar
              size='30px'
              round='50%'
              name={inquiry.creator.firstName + ' ' + inquiry.creator.lastName}
            />
          )}
          <div>{inquiry.creator.firstName}</div>
        </div>
      </RowBox>
      <div style={{ margin: '5% 0 3% 0' }}>
        <Button text={t('universal_actions.save')} type='submit'></Button>
      </div>
    </InquiryDetailForm>
  );
});
export default withTranslation('common')(EditInquiry);
