import React from 'react';
import { Button } from '../common';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

let DeleteForm = styled.form`
  width: 20vw;
  h3 {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #4b4d4e;
    padding: 30px 0;
    @media (min-width: 1600px) {
      font-size: 13px;
    }
  }
`;
let Header = styled.p`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 0.75px;
  text-align: center;
  color: rgb(40, 40, 40);
  margin: auto;
  font-weight: 500;
  @media (min-width: 1600px) {
    font-size: 13px;
  }
`;
const DeletePopUp = ({ t, ...props }) => {
  const submit = (e) => {
    e.preventDefault();
    props.delete();
  };

  return (
    <>
      <div className='row m-0' style={{ padding: '8px 0' }}>
        <Header> {t('delete_popup.title')}</Header>
      </div>
      <DeleteForm>
        <h3> {t('delete_popup.text', { type: props.type })}</h3>
        <Button
          text={t('universal_actions.delete')}
          fullWidth='40%'
          type='button'
          onClick={submit}
        />
      </DeleteForm>
    </>
  );
};

export default withTranslation('common')(DeletePopUp);
