import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { authenticationService } from '../../../_services';
import Dropdown from 'react-bootstrap/Dropdown';
import { Modal2 } from '../../../common';
import ProfileModal from '../../Task/ProfileModal';
import { UserContext } from '../../../common/context/UserContext';

import { HeaderLogo, HeaderProfile } from '../../../public/icons/Header';
import { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
let Header = styled.header`
  height: 60px;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  text-align: center;
  z-index: 1;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  border: solid 0.5px #f8f8f8;
  background-color: #f8f8f8;
  div.container-fluid {
    padding-left: min(4vw, 20px);
    padding-right: 0;
  }
`;
let HeaderFunctions = styled.p`
  align-self: center;
  margin: 0 15px;
  font-family: Montserrat;
`;
let RightPanel = styled.div`
  flex-grow: 1;
  justify-content: flex-end;
`;

const DashboardHeader = ({ t, i18n, props }) => {
  const [{ user, dropdownAll }, dispatch] = useContext(UserContext);
  const [showProfileModal, setShowProfileModal] = useState(false);

  useEffect(() => {
    setNotificationData();
  }, []);
  const logoutUser = () => {
    authenticationService.logout();
  };
  const setNotificationData = () => {
    checkNorification();
    setInterval(() => {
      checkNorification();
    }, 10000);
  };
  const checkNorification = () => {
    authenticationService.getNotification().then(
      (response) => {
        let temp = false;
        temp = response.data.result.filter((notify) => notify.isRead !== true).length;
      },
      (err) => {
        console.error(err);
      }
    );
  };

  return (
    <Header>
      <div className='container-fluid h-100'>
        <div className='d-flex h-100'>
          <p className='m-0 align-self-center'>
            <HeaderLogo />
          </p>

          <RightPanel className='d-flex'>
            <HeaderFunctions>
              <Dropdown>
                <Dropdown.Toggle variant='none' id='dropdown-basic'>
                  <HeaderProfile />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={(e) => setShowProfileModal(true)}>
                    {t('common.edit_profile')}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={logoutUser} href='/login'>
                    {t('common.logout')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </HeaderFunctions>
          </RightPanel>
        </div>
        {showProfileModal && (
          <Modal2
            show={showProfileModal}
            close={() => {
              setShowProfileModal(false);
            }}
          >
            <ProfileModal />
          </Modal2>
        )}
      </div>
    </Header>
  );
};

export default withTranslation('common')(DashboardHeader);
