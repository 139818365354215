import React, { useState, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { Dropdown, TextArea, Input, Button, PageHeader } from '../../../common';
import { inquiryService } from '../../../_services/inquiry.service';
import Attachments from '../../../public/icons/Attachments';
import { UserContext } from '../../../common/context/UserContext';
import Close from '../../../public/icons/Close';
import { wrapComponent } from 'react-snackbar-alert';
import NumberFormat from 'react-number-format';
import { withTranslation } from 'react-i18next';
import BackToOption from '../../../common/BackToOption';

let LabelField = styled.label`
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #4b4d4e;
  margin: 0;
  margin-left: 5px;
  @media (min-width: 1600px) {
    font-size: 12px;
  }
`;
let RadioButtonPanel = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #4b4d4e;
  margin-left: 10px;
  padding: 0;
  margin: 10px;
  padding-left: 25px;
  input {
    margin: 0 0 0 -20px;
  }
  @media (min-width: 1600px) {
    font-size: 13px;
  }
`;
let SaveDraft = styled.p`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.05px;
  text-align: center;
  width: 170%;
  color: #21a8df;

  :hover {
    cursor: pointer;
  }
  @media (min-width: 1600px) {
    font-size: 13px;
  }
`;
let ThankYouBanner = styled.div`
  width: fit-content;
  height: 24px;
  object-fit: contain;
  border-radius: 12px;
  background-color: rgba(33, 168, 223, 0.2);
  padding: 7px 20px 0 5px;
  margin: 15px 0 25px 0px;
  p {
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.22;
    color: rgb(75, 77, 78);
    @media (min-width: 1600px) {
      font-size: 12px;
    }
  }
`;
let AttachmentPanel = styled.p`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.05px;
  text-align: left;
  color: #21a8df;
  margin: 20px 0;

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  label:hover {
    cursor: pointer;
  }
  @media (min-width: 1600px) {
    font-size: 13px;
  }
`;
let AttachedFiles = styled.div`
  margin: 10px;
  font-size: 11px;
  label {
    margin: 0;
  }
  div {
    position: relative;
    top: -10px;
    :hover {
      cursor: pointer;
    }
  }
  @media (min-width: 1600px) {
    font-size: 12px;
  }
`;
const NewInquiry = wrapComponent(({ createSnackbar, t, ...props }) => {
  const currencies = ['USD', 'EUR', 'JPY'];
  const [{ user, dropdown }, dispatch] = useContext(UserContext);
  const [currenySign, setcurrenySign] = useState('$');
  const [allClient, setAllClients] = useState([]);

  useState(() => {
    inquiryService.getAllClients().then(
      (response) => {
        let data = [{ value: '', label: 'Select' }];
        response.data.result.map((client) => data.push({ value: client._id, label: client.email }));
        setAllClients(data);
      },
      (error) => {
        console.error('failed to load clients');
      }
    );
  }, []);

  const showSnackbar = (msg, theme) => {
    createSnackbar({
      theme: theme,
      message: msg,
      timeout: 2000,
      dismissable: false,
      pauseOnHover: true,
      progressBar: false,
      sticky: false
    });
  };

  const [subCategories, setSubCategories] = useState(dropdown.subCategoriesBank[0]);
  const [isLoading, setIsLoading] = useState(false);
  let [inquiryForm, setInquiryForm] = useState({
    client: '',
    title: '',
    projectType: dropdown.categories[0].label,
    projectTypeDetails: '',
    subCategory: subCategories[0],
    marketInterest: dropdown.markets[0].label,
    planStart: '',
    planEstimate: '',
    planEstimateType: currencies[0],
    projectBackground: '',
    projectDetails: '',
    primaryContact: '',
    attachments: '',
    attachmentName: [],
    inquiryStatus: '',
    formStep: 1
  });

  const submit = useCallback(() => {
    let data = {};
    if (!inquiryForm.attachmentName) {
      data = {
        name: inquiryForm.title,
        category: inquiryForm.projectType,
        remarks: inquiryForm.projectTypeDetails || undefined,
        subCategory: inquiryForm.subCategory || undefined,
        budget: inquiryForm.planEstimate,
        currency: inquiryForm.planEstimateType,
        background: inquiryForm.projectBackground,
        details: inquiryForm.projectDetails,
        market: inquiryForm.marketInterest,
        estimatedStart: inquiryForm.planStart,
        inquiryStatus: inquiryForm.inquiryStatus || undefined
      };
      if (user.user.role === 'facilitator') {
        data['client'] = inquiryForm.client;
      }
    } else {
      let temp = new FormData();
      temp.append('name', inquiryForm.title);
      temp.append('category', inquiryForm.projectType);
      if (inquiryForm.projectTypeDetails) {
        temp.append('remarks', inquiryForm.projectTypeDetails);
      }
      if (inquiryForm.subCategory) {
        temp.append('subCategory', inquiryForm.subCategory);
      }
      if (inquiryForm.planEstimate) {
        temp.append('budget', inquiryForm.planEstimate);
        temp.append('currency', inquiryForm.planEstimateType);
      }
      temp.append('background', inquiryForm.projectBackground);
      temp.append('details', inquiryForm.projectDetails);
      temp.append('market', inquiryForm.marketInterest);
      temp.append('estimatedStart', inquiryForm.planStart);
      Array.from(inquiryForm.attachmentName).forEach((item) => {
        temp.append('document', item);
      });
      if (inquiryForm.inquiryStatus) {
        temp.append('inquiryStatus', inquiryForm.inquiryStatus);
      }
      if (user.user.role === 'facilitator') {
        temp.append('client', inquiryForm.client);
      }
      data = temp;
    }
    inquiryService.newInquiry(data).then(
      (data) => {
        showSnackbar(t('inquiry.actions.added_inquiry'), 'success');
        props.history.push('/home/inquiries');
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
        console.error('error inquiry');
      }
    );
    // }
  });

  const triggerNextForm = useCallback((type) => {
    if (type === 'next' || type === 'draft') {
      if (validateFormStep()) {
        if (inquiryForm.formStep < 2) {
          setInquiryForm({
            ...inquiryForm,
            formStep: inquiryForm.formStep + 1
          });
        } else {
          if (type === 'draft') {
            inquiryForm.inquiryStatus = 'draft';
          }
          setIsLoading(true);
          submit();
          setInquiryForm({ ...inquiryForm, formStep: 2 });
        }
      }
    } else {
      if (inquiryForm.formStep < 1) {
        setInquiryForm({ ...inquiryForm, formStep: 1 });
      } else {
        setInquiryForm({ ...inquiryForm, formStep: inquiryForm.formStep - 1 });
      }
    }
  });

  const fieldChange = useCallback((event) => {
    let data = {};
    if (event.target.type === 'file') {
      data['attachmentName'] = inquiryForm.attachmentName.concat(Object.values(event.target.files));
    } else {
      data[event.target.name] = event.target.value;
    }
    if (event.target.name === 'projectType') {
      setSubCategories(
        dropdown.subCategoriesBank[
          dropdown.categories.findIndex((item) => {
            return item.value === event.target.value;
          })
        ]
      );
    }

    if (event.target.name === 'planEstimateType') {
      if (event.target.value === 'USD') {
        setcurrenySign('$');
      } else if (event.target.value === 'EUR') {
        setcurrenySign('€');
      } else {
        setcurrenySign('¥');
      }
    }
    setInquiryForm({ ...inquiryForm, ...data });
  });

  const validateFormStep = () => {
    let msg;
    if (inquiryForm.formStep === 1) {
      if (inquiryForm.title === '' || inquiryForm.title === null) {
        msg = t('inquiry.actions.title_empty');
      } else if (inquiryForm.projectType === '' || inquiryForm.projectType === null) {
        msg = t('inquiry.actions.type_empty');
      } else if (
        (inquiryForm.subCategory === '' || inquiryForm.subCategory === null) &&
        (inquiryForm.projectTypeDetails === '' || inquiryForm.projectTypeDetails === null)
      ) {
        msg = t('inquiry.actions.sub_category_empty');
      } else if (inquiryForm.marketInterest === '' || inquiryForm.marketInterest === null) {
        msg = t('inquiry.actions.market_interest_empty');
        // }
        // else if (inquiryForm.planEstimate === '' || inquiryForm.planEstimate === null) {
        //   msg = 'Plan Estimate cannot be empty';
      } else if (inquiryForm.planStart === '' || inquiryForm.planStart === null) {
        msg = t('inquiry.actions.plan_start_empty');
      }
      // else if (inquiryForm.planEstimateType === '' || inquiryForm.planEstimateType === null) {
      //   msg = 'Plan Estimate Type cannot be empty';
      // }
      if (user.user.role === 'facilitator') {
        if (inquiryForm.client === '') {
          msg = t('inquiry.actions.client_required');
        }
      }
    } else if (inquiryForm.formStep == 2) {
      if (inquiryForm.projectBackground === '' || inquiryForm.projectBackground === null) {
        msg = t('inquiry.actions.background_empty');
      } else if (inquiryForm.projectDetails === '' || inquiryForm.projectDetails === null) {
        msg = t('inquiry.actions.details_empty');
      }
    }

    if (msg) {
      showSnackbar(msg, 'error');
      return false;
    } else {
      return true;
    }
  };

  const removeAttachedFile = (fileToRemove) => {
    let temp = inquiryForm.attachmentName;
    let index = temp.indexOf(fileToRemove);
    if (index !== -1) temp.splice(index, 1);
    let data = {};
    data['attachmentName'] = temp;
    setInquiryForm({ ...inquiryForm, ...data });
  };

  const showAttchmentName = () => {
    // console.log("inquiryForm.attachmentName", inquiryForm.attachmentName)
    let filename = inquiryForm.attachmentName.slice(0, 5).map((file) => {
      return (
        <AttachedFiles className='d-flex'>
          <label htmlFor='file'>{file.name}</label>
          <div onClick={() => removeAttachedFile(file)}>
            <Close />
          </div>
        </AttachedFiles>
      );
    });
    return filename;
  };

  return (
    <>
      <div style={{ padding: '20px' }}>
        <BackToOption link='/home/inquiries' text={t('inquiry.new.my_inquiry')} />
        <div className='row m-0'>
          <PageHeader text={t('inquiry.new.new_inquiry')} />
        </div>
        <ThankYouBanner className='row'>
          <p>{t('inquiry.new.thank_you_text')}</p>
        </ThankYouBanner>
        {inquiryForm.formStep === 1 && (
          <div>
            {user.user.role === 'facilitator' && allClient.length > 0 && (
              <Dropdown
                text={t('universal_actions.clients_email')}
                type='client'
                name='client'
                value={inquiryForm.client}
                options={allClient}
                onChange={fieldChange}
                width='35%'
                margin='20px 0'
                border='solid 0.5px #979797'
                backgroundColor='#ffffff'
                color='#000'
              />
            )}
            <Input
              type='text'
              name='title'
              text={t('inquiry.new.title')}
              placeholder={t('inquiry.new.title_placeholder')}
              value={inquiryForm.title}
              onChange={fieldChange}
              width='35%'
              border='solid 0.5px #979797'
              backgroundColor='#ffffff'
              color='#000'
            />
            <Dropdown
              text={t('inquiry.new.project_type')}
              type='projectType'
              name='projectType'
              value={inquiryForm.projectType}
              options={dropdown.categories}
              onChange={fieldChange}
              width='35%'
              margin='20px 0'
              border='solid 0.5px #979797'
              backgroundColor='#ffffff'
              color='#000'
            />
            {inquiryForm.projectType === 'Other' ? (
              <>
                <TextArea
                  cols={50}
                  rows={6}
                  placeholder={t('inquiry.new.elaborate_text')}
                  width='35%'
                  name='projectTypeDetails'
                  value={inquiryForm.projectTypeDetails}
                  onChange={fieldChange}
                />
              </>
            ) : (
              <Dropdown
                text={t('inquiry.new.sub_category')}
                type='subCategory'
                name='subCategory'
                value={inquiryForm.subCategory}
                options={subCategories}
                arrayType='string'
                onChange={fieldChange}
                width='35%'
                margin='20px 0'
                border='solid 0.5px #979797'
                backgroundColor='#ffffff'
                color='#000'
              />
            )}
            <Dropdown
              text={t('inquiry.new.market_interest')}
              type='marketInterests'
              name='marketInterest'
              options={dropdown.markets}
              value={inquiryForm.marketInterest}
              onChange={fieldChange}
              width='35%'
              margin='20px 0'
              border='solid 0.5px #979797'
              backgroundColor='#ffffff'
              color='#000'
            />
            <div className='form-row'>
              <div className='form-group'>
                <LabelField>{t('inquiry.new.project_plan')}</LabelField>
                {dropdown.projectStart.map((field) => (
                  <RadioButtonPanel className='form-check'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='planStart'
                      value={field.value}
                      onChange={fieldChange}
                      checked={field.value === inquiryForm.planStart}
                    />
                    <label className='form-check-label'>{field.label}</label>
                  </RadioButtonPanel>
                ))}
              </div>
            </div>

            {/* <Checkbox text="When do you plan to start this project?*" type="projectPlan" margin="10px 0" name="planStart" value={inquiryForm.planStart} onChange={fieldChange} />*/}

            <div className='row flex-column' style={{ margin: '5px 0' }}>
              <LabelField>{t('inquiry.new.budget_text')}</LabelField>
              <div className='row m-0'>
                <div className='col-md-2 p-0'>
                  <NumberFormat
                    className='currency-inqiry-font'
                    thousandSeparator={true}
                    placeholder={t('inquiry.new.amount_placeholder')}
                    allowNegative={false}
                    prefix={currenySign}
                    value={inquiryForm.planEstimate}
                    onValueChange={(values) => {
                      const { value } = values;
                      inquiryForm.planEstimate = value;
                    }}
                  />
                </div>
                <div className='col-md-2' style={{ marginLeft: '-50px' }}>
                  <Dropdown
                    type='country'
                    name='planEstimateType'
                    value={inquiryForm.planEstimateType}
                    options={currencies}
                    arrayType='string'
                    onChange={fieldChange}
                    width='60%'
                    border='solid 0.5px #979797'
                    backgroundColor='#ffffff'
                    color='#000'
                  />
                </div>
              </div>
            </div>
            <div className='row' style={{ width: '35%', margin: '15px 0' }}>
              <div>{/* <Button text="BACK" fullWidth="140%" switch={true} />*/}</div>

              <div style={{ marginLeft: 'auto' }}>
                <Button
                  text={t('universal_actions.next')}
                  fullWidth='240%'
                  onClick={() => triggerNextForm('next')}
                />
              </div>
            </div>
          </div>
        )}
        {inquiryForm.formStep === 2 && (
          <>
            <div className='d-flex flex-column'>
              <TextArea
                text={t('inquiry.new.background')}
                cols={50}
                rows={6}
                name='projectBackground'
                value={inquiryForm.projectBackground}
                onChange={fieldChange}
                placeholder={t('inquiry.new.background_placholder')}
                width='35%'
              />
              <TextArea
                text={t('inquiry.new.project_details')}
                cols={50}
                rows={6}
                name='projectDetails'
                value={inquiryForm.projectDetails}
                onChange={fieldChange}
                placeholder={t('inquiry.new.background_placholder')}
                width='35%'
              />
              <AttachmentPanel>
                <Attachments />
                <input
                  type='file'
                  multiple
                  name='attachments'
                  id='file'
                  onChange={fieldChange}
                  accept='.doc,.docx,.png,.jpg,.jpeg,.pdf,.xls,.csv,.xlsx,.zip,.rar'
                />
                <label htmlFor='file'>
                  {inquiryForm.attachmentName.length > 0
                    ? t('inquiry.new.add_more')
                    : t('inquiry.new.add_documents')}
                </label>
                {inquiryForm.attachmentName.length > 0 && (
                  <div className='d-flex'>
                    {showAttchmentName()}
                    {inquiryForm.attachmentName.length > 5 && (
                      <AttachedFiles>+ {inquiryForm.attachmentName.length - 5}</AttachedFiles>
                    )}
                  </div>
                )}
              </AttachmentPanel>
            </div>
            <div className='row' style={{ width: '35%', margin: '15px 0' }}>
              <div>
                <Button
                  text={t('universal_actions.back')}
                  fullWidth='240%'
                  switch={true}
                  onClick={() => triggerNextForm('back')}
                />
              </div>

              <div style={{ marginLeft: 'auto' }}>
                {!isLoading && (
                  <Button
                    text={t('universal_actions.submit')}
                    fullWidth='170%'
                    onClick={() => triggerNextForm('next')}
                  />
                )}
                {isLoading && (
                  <div class='spinner-grow' role='status' style={{ margin: '10px 0' }}>
                    <span class='sr-only'>Loading...</span>
                  </div>
                )}
                <SaveDraft onClick={() => triggerNextForm('draft')}>
                  {' '}
                  {t('inquiry.new.draft_save')}
                </SaveDraft>
                {/* <Button text="Save as Draft" disabled={!validateFormStep()} fullWidth="140%" onClick={() => triggerNextForm('draft')} />*/}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
});

export default withTranslation('common')(NewInquiry);
