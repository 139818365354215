import { useEffect, useState } from 'react';

export function useMobile() {
  const [isMobile, setMobile] = useState(false);
  useEffect(() => {
    setMobile(window.innerWidth <= 520);
    const onResize = function () {
      setMobile(window.innerWidth <= 520);
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return isMobile;
}
