import React from 'react';
import styled from 'styled-components';

let RoundButton = styled.button`
  width: ${(props) => (props.fullWidth ? props.fullWidth : '30%')};
  height: auto;
  border-radius: 20px;
  box-shadow: 0 0.5px 4px 0 rgba(0, 0, 0, 0.3);
  background-color: ${(props) => (props.switch ? '#ffffff' : '#21a8df')};
  border: none;
  font-size: ${(props) => (props.font ? props.font : '13px')};
  font-family: Montserrat;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: 0.6px;
  text-align: center;
  color: ${(props) => (!props.switch ? '#ffffff' : '#21a8df')};
  padding: 9px;
  :hover {
    color: ${(props) => (!props.switch ? '#ffffff' : '#21a8df')};
  }
`;
let ButtonForm = styled.div`
  text-align: center;
  // padding: 10% 0;
`;

const Button = ({ text, disabled, ...props }) => (
  <ButtonForm className='form-group'>
    <RoundButton className='btn' disabled={disabled} {...props}>
      {text}
    </RoundButton>
  </ButtonForm>
);

export { Button };
