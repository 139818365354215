import React, { useContext } from 'react';
import DashboardHeader from './DashboardHeader';
import SideNav from '../Dashboard/DashboardSideNav';
import { sendBirdService, authenticationService } from '../../_services';
import styled from 'styled-components';
import { routesSection } from './RoutesFile';
import { UserContext } from '../../common/context/UserContext';

let MainPanel = styled.div`
  height: calc(100vh - 45px);
  margin: 0;
`;

let MainDashboardPanel = styled.div`
  text-align: center;
  margin-left: 15%;
  height: inherit;
  position: relative;
  left: 0;
`;

let DashboardSection = styled.section`
  margin-top: 60px;
  height: inherit;
`;

const HomePage = (props) => {
  const [{ user }, dispatch] = useContext(UserContext);
  if (!sendBirdService.getConnectedUser()) {
    sendBirdService.connectToSendBirdServer(user);
  }
  if (!authenticationService.loggedUser) {
    authenticationService.updateUser();
  }
  // authenticationService.loggedUser.subscribe(item => {
  //   if (!loggedUser._id && item._id) {
  //     console.log(item)
  //     setLoggedUser(item)
  //   } else {
  //     authenticationService.updateUser()
  //   }
  // })
  return (
    <MainPanel>
      <DashboardHeader props={props}></DashboardHeader>
      <SideNav></SideNav>
      <MainDashboardPanel>
        <DashboardSection>
          {user.user.role === 'client'
            ? routesSection.RoutesFileClient
            : user.user.role === 'facilitator'
            ? routesSection.RoutesFileFaciliator
            : routesSection.RoutesFileProfessional}
        </DashboardSection>
      </MainDashboardPanel>
    </MainPanel>
  );
};

export { HomePage };
