import React, { useState, useCallback, useEffect, useContext } from 'react';
import { FormProgress, Button, Calendar, Modal } from '../../../common';
import styled from 'styled-components';
import AddIcon from '../../../public/icons/AddIcon';
import { clientService, authenticationService } from '../../../_services';
import { projectService } from '../../../_services/project.service';
import { UserContext } from '../../../common/context/UserContext';
import * as moment from 'moment';
import { wrapComponent } from 'react-snackbar-alert';
import NumberFormat from 'react-number-format';
import { inquiryService } from '../../../_services/inquiry.service';
import InviteCollaborator from '../ProjectDetail/InviteCollaborator';
import Avatar from 'react-avatar';
import { withTranslation } from 'react-i18next';
import TeamDropdown from '../../../common/TeamDropdown';
import 'moment/locale/ja';
import 'moment/locale/en-ca';

let Title = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.29;
  text-align: center;
  color: #696974;
`;
let RowBox = styled.div`
  padding: 10px 0;
  div.primaryContact {
    img {
      width: 30px;
      border-radius: 50%;
      height: 30px;
    }
    div {
      margin: auto 10px;
      font-family: Montserrat;
      font-size: 0.8em;
    }
  }
`;
let FormLabel = styled.div`
  font-family: Montserrat;
  font-size: 0.8em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.05px;
  text-align: left;
  color: #696974;
  align-self: center;
`;
let Warning = styled.div`
  font-family: Montserrat;
  font-size: 0.7em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.05px;
  text-align: right;
  color: #f44336;
  align-self: center;
`;
let InputBox = styled.input`
  border: 1px solid #e5e5e5 !important;
  border-radius: 3px;
  font-family: Montserrat;
  font-size: 0.8em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #696974;
  border: 0;
  width: 100%;
  resize: none;
  padding: 0 10px;
`;
let SelectBox = styled.select`
  font-family: Montserrat;
  font-size: 0.8em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #696974;
  border: 0;
  width: 100%;
  float: left;
  padding: 10px;
`;
let TextBoxBox = styled.textarea`
  border: 1px solid #e5e5e5 !important;
  border-radius: 3px;
  font-family: Montserrat;
  font-size: 0.8em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.7px;
  text-align: left;
  color: #696974;
  border: 0;
  height: 90px;
  width: 100%;
  resize: none;
  scrollbar-width: none;
`;
let BackButton = styled.button`
  position: absolute;
  border: 0;
  background: none;
  left: 40%;
  font-family: Montserrat;
  font-size: 10px;
  line-height: 1.3;
  color: #4b4d4e;
  text-decoration: underline;
`;
let AddTeamPanel = styled.div`
  display: flex;
  p.add-team-member {
    font-family: Montserrat;
    font-size: 12px;
    line-height: 1.43;
    letter-spacing: 0.05px;
    text-align: left;
    color: #21a8df;
    margin: 0 10px;
    :hover {
      cursor: hover;
    }
  }
`;
let MemberIcon = styled.div`
  border-radius: 50%;
  margin-right: 5px;
  height: 29px;
  width: 30px;
  overflow: hidden;
  border: ${(props) => (props.number ? 'solid 0.5px #4b4d4e' : 'none')};
  img {
    height: 29px;
    width: 30px;
    border-radius: 50%;
  }
  p {
    align-self: center;
    padding: 7px 0;
    text-align: center;
    overflow: hidden;
    height: 100%;
    line-height: 1.25;
    color: #4b4d4e;
    font-family: Montserrat;
    font-size: 11px;
  }
`;

let InviteCollab = styled.p`
  font-family: Montserrat;
  font-size: 0.8em;
  line-height: 1.29;
  letter-spacing: 0.05px;
  text-align: center;
  color: #21a8df;
  margin-left: 3%;
`;
const AddProjectModal = wrapComponent(({ t, i18n, createSnackbar, ...props }) => {
  moment.locale('ja');
  const currencies = [
    { value: 'USD', label: 'USD' },
    { value: 'EUR', label: 'EUR' },
    { value: 'JPY', label: 'JPY' }
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [{ user, dropdown }, dispatch] = useContext(UserContext);
  const [inviteProfessional, setInviteProfessional] = useState(false);
  const showSnackbar = (msg, theme) => {
    createSnackbar({
      theme: theme,
      message: msg,
      timeout: 2000,
      dismissable: false,
      pauseOnHover: true,
      progressBar: false,
      sticky: false
    });
  };

  const submit = useCallback(() => {
    let data = {};
    data = {
      name: addProjectForm.title,
      category: addProjectForm.category,
      subCategory: addProjectForm.subCategory,
      inquiry: addProjectForm.inquiry,
      status: addProjectForm.projectSatus,
      budget: addProjectForm.budget,
      currency: addProjectForm.currency,
      startDate: addProjectForm.startDate,
      endDate: addProjectForm.endDate,
      background: addProjectForm.background,
      details: addProjectForm.details,
      owner: addProjectForm.owners._id,
      // facilitator: addProjectForm.facilitator._id,
      formProgress: addProjectForm.formProgress
    };
    // console.log(data)
    const team = addProjectForm.professtionals.map((t) => {
      return t.member._id;
    });
    data.team = team;
    // console.log(data)
    projectService.newProject(data).then(
      (data) => {
        setIsLoading(false);
        showSnackbar(t('project.actions.add_success'), 'success');
        props.addedProject();
      },
      (err) => {
        console.error('error project');
        setIsLoading(false);
      }
    );
  });

  // const currencies = ["USD", "EUR", "JPY"];
  const [inquiries, setInquiries] = useState([]);

  const [subCategories, setSubCategories] = useState(dropdown.subCategoriesBank[0]);

  const [addProjectForm, setAddProjectForm] = useState({
    title: '',
    category: dropdown.categories[0].label,
    subCategory: subCategories[0],
    inquiry: '',
    projectSatus: dropdown.TaskStatus[0].value,
    budget: '',
    currency: currencies[0].label,
    startDate: new Date().toISOString().substr(0, 10),
    endDate: new Date().toISOString().substr(0, 10),
    background: '',
    details: '',
    owners: '',
    facilitator: '',
    professtionals: [],
    formProgress: 1
  });

  const [currenySign, setcurrenySign] = useState('$');

  const [showWarning, setWarning] = useState(0);

  const fieldChangeCalendar = (value, type) => {
    let data = {};
    data[type] = moment(value).format('YYYY-MM-DD');
    if (type === 'endDate' && moment(value).format('YYYY-MM-DD') < addProjectForm.startDate) {
      setWarning(1);
      showSnackbar('End date cannot be less than start date', 'error');
      return false;
    }
    if (type === 'endDate' && moment(value).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
      setWarning(1);
      showSnackbar('End date cannot be less than todays date', 'error');
      return false;
    }
    if (type === 'startDate' && moment(value).format('YYYY-MM-DD') > addProjectForm.endDate) {
      setWarning(1);
      showSnackbar('Start date cannot be more than end date', 'error');
      return false;
    }
    setWarning(0);
    setAddProjectForm({ ...addProjectForm, ...data });
  };

  const fieldChange = useCallback((event) => {
    let data = {};
    data[event.target.name] = event.target.value;
    if (event.target.name === 'inquiry') {
      let inquiryData = inquiries.filter((item) => item._id === event.target.value);
      data['details'] = inquiryData[0].details;
      data['background'] = inquiryData[0].background;
      data['budget'] = inquiryData[0].budget || '0';
      data['currency'] = inquiryData[0].currency || 'USD';
      data['owners'] = inquiryData[0].creator;
      if (inquiryData[0].currency) {
        if (inquiryData[0].currency === 'USD') {
          setcurrenySign('$');
        } else if (inquiryData[0].currency === 'EUR') {
          setcurrenySign('€');
        } else {
          setcurrenySign('¥');
        }
      } else {
        setcurrenySign('$');
      }
    }

    if (event.target.name === 'currency') {
      if (event.target.value === 'USD') {
        setcurrenySign('$');
      } else if (event.target.value === 'EUR') {
        setcurrenySign('€');
      } else {
        setcurrenySign('¥');
      }
    }

    setAddProjectForm({ ...addProjectForm, ...data });
    if (event.target.name === 'category') {
      setSubCategories(
        dropdown.subCategoriesBank[
          dropdown.categories.findIndex((item) => {
            return item.value === event.target.value;
          })
        ]
      );
    }
    setWarning(0);
  });

  const [teamMembers, setTeamMembers] = useState();
  useEffect(() => {
    // if (projectDetails._id) {
    projectService.getProfesstional().then(
      (response) => {
        if (response.data.result) {
          // console.log("response team", response)
          let res = response.data.result;
          let data = [];
          res.map((r) => {
            data.push({ member: r });
          });
          setTeamMembers(data);
        }
      },
      (err) => {
        console.error('error project filers', err);
      }
    );
  }, []);
  const [showTeamBox, setShowTeamBox] = useState(false);

  const [loggedUser, setLoggedUser] = useState(authenticationService.loggedUser);

  useEffect(() => {
    if (!loggedUser) {
      authenticationService.updateUser().then((response) => {
        setLoggedUser(response);
      });
    }
    inquiryService.getSignedInquiries().then((response) => {
      setInquiries(response.data.result);
    });
  }, []);

  const triggerNextForm = useCallback(async (type) => {
    let verify = true;
    if (verify) {
      if (type === 'next') {
        if (await validateFormData()) {
          // console.log('iniii');
          if (addProjectForm.formProgress < 3) {
            setAddProjectForm({
              ...addProjectForm,
              formProgress: addProjectForm.formProgress + 1
            });
          } else {
            setAddProjectForm({ ...addProjectForm, formProgress: 3 });
            submit();
            setIsLoading(true);
          }
        }
      } else {
        if (addProjectForm.formProgress < 1) {
          setAddProjectForm({ ...addProjectForm, formProgress: 1 });
        } else {
          setAddProjectForm({
            ...addProjectForm,
            formProgress: addProjectForm.formProgress - 1
          });
        }
      }
    }
  });

  const validateFormData = () => {
    let msg;
    if (addProjectForm.formProgress === 1) {
      if (addProjectForm.title === '' || addProjectForm.title === null) {
        msg = t('project.actions.title_empty');
      } else if (addProjectForm.category === '' || addProjectForm.category === null) {
        msg = t('project.actions.category_empty');
      } else if (addProjectForm.subCategory === '' || addProjectForm.subCategory === null) {
        msg = t('project.actions.sub_category_empty');
      } else if (addProjectForm.inquiry === '' || addProjectForm.inquiry === null) {
        msg = t('project.actions.inquiry_empty');
      } else if (addProjectForm.projectSatus === '' || addProjectForm.projectSatus === null) {
        msg = t('project.actions.status_empty');
      } else if (addProjectForm.startDate === '' || addProjectForm.startDate === null) {
        msg = t('project.actions.start_date_empty');
      } else if (addProjectForm.endDate === '' || addProjectForm.endDate === null) {
        msg = t('project.actions.end_date_empty');
      } else if (addProjectForm.budget === '' || addProjectForm.budget === null) {
        msg = t('project.actions.budget_empty');
      } else if (addProjectForm.currency === '' || addProjectForm.currency === null) {
        msg = t('project.actions.currency_empty');
      }
    } else if (addProjectForm.formProgress === 2) {
      if (addProjectForm.background === '' || addProjectForm.background === null) {
        msg = t('project.actions.background_empty');
      } else if (addProjectForm.details === '' || addProjectForm.details === null) {
        msg = t('project.actions.detail_empty');
      }
    }
    // else if (addProjectForm.formProgress === 3) {
    //   if (addProjectForm.professtionals.length === 0) {
    //     msg = 'Professtionals cannot be empty';
    //   }
    // }

    if (msg) {
      showSnackbar(msg, 'error');
      return false;
    } else {
      return true;
    }
  };

  const selectedTeamMembers = (team) => {
    setShowTeamBox(false);
    setAddProjectForm({ ...addProjectForm, professtionals: team });
  };
  return (
    <div>
      <div style={{ marginTop: '10%' }}>
        <Title>{t('project.add.header')}</Title>
        <div style={{ width: '50%', margin: 'auto' }}>
          <FormProgress totalForms={3} completed={addProjectForm.formProgress}></FormProgress>
        </div>
      </div>
      <div
        style={{
          width: '40vw',
          marginTop: '30px'
        }}
      >
        {addProjectForm.formProgress === 1 && (
          <>
            <RowBox className='row'>
              <FormLabel className='col-md-3'>{t('project.add.title')}</FormLabel>
              <InputBox
                className='col-md-9'
                name='title'
                placeholder={t('project.add.title_placeholder')}
                value={addProjectForm.title}
                onChange={(e) => fieldChange(e)}
              />
            </RowBox>
            <RowBox className='row'>
              <FormLabel className='col-md-3'>{t('project.add.category')}</FormLabel>
              <SelectBox
                className='col-md-9'
                name='category'
                value={addProjectForm.category}
                onChange={(e) => fieldChange(e)}
              >
                {dropdown.categories.map(({ value, label }, index) => (
                  <option value={value} key={index}>
                    {value}
                  </option>
                ))}
              </SelectBox>
            </RowBox>
            <RowBox className='row'>
              <FormLabel className='col-md-3'>{t('project.add.sub-category')}</FormLabel>
              <SelectBox
                className='col-md-9'
                name='subCategory'
                value={addProjectForm.subCategory}
                onChange={(e) => fieldChange(e)}
              >
                {subCategories.map((value, index) => (
                  <option value={value} key={index}>
                    {value}
                  </option>
                ))}
              </SelectBox>
            </RowBox>
            <RowBox className='row'>
              <FormLabel className='col-md-3'>{t('project.add.inquiry')}</FormLabel>
              <SelectBox
                className='col-md-9'
                name='inquiry'
                value={addProjectForm.inquiry}
                onChange={(e) => fieldChange(e)}
              >
                {!addProjectForm.inquiry && (
                  <option value=''>{t('project.add.select_inquiry')}</option>
                )}
                {inquiries.map((value, index) => (
                  <option value={value._id} key={index}>
                    {value.name}
                  </option>
                ))}
              </SelectBox>
            </RowBox>
            <RowBox className='row'>
              <FormLabel className='col-md-3'>{t('project.add.status')}</FormLabel>
              <SelectBox
                className='col-md-3'
                name='projectSatus'
                value={addProjectForm.projectSatus}
                onChange={(e) => fieldChange(e)}
              >
                {dropdown.TaskStatus.map(({ value, label }, index) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </SelectBox>
              <FormLabel className='col-md-2'>{t('project.add.budget_field')}</FormLabel>
              <NumberFormat
                className='col-md-2 currency-font'
                thousandSeparator={true}
                allowNegative={false}
                placeholder={t('project.add.budget')}
                prefix={currenySign}
                value={addProjectForm.budget}
                onValueChange={(values) => {
                  const { value } = values;
                  addProjectForm.budget = value;
                }}
              />
              <SelectBox
                className='col-md-2'
                name='currency'
                value={addProjectForm.currency}
                onChange={(e) => fieldChange(e)}
              >
                {currencies.map(({ value, label }, index) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </SelectBox>
            </RowBox>
            <RowBox className='row'>
              <FormLabel className='col-md-3'> {t('project.edit.start_date')} </FormLabel>
              <div
                className='col-md-3'
                style={{
                  padding: '0',
                  fontSize: '11px',
                  fontFamily: 'Montserrat'
                }}
              >
                <Calendar
                  name='startDate'
                  value={moment(addProjectForm.startDate).format('MMM DD, YYYY')}
                  onChange={(e) => fieldChangeCalendar(e, 'startDate')}
                  dateFormat='MMM DD, YYYY'
                  className='hover'
                />
              </div>
              <FormLabel className='col-md-3'>{t('project.edit.end_date')}</FormLabel>
              <div
                className='col-md-3'
                style={{
                  padding: '0',
                  fontSize: '11px',
                  fontFamily: 'Montserrat'
                }}
              >
                <Calendar
                  name='endDate'
                  value={moment(addProjectForm.endDate).format('MMM DD, YYYY')}
                  onChange={(e) => fieldChangeCalendar(e, 'endDate')}
                  dateFormat='MMM DD, YYYY'
                  className='hover'
                  startDate={addProjectForm.startDate ? addProjectForm.startDate : new Date()}
                />
              </div>
              {/* {showWarning ? (
                <Warning className='col-md-12'>{t('project.add.enter_date')}</Warning>
              ) : null} */}
            </RowBox>
          </>
        )}
        {addProjectForm.formProgress === 2 && (
          <>
            <RowBox className='row'>
              <FormLabel className='col-md-3 m-0 align-self-start'>
                {t('project.add.background')}
              </FormLabel>
              <div className='col-md-9'>
                <TextBoxBox
                  name='background'
                  placeholder={t('project.add.background_placeholder')}
                  value={addProjectForm.background}
                  onChange={(e) => fieldChange(e)}
                />
              </div>
            </RowBox>
            <RowBox className='row'>
              <FormLabel className='col-md-3 m-0 align-self-start'>
                {t('project.add.project_details')}
              </FormLabel>
              <div className='col-md-9'>
                <TextBoxBox
                  name='details'
                  placeholder={t('project.add.project_details_placeholder')}
                  value={addProjectForm.details}
                  onChange={(e) => fieldChange(e)}
                />
              </div>
            </RowBox>
          </>
        )}
        {addProjectForm.formProgress === 3 && (
          <>
            <RowBox className='row'>
              <FormLabel className='col-md-3 m-0'>{t('project.add.project_owner')}</FormLabel>
              <div className='col-md-9'>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontFamily: 'Montserrat',
                    fontSize: '12px',
                    color: '#696974'
                  }}
                >
                  {addProjectForm.owners.profileImage ? (
                    <img
                      src={addProjectForm.owners.profileImage}
                      style={{
                        width: '30px',
                        borderRadius: '50%',
                        height: '30px'
                      }}
                    />
                  ) : (
                    <Avatar
                      size='30px'
                      round='50%'
                      name={addProjectForm.owners.firstName + ' ' + addProjectForm.owners.lastName}
                    />
                  )}
                  <div style={{ margin: 'auto 10px' }}>{addProjectForm.owners.firstName}</div>
                </div>
              </div>
            </RowBox>
            <RowBox className='row'>
              <FormLabel className='col-md-3 m-0'>{t('project.add.project_facilitator')}</FormLabel>
              <div className='col-md-9'>
                <div className='col-md-9 p-0'>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      fontFamily: 'Montserrat',
                      fontSize: '12px',
                      color: '#696974'
                    }}
                  >
                    {loggedUser.profileImage ? (
                      <img
                        src={loggedUser.profileImage}
                        style={{
                          width: '30px',
                          borderRadius: '50%',
                          height: '30px'
                        }}
                      />
                    ) : (
                      <Avatar
                        size='30px'
                        round='50%'
                        name={loggedUser.firstName + ' ' + loggedUser.lastName}
                      />
                    )}
                    <div style={{ margin: 'auto 10px' }}>{loggedUser.firstName}</div>
                  </div>
                </div>
              </div>
            </RowBox>
            <RowBox className='row'>
              <FormLabel className='col-md-3 m-0'>{t('project.add.team')}</FormLabel>
              <div className='col-md-9'>
                <div>
                  <AddTeamPanel>
                    {addProjectForm.professtionals.slice(0, 5 - 1).map((icon, index) => (
                      <MemberIcon key={'profile-icon' + index}>
                        {icon.member.profileImage ? (
                          <img src={icon.member.profileImage} />
                        ) : (
                          <Avatar
                            size='30px'
                            round='50%'
                            name={icon.member.firstName + ' ' + icon.member.lastName}
                          />
                        )}
                      </MemberIcon>
                    ))}
                    {addProjectForm.professtionals.length > 4 && (
                      <>
                        <MemberIcon number={true}>
                          <p>+{addProjectForm.professtionals.length - (5 - 1)}</p>
                        </MemberIcon>
                      </>
                    )}

                    {addProjectForm.professtionals.length === 0 ? (
                      <>
                        <AddIcon />
                        <p className='add-team-member' onClick={() => setShowTeamBox(!showTeamBox)}>
                          {t('project.add.select_team')}
                        </p>
                      </>
                    ) : (
                      <p
                        className='add-team-member align-self-center'
                        onClick={() => setShowTeamBox(!showTeamBox)}
                      >
                        {t('project.add.select_team')}
                      </p>
                    )}
                    {showTeamBox && (
                      <div
                        style={{
                          width: ' 40%',
                          position: 'absolute',
                          top: '25px'
                        }}
                      >
                        <TeamDropdown
                          close={() => setShowTeamBox(!showTeamBox)}
                          selectedTeam={(value) => selectedTeamMembers(value)}
                          selectedMember={addProjectForm.professtionals}
                          multiple={true}
                          teamMembersOptions={teamMembers}
                        />
                      </div>
                    )}
                  </AddTeamPanel>
                </div>
              </div>
            </RowBox>
            <RowBox className='row'>
              <InviteCollab onClick={() => setInviteProfessional(true)} className='hover'>
                {t('project.add.invite_collab')}
              </InviteCollab>
              {inviteProfessional && (
                <Modal show={inviteProfessional} close={() => setInviteProfessional(false)}>
                  <InviteCollaborator setInviteProfessional={setInviteProfessional} />
                </Modal>
              )}
            </RowBox>
          </>
        )}
        <div
          style={{
            width: 'inherit',
            bottom: '0',
            margin: '5% 0px 8% 0px'
          }}
        >
          {!isLoading && (
            <Button
              text={
                addProjectForm.formProgress === 3
                  ? t('project.add.create_project')
                  : t('universal_actions.next')
              }
              onClick={() => triggerNextForm('next')}
            />
          )}
          {isLoading && (
            <div class='spinner-grow' role='status' style={{ margin: '10px 0' }}>
              <span class='sr-only'>Loading...</span>
            </div>
          )}
          {addProjectForm.formProgress > 1 && (
            <BackButton className='button icon-left' onClick={() => triggerNextForm('back')}>
              &#8592; {t('universal_actions.back')}
            </BackButton>
          )}
        </div>
      </div>
    </div>
  );
});

export default withTranslation('common')(AddProjectModal);
