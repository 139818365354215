import React from 'react';
import { Snackbar } from 'react-snackbar-alert';
import styled from 'styled-components';

let SnackbarNotify = styled.div`
  margin: 0.5em;
  flex-grow: 1;
  font-family: Montserrat;
  font-size: 14px;
`;
export default function CustomSnackbarComponent(props) {
  return (
    <Snackbar {...props}>
      <SnackbarNotify className='text-capitalize'>{props.message} !</SnackbarNotify>
    </Snackbar>
  );
}
