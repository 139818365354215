import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { MdHome } from 'react-icons/md';
import { FaRegNewspaper, FaSearchPlus, FaCoffee, FaStickyNote } from 'react-icons/fa';
import { BsBag } from 'react-icons/bs';
import { RiProjectorFill } from 'react-icons/ri';
import DownUpArrow from '../../../public/icons/DownUpArrow';
import { UserContext } from '../../../common/context/UserContext';
import { withTranslation } from 'react-i18next';

let SideNavigationPanel = styled.aside`
  background: RowPanelghtgreen;
  width: 15%;
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  margin-top: 60px;
  background-color: #f8f8f8;
  border: 0;
`;

let MenuPanel = styled.ul`
  rowpanelst-style-type: none;
  padding: 10px 0;
`;

let RowPanel = styled.li`
  padding: ${(props) => (props.selected ? '10px 10px 10px 7px' : '10px')};
  font-family: Montserrat;
  font-size: 0.8em;
  font-weight: 500;
  rowpanelne-height: 1.14;
  letter-spacing: 0.05px;
  height: 40px;
  border-left: ${(props) => (props.selected ? '3px solid #21a8df' : 'none')};
  a {
    div.nav-home {
      align-self: center;
      margin-left: 10px;
      svg {
        width: 25px;
        height: 25px;
      }
    }
    p.sector-name {
      position: absolute;
      align-self: center;
      margin: 0;
      margin-left: 50px;
      div.underline-selected {
        width: 100%;
        height: 3px;
        background: #21a8df;
        position: relative;
        top: 0px;
      }
    }
    p.sector-arrow {
      margin-left: 70%;
      margin-bottom: 0;
    }
    &:hover {
      text-decoration: none;
    }
    color: ${(props) => (props.selected ? '#21a8df' : '#4b4d4e')};
  }
  @media (max-width: 768px) {
    a {
      p.sector-name {
        display: none;
      }
    }
  }
  @media (min-width: 1600px) {
    font-size: 13px;
  }
`;

const NavRow = ({ icon, text, arrowIcon, selected }) => (
  <div className='row m-0'>
    <div className='nav-home'>{icon}</div>
    <p className='sector-name'>
      {text}
      {selected && <div className='underline-selected'></div>}
    </p>
    {arrowIcon && <p className='sector-arrow'>{arrowIcon}</p>}
  </div>
);

const SideNav = ({ t }) => {
  const [{ user }, dispatch] = useContext(UserContext);
  const [selectedTag, setSelectedTag] = useState('/home');
  const selectedTopic = (type) => {
    if (document.getElementById('content_iframe')) {
      document.getElementById('content_iframe').src += '';
    }
    setSelectedTag(type);
  };
  useEffect(() => {
    if (window.location.pathname) {
      setSelectedTag(window.location.pathname);
    } else {
      setSelectedTag('/home');
    }
  }, []);

  return (
    <SideNavigationPanel>
      <MenuPanel>
        {user.user.role === 'client' && (
          <RowPanel selected={selectedTag === '/home'}>
            <Link to='/home' onClick={() => selectedTopic('/home')}>
              <NavRow
                text={t('sidenav.home')}
                icon={<MdHome selected={selectedTag === '/home'} />}
              />
            </Link>
          </RowPanel>
        )}
        {user.user.role === 'client' && (
          <RowPanel selected={selectedTag === '/home/insight'}>
            <Link to='/home/insight' onClick={() => selectedTopic('/home/insight')}>
              <NavRow
                text={t('sidenav.news')}
                icon={<FaRegNewspaper selected={selectedTag === '/home/insight'} />}
              />
            </Link>
          </RowPanel>
        )}
        {user.user.role === 'client' && (
          <RowPanel selected={selectedTag === '/home/businessCases'}>
            <Link to='/home/businessCases' onClick={() => selectedTopic('/home/businessCases')}>
              <NavRow
                text={t('sidenav.eis_insights')}
                icon={<FaSearchPlus selected={selectedTag === '/home/businessCases'} />}
              />
            </Link>
          </RowPanel>
        )}
        {user.user.role === 'client' && (
          <RowPanel selected={selectedTag === '/home/coffeeBreak'}>
            <Link to='/home/coffeeBreak' onClick={() => selectedTopic('/home/coffeeBreak')}>
              <NavRow
                text={t('sidenav.coffe_break')}
                icon={<FaCoffee selected={selectedTag === '/home/coffeeBreak'} />}
              />
            </Link>
          </RowPanel>
        )}
        {user.user.role === 'client' && (
          <RowPanel selected={selectedTag === '/home/businessLibrary'}>
            <Link to='/home/businessLibrary' onClick={() => selectedTopic('/home/businessLibrary')}>
              <NavRow
                text={t('sidenav.business_library')}
                icon={<BsBag selected={selectedTag === '/home/businessLibrary'} />}
              />
            </Link>
          </RowPanel>
        )}
        {user.user.role !== 'professional' && (
          <RowPanel selected={selectedTag === '/home/inquiries'}>
            <Link to='/home/inquiries' onClick={() => selectedTopic('/home/inquiries')}>
              <NavRow
                text={t('sidenav.inquiry')}
                icon={<RiProjectorFill selected={selectedTag === '/home/inquiries'} />}
              />
            </Link>
          </RowPanel>
        )}
        <RowPanel selected={selectedTag === '/home/projects'}>
          <Link to='/home/projects' onClick={() => selectedTopic('/home/projects')}>
            <NavRow
              text={t('sidenav.project')}
              icon={<FaStickyNote selected={selectedTag === '/home/projects'} />}
            />
          </Link>
        </RowPanel>
        {user.user.role === 'professional' && (
          <RowPanel selected={selectedTag === '/home/task'}>
            <Link to='/home/task' onClick={() => selectedTopic('/home/task')}>
              <NavRow
                text={t('sidenav.task')}
                icon={<NavTask selected={selectedTag === '/home/task'} />}
              />
            </Link>
          </RowPanel>
        )}
        {/* {user.user.role === 'facilitator' &&
          <RowPanel selected={selectedTag.main === '/home/tools/businessLibrary'}>
            <Link to='/home/tools/businessLibrary' onClick={() => selectedTopic('main', '/home/tools/businessLibrary')}>
              <NavRow
                text={t('sidenav.business_library')}
                icon={<NavTask selected={selectedTag.main === '/home/tools/businessLibrary'} />}
              />
            </Link>
          </RowPanel>
        } */}
        {/* Sub panel for Business */}
      </MenuPanel>
    </SideNavigationPanel>
  );
};

export default withTranslation('common')(SideNav);
